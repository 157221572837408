import React, {ChangeEvent, useState} from 'react';
import {ReactComponent as AttrIcon} from "../../../img/icons/createAttrModalICon.svg";
import s from './AttributesCodeModal.module.scss'
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    GetActivityAttributesThunk,
    onChangeAttrNewCodeField,
    resetAttrCodeFields, selectAttrCodesAgentPositions,
    selectAttrNewCodeFields
} from "../../../store/parametersReducer";
import {Button, Checkbox, Input, InputNumber, message, Select} from "antd";
import { ValueType } from 'rsuite/esm/Checkbox';
import TextArea from "antd/es/input/TextArea";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {parametersAPI} from "../../../app/api";
import {selectUserData} from "../../../store/userReducer";


interface AttributesCodeModalTypes {
    setIsOpenedModal: (isOpenedModal: boolean) => void
    setIsLoading: (isLoading: boolean) => void
    loading: boolean
}

const AttributesCodeModal = ({setIsOpenedModal, setIsLoading, loading} : AttributesCodeModalTypes) => {
    const attrCodeFormFields = useAppSelector(selectAttrNewCodeFields)
    const dispatch = useAppDispatch()
    const [checkFieldsForErrors, setCheckFieldsForErrors] = useState(false)
    const userData = useAppSelector(selectUserData)
    const agentPositions = useAppSelector(selectAttrCodesAgentPositions)
    const domainNames = [
        {value: 'CD', label: 'ČINŽOVNÍ DŮM'},
        {value: 'RS', label: 'E&V-PRAGUE RESIDENTIAL'},
        {value: 'RR', label: 'E&V-PRAGUE RENTAL'},
        {value: 'OR', label: 'OFFICE-RENT'},
    ]

    const userPositions = agentPositions.map((p: string) => (
        {value: p, label: p}
    ))


    const onChangeSelectValue = (value: string | number | boolean, selectName: string, option?: any) => {

        if(selectName === 'prop_required' || selectName === 'send_surveys'){
            if (value){
                dispatch(onChangeAttrNewCodeField({fieldName: selectName, fieldValue: 1}))
            }
            else{
                dispatch(onChangeAttrNewCodeField({fieldName: selectName, fieldValue: 0}))
            }
        }
        else{
            dispatch(onChangeAttrNewCodeField({fieldName: selectName, fieldValue: value}))
        }
    }



    const onCreateCode = () => {
        const excludeFields = ['old_act_code', 'old_domain_cd', 'old_position', 'order_num', 'enum_index', 'position']
        if (Object.keys(attrCodeFormFields).some(key =>
            (attrCodeFormFields[key] === '' || attrCodeFormFields[key] === null) && !excludeFields.includes(key))){
            setCheckFieldsForErrors(true)
        }
        else{
            let { enum_index, ...reqData } = attrCodeFormFields;
            reqData = {
                ...reqData,
                position: reqData.position !== null ? reqData.position : null
            }
            setIsLoading(true)
            parametersAPI.createActivityCodeAttributes(reqData)
                .then(() => {
                    setTimeout(() => {
                        setIsOpenedModal(false)
                        dispatch(GetActivityAttributesThunk({
                            act_code: null,
                            agent_ref: Number(userData.user_ref),
                            domain_cd: null,
                            position: userData.position,
                            sort_order: null
                        }))
                            .then(() => {
                                dispatch(resetAttrCodeFields())
                            })
                            .then(() => {
                                setIsLoading(false)
                            })
                            .then(() => {
                                message.success('Activity code was successfully created')
                            })
                    }, 2000)
                })
                .catch(() => {
                    setTimeout(() => {
                        setIsOpenedModal(false)
                        dispatch(GetActivityAttributesThunk({
                            act_code: null,
                            agent_ref: Number(userData.user_ref),
                            domain_cd: null,
                            position: userData.position,
                            sort_order: null
                        }))
                            .then(() => {
                                dispatch(resetAttrCodeFields())
                            })
                            .then(() => {
                                setIsLoading(false)
                            })
                            .then(() => {
                                message.success('Activity code was successfully created')
                            })
                    }, 2000)
                })
        }
    }

    return (
        <div className={`${s.modal} attrCodes`}>
            <div className={s.modal__inner}>
                <div style={{
                    minWidth: '48px',
                    minHeight: '48px'
                }}>
                    <AttrIcon/>
                </div>
                <div>
                    <h1 className={s.modal__title}>Create a new kind</h1>
                    <div className={s.form__row}>
                        <div className={s.form__item}>
                            <label className={s.form__label}>Domain</label>
                            <Select
                                placeholder="Select domain"
                                onChange={(value, option) => onChangeSelectValue(value, 'domain_cd', option)}
                                options={domainNames}
                                defaultValue={attrCodeFormFields.domain_cd}
                                value={attrCodeFormFields.domain_cd}
                                style={{
                                    width: '100% !important',
                                    height: '44px'
                                }}
                            />
                            {
                                attrCodeFormFields.domain_cd === null && checkFieldsForErrors
                                &&
                                <div className={s.error}>Please select domain</div>
                            }
                        </div>
                    </div>
                    <div className={s.form__row}>
                        <div className={s.form__item}>
                            <label className={s.form__label}>Position</label>
                            <Select
                                placeholder="Select position"
                                onChange={(value, option) => onChangeSelectValue(value, 'position', option)}
                                options={userPositions}
                                defaultValue={attrCodeFormFields.position}
                                value={attrCodeFormFields.position}
                                style={{
                                    width: '100% !important'
                                }}
                                allowClear
                            />
                            {/*{*/}
                            {/*    attrCodeFormFields.position === '' && checkFieldsForErrors*/}
                            {/*    &&*/}
                            {/*    <div className={s.error}>Please select position</div>*/}
                            {/*}*/}
                        </div>
                    </div>
                    <div className={s.form__row}>
                        <label className={s.form__label} style={{
                            marginBottom: '6px'
                        }}>Kind</label>
                        <Input
                            style={{
                                marginTop: '6px',
                                height: '44px'
                            }}
                            placeholder={'Enter code'}
                            value={attrCodeFormFields.act_code !== null ? attrCodeFormFields.act_code : ''}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeSelectValue(e.target.value, 'act_code')}
                        />
                        {
                            attrCodeFormFields.act_code === '' && checkFieldsForErrors
                            &&
                            <div className={s.error}>Please enter activity code</div>
                        }
                    </div>
                    <div className={s.form__row}>
                        <div className={s.form__item}>
                            <label className={s.form__label}>Order Num</label>
                            <InputNumber
                                value={attrCodeFormFields.order_num}
                                onChange={(value: ValueType | null) => onChangeSelectValue(value as number, 'order_num')}
                                min={1}
                                style={{
                                    height: '44px'
                                }}
                            />
                            {/*<div>*/}
                            {/*    {*/}
                            {/*        attrCodeRows.some((activity: any) => activity.ORDER_NUM === attrCodeFormFields.order_num)*/}
                            {/*        &&*/}
                            {/*        <div className={s.error}>This order num already exists. Please pick another</div>*/}
                            {/*    }*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className={s.form__row}>
                        <div>
                            <label className={s.form__label}>Description</label>
                            <TextArea
                                placeholder={'Enter a description...'}
                                style={{resize: 'none', height: '94px', padding: '10px 14px', marginTop: '6px'}}
                                value={attrCodeFormFields.act_description}
                                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChangeSelectValue(e.target.value, 'act_description')}
                            />
                            {
                                attrCodeFormFields.act_description === '' && checkFieldsForErrors
                                &&
                                <div className={s.error}>Please enter activity description</div>
                            }
                        </div>
                        <div className={s.checkboxes}>
                            <div className={s.checkboxes__prop}>
                                <Checkbox
                                    onChange={(e: CheckboxChangeEvent) => onChangeSelectValue(e.target.checked, 'prop_required')}
                                    checked={attrCodeFormFields.prop_required === 1}
                                    style={{
                                        marginRight: '8px'
                                    }}
                                />
                                <div className={s.checkboxes__title}>
                                    <span>Prop_Required</span>
                                    <span>Check if property and requirement are required for this kind, skip if not.</span>
                                </div>
                            </div>
                            <div className={s.checkboxes__prop}>
                                <Checkbox
                                    onChange={(e: CheckboxChangeEvent) => onChangeSelectValue(e.target.checked, 'send_surveys')}
                                    checked={attrCodeFormFields.send_surveys === 1}
                                    style={{
                                        marginRight: '8px'
                                    }}
                                />
                                <div className={s.checkboxes__title}>
                                    <span>Send_Surveys</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.form__buttons}>
                    <Button
                        type='default'
                        style={{
                            height: '44px',
                            width: '100%'
                        }}
                        onClick={() => setIsOpenedModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type='primary'
                        htmlType='submit'
                        loading={loading}
                        style={{
                            height: '44px',
                            backgroundColor: '#0707FA',
                            width: '100%',
                            border: '1px solid #0707FA',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}
                        onClick={onCreateCode}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AttributesCodeModal;