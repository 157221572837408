import React, {useEffect, useLayoutEffect, useState} from 'react';
import PageTitle from "../../common/PageTitle/PageTitle";
import s from "../OrganizationActivitiesGrid.module.scss";
import propertiesStyles from './../../PropertiesPage/Properties.module.scss'
import commonS from "../../RequirementsPage/CommonTableStyles.module.scss";
import PropertiesSearchInput from "../../PropertiesCommonComponents/PropertiesSearchInput/PropertiesSearchInput";
import Box from "@mui/material/Box";
import {DataGridPro, GridColumnMenu, GridRow, useGridApiRef} from "@mui/x-data-grid-pro";
import {Lottie} from "@crello/react-lottie";
import animatedLoader from "../../../img/Loader.json";
import CommonActivityModal from "../../common/CommonActivityModal/CommonActivityModal";
import ActivitiesGridForm from "../../ActivitiesGridForm/ActivitiesGridForm";
import {Button, Checkbox} from "antd";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {ReactComponent as WarningActivityIcon} from './../../../img/icons/alert-triangle.svg';
import surfaceArrow from "../../../img/icons/chevron-down.svg";
import {
    ActivitiesGridCheckIsPasswordNeededThunk,
    CreateNewActivityThunk,
    GetActivitiesByReqRefThunk,
    GetActivitiesRefLinksThunk, GetActivitiesSurfaceThunk,
    onCheckActivitiesProperties,
    onCheckActivitiesPropertiesIds,
    onCheckSurfaces,
    onCheckSurfacesIds,
    onClearActivitiesPropertiesAndSurfaces, onDeleteCheckedProperty,
    onDeleteCheckedSurface, onSetActivitiesSurfaceIsOpened,
    onSetCurrentModalTab,
    onSetIsDescriptionFieldError,
    onSetIsSavingButtonDisabled,
    selectActivitiesCheckedSurfaces,
    selectActivitiesCheckedSurfacesIds,
    selectActivitiesCreationGridPropsByReqColumns, selectActivitiesCreationGridPropsByReqRows,
    selectActivitiesCurrentRow,
    selectActivitiesKindNumberEntity,
    selectActivitiesModalFormFields,
    selectActivitiesTypeKind,
    selectActivitiesTypeNumberEntity,
    selectActivitiesTypes,
    selectActivityEditMode,
    selectActivityModalCurrentTab,
    selectAllActivitiesEntitiesArray,
    selectContactId,
    selectCurrentActivitiesCheckedProperties,
    selectCurrentActivitiesCheckedPropertiesIds,
    selectOrganizationId,
    UpdateExistingActivityThunk,
} from "../../../store/activitesGrid";
import {
    onSetReqGridHeight,
} from "../../../store/propertiesReducer";
import {customSortFunction} from "../../../helpers/sortingDataHelper";
import PropertiesGridFooter from "../../PropertiesCommonComponents/PropertiesGridFooter/PropertiesGridFooter";
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";
import {CreateAndUpdateActivityRequest} from "../../../app/api";
import moment from 'moment';
import {useNavigate} from "react-router";
import Tooltip from "../../Tooltip/Tooltip";
import {selectUserData} from "../../../store/userReducer";


const OrganizationActivitiesProperties1 = () => {
    return (
        <PageWithSidebar>
            <OrganizationActivitiesPropertiesPageComponent/>
        </PageWithSidebar>
    )
}


const OrganizationActivitiesPropertiesPageComponent = () => {
    const currentTab = useAppSelector(selectActivityModalCurrentTab)
    const editMode = useAppSelector(selectActivityEditMode)
    const currentRowValues = useAppSelector(selectActivitiesCurrentRow)
    const [height, setHeight] = useState(window.innerHeight);
    const [gridContentHeight, setGridContentHeight] = useState(height - 340);
    const dispatch = useAppDispatch()
    const [searchTerm, setSearchTerm] = useState('');
    const [localSortModel, setLocalSortModel] = useState<any>({});
    const modalFormFields = useAppSelector(selectActivitiesModalFormFields)
    const activitiesProperties = useAppSelector(selectActivitiesCreationGridPropsByReqRows)
    const checkedSurfaces = useAppSelector(selectActivitiesCheckedSurfaces)
    const rowsPerPageOptions = [150, 200, 500];
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [page, setPage] = React.useState(1);
    const checkedPropertiesIds = useAppSelector(selectCurrentActivitiesCheckedPropertiesIds)
    const checkedSurfacesIds = useAppSelector(selectActivitiesCheckedSurfacesIds)
    const typeNumberEntity = useAppSelector(selectActivitiesTypeNumberEntity)
    const kindNumberEntity = useAppSelector(selectActivitiesKindNumberEntity)
    const [popConfirmModalOpened, setPopConfirmModalOpened] = useState(false)
    const allActivitiesEntities = useAppSelector(selectAllActivitiesEntitiesArray)
    const navigate = useNavigate()
    const organizationId = useAppSelector(selectOrganizationId)
    const contactId = useAppSelector(selectContactId)
    const activitiesTypeKind = useAppSelector(selectActivitiesTypeKind)
    const activitiesTypes = useAppSelector(selectActivitiesTypes)
    const [loading, setIsLoading] = useState(false)
    const checkedProperties = useAppSelector(selectCurrentActivitiesCheckedProperties)
    const userData = useAppSelector(selectUserData)
    const gridColumns = useAppSelector(selectActivitiesCreationGridPropsByReqColumns)
    useEffect(() => {
        if (editMode) {
            dispatch(GetActivitiesRefLinksThunk(currentRowValues.REF!))
        }
    }, [dispatch, editMode, currentRowValues.REF])

    useEffect(() => {
        dispatch(GetActivitiesByReqRefThunk({
            p_agent_ref: Number(userData.user_ref),
            p_req_ref: currentRowValues.REF!,
            p_sort_order: null
        }))
    }, [dispatch, userData, currentRowValues])
    // useEffect(() => {
    //     if (editMode) {
    //         const filteredProperties = currentPropertiesArray.filter((obj: any) => checkedPropertiesIds.includes(obj.REF));
    //         dispatch(onSetActivitiesProperties(filteredProperties))
    //     }
    //
    //
    // }, [checkedPropertiesIds, currentPropertiesArray, dispatch, editMode])

    // useEffect(() => {
    //     checkedPropertiesIds.forEach((id: number) => {
    //         dispatch(GetActivitiesSurfaceThunk({ prop_id: id, req_id: currentRowValues.REQ_REF! }));
    //     });
    // }, [checkedPropertiesIds, dispatch, currentRowValues.REQ_REF!])

    const handleSearchInputChange = (event: any) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        dispatch(ActivitiesGridCheckIsPasswordNeededThunk({
            list_code: 'activities',
            operation: 'D',
            password: 'act999'

        }))
    }, [dispatch])

    const apiRef = useGridApiRef();
    // const columns = [
    //     {
    //         field: 'REF',
    //         headerName: 'Prop id',
    //         flex: 0.5,
    //         fontFamily: 'Inter, sans-serif',
    //         fontWeight: 600,
    //         color: '#6B7280',
    //         fontSize: '12px',
    //         lineHeight: '20px',
    //     },
    //     {
    //         field: 'DESCRIPTION',
    //         headerName: 'Description',
    //         flex: 1,
    //         fontFamily: 'Inter, sans-serif',
    //         fontWeight: 600,
    //         color: '#6B7280',
    //         fontSize: '12px',
    //         lineHeight: '20px',
    //     },
    //     {
    //         field: 'PROP_ADDR_CITY',
    //         headerName: 'City',
    //         flex: 1,
    //         fontFamily: 'Inter, sans-serif',
    //         fontWeight: 600,
    //         color: '#6B7280',
    //         fontSize: '12px',
    //         lineHeight: '20px',
    //     }
    // ]



    let columns = gridColumns.map((g: any) => {

            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: g.COL_WIDTH,
                dataType: g.COL_TYPE,
                col_pos: g.COL_POS,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
            }


    }).filter((c: any) => c.width !== 0 && c.width !== null).sort((a, b) => a.col_pos - b.col_pos)


    console.log(columns)

    const debounce = (func: Function, wait: number) => {
        let timeout: ReturnType<typeof setTimeout> | null;
        return function (this: any, ...args: any[]) {
            const context = this;
            clearTimeout(timeout!);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    };

    useLayoutEffect(() => {
        const handleResize = debounce(function () {
            setHeight(window.innerHeight);
            const heightForPage = window.innerHeight;
            setGridContentHeight(heightForPage - 340);
            dispatch(onSetReqGridHeight(heightForPage))
        }, 100);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [dispatch]);

    const handleSortModelChange = (newModel: any) => {
        const newLocalModel = {...localSortModel};
        newModel.forEach((model: any) => {
            // @ts-ignore
            newLocalModel[model.field] = model;
        });
        setLocalSortModel(newLocalModel);
    };
    console.log(activitiesProperties)
    let sortedRows = customSortFunction(activitiesProperties, []);


    const rows = sortedRows.reduce((acc, row) => {
        const values = Object.values(row);
        const useStartsWith = searchTerm.endsWith('%');
        const processedSearchTerm = searchTerm.slice(0, -1).toLowerCase();
        if (values.some(value => {
            const stringValue = String(value).toLowerCase();
            return useStartsWith ? stringValue.startsWith(processedSearchTerm) : stringValue.includes(searchTerm.toLowerCase());
        })) {
            acc.push(row);
        }
        return acc;
    }, []);



    let activitiesDataRowsPaginated = rows.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);

    console.log(activitiesDataRowsPaginated)
    const handlePageChange = (params: any, value: number) => {
        setPage(value);
        afterPaginationPageChangeScrollToTop();
    }

    const afterPaginationPageChangeScrollToTop = () => {
        const gridApi = apiRef.current;
        if (!gridApi) return;
        gridApi.scrollToIndexes({rowIndex: 0});
    };

    function handleRowsPerPageChange(event: any) {
        setRowsPerPage(event);
    }




    const onCreateNewActivity = () => {
        setIsLoading(true)
        const maxListLength = checkedPropertiesIds.length + checkedSurfacesIds.length;
        const kindNumberEntityForEdit = activitiesTypeKind.find((t) => t.VALUE === modalFormFields.kind)
        const typeNumberEntityForEdit = activitiesTypes.find((t) => t.AE_LI_VALUE === modalFormFields.type)
        const link_type_list: number[] = []
        const link_entity_list = !editMode ? [] : allActivitiesEntities.map((c: any) => c.link_entity_list).filter(item => !checkedPropertiesIds.includes(item) && !checkedSurfacesIds.includes(item))

        let link_ref_list = !editMode ? Array.from({length: maxListLength + 3}, () => null) : allActivitiesEntities.map((c: any) => c.link_ref_list)
        console.log(link_type_list)
        console.log(link_entity_list)
        for (let i = 0; i < maxListLength; i++) {
            if (i < checkedPropertiesIds.length) {
                link_type_list.push(0);
            }
            if (i < checkedSurfacesIds.length) {
                link_type_list.push(14);
            }
        }
        for (let i = 0; i < maxListLength; i++) {
            if (i < checkedPropertiesIds.length) {
                link_entity_list.push(checkedPropertiesIds[i]);
            }
            if (i < checkedSurfacesIds.length) {
                link_entity_list.push(checkedSurfacesIds[i]);
            }
        }

        link_entity_list.unshift(currentRowValues.REF!)
        link_type_list.unshift(1)
        link_type_list.unshift(2,3)
        link_entity_list.unshift(organizationId, contactId)
        if (modalFormFields.description !== '') {
            dispatch(onSetIsSavingButtonDisabled(true))
            const req: CreateAndUpdateActivityRequest = {
                date_begin: moment(modalFormFields.date_from),
                date_end: moment(modalFormFields.date_till),
                description: modalFormFields.description !== '' ? modalFormFields.description : '',
                link_entity_list: link_entity_list,
                link_ref_list: !editMode ? link_ref_list : link_ref_list,
                link_type_list: link_type_list,
                priority: modalFormFields.urgency === 'low' ? 0 : modalFormFields.urgency === 'avg' ? 1 : 2,
                remark: modalFormFields.remark !== '' ? modalFormFields.remark : '',
                type:  !editMode ? typeNumberEntity : typeNumberEntityForEdit?.AE_NO_INDEX,
                type_kind: !editMode ? kindNumberEntity : kindNumberEntityForEdit?.CODE
            }
            if (!editMode) {
                dispatch(CreateNewActivityThunk({activityData: req}))
                console.log(req)
            } else {
                dispatch(UpdateExistingActivityThunk({requestData: req, reqRef: currentRowValues.REF!}))
            }
        } else {
            dispatch(onSetIsDescriptionFieldError(true))
            dispatch(onSetCurrentModalTab('General'))
            setIsLoading(false)
        }
    }

    const onCloseModal = () => {
        if (!editMode) {
            setPopConfirmModalOpened(true)
        } else {
            navigate('/organizationActivities')
        }

    }

    const onCancelActivityCreation = () => {
        setPopConfirmModalOpened(false)
        navigate('/organizationActivities')
        dispatch(onClearActivitiesPropertiesAndSurfaces())
    }






    const isActivityTypeKindWithRightValue = activitiesTypeKind && activitiesTypeKind.some((a: any) => a.VALUE! === currentRowValues.ACT_KIND || a.VALUE! === modalFormFields.kind)


    return (
        <div>
            {
                !editMode
                    ?
                    checkedProperties.length || checkedSurfaces.length
                        ?
                        <div onClick={onCloseModal}>
                            <PageTitle backLinktext='Back' isBackButton={true}
                                       backLink={'/organizationActivities'}
                                       isLinkDisabled={true}/>
                        </div>
                        :
                        <PageTitle backLinktext='Back' isBackButton={true}
                                   backLink={'/organizationActivities'}/>
                    :
                    <PageTitle backLinktext='Activities' isBackButton={true}
                               backLink={'/organizationActivities'}/>
            }
            <div className={s.inner} style={{
                width: '100%'
            }}>
                <div
                    className={s.inner}
                    style={{
                        marginTop: '0'
                    }}
                >
                    <div
                        className={commonS.tableInner}
                        style={{
                            padding: '9px 0 16px 12px',
                            width: '100%',
                            marginRight: '55px'
                        }}
                    >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <h1 className={s.tableTitle}>List of Properties for
                                Req: {currentRowValues.REF!} </h1>
                        </div>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '10px'
                        }}>
                            <PropertiesSearchInput
                                searchTerm={searchTerm}
                                setSearchTerm={(searchTerm: string) => setSearchTerm(searchTerm)}
                                handleSearchInputChange={handleSearchInputChange}
                                isActivityProp={true}
                            />
                        </div>
                        <Box sx={{position: 'relative', width: '100%'}}>
                            <>
                                {
                                    // isDataLoading
                                    //     ?
                                    //     <div style={{
                                    //         height: gridContentHeight + 115,
                                    //         width: '400px',
                                    //         margin: '0 auto',
                                    //         display: 'flex',
                                    //         flexDirection: 'column',
                                    //         justifyContent: 'center'
                                    //     }}>
                                    //         <div style={{
                                    //             height: '300px',
                                    //             width: '100%',
                                    //             textAlign: 'center'
                                    //         }}>
                                    //             {/* @ts-ignore */}
                                    //             <Lottie config={{animationData: animatedLoader, loop: true, height: '60px !important'
                                    //             }}/>
                                    //         </div>
                                    //     </div>
                                    //     :
                                        activitiesDataRowsPaginated.length
                                            ?
                                            <DataGridPro
                                                rows={activitiesDataRowsPaginated}
                                                columns={columns.map((column: any, index: number) => ({
                                                    ...column,
                                                    renderCell: (params: any) =>
                                                        <CustomCell field={column.field}
                                                                    value={params.value}
                                                                    params={params}
                                                                    properties={activitiesDataRowsPaginated}
                                                                    key={index}
                                                                    dataType={column.dataType}

                                                        />
                                                }))}
                                                apiRef={apiRef}
                                                autoHeight={true}
                                                getRowId={e => e.REF}
                                                sortingOrder={['desc', 'asc']}
                                                onSortModelChange={handleSortModelChange}
                                                disableColumnSelector={true}
                                                rowHeight={44}
                                                // onStateChange={handleStateChange}
                                                {...activitiesDataRowsPaginated}
                                                sx={{
                                                    borderRight: 'none',
                                                    borderLeft: 'none',
                                                    borderBottom: 'none',
                                                    borderRadius: '0px',
                                                    // minHeight: '600px',
                                                    // height: height > 950 ? `${gridContentHeight}px !important` : height > 900 ? `${gridContentHeight - 150}px !important` : `${gridContentHeight + 150}px !important`,
                                                    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                                        opacity: 0
                                                    },
                                                    "& .MuiDataGrid-cellContent": {
                                                        fontFamily: 'Inter, sans-serif',
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        lineHeight: '20px',
                                                        color: '#111827'
                                                    },
                                                    "& .MuiDataGrid-columnHeaderTitle": {
                                                        fontFamily: 'Inter, sans-serif',
                                                        fontWeight: 600,
                                                        color: '#6B7280',
                                                        fontSize: '12px',
                                                        lineHeight: '20px',
                                                    },
                                                    "& .MuiDataGrid-row:hover": {
                                                        backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                                    },
                                                    "& .MuiDataGrid-row.Mui-selected": {
                                                        backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                                    },
                                                    "& .MuiDataGrid-row.Mui-selected:hover": {
                                                        backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                                    },
                                                    "& .MuiDataGrid-cell:focus": {
                                                        outline: 'none'
                                                    },
                                                    "& .MuiDataGrid-toolbarContainer": {
                                                        color: '#454545',
                                                        position: 'absolute',
                                                        top: '-50px',
                                                        right: '0px'
                                                    },
                                                    "& .MuiTablePagination-displayedRows": {
                                                        margin: '0',
                                                        fontFamily: 'Inter, sans-serif !important',
                                                        fontWeight: 400,
                                                        color: '#6B7280',
                                                        fontSize: '12px',
                                                        lineHeight: '20px',
                                                    },
                                                    "& .MuiPagination-root": {
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        padding: '15px 0px'
                                                    },
                                                    "& .MuiButtonBase-root.Mui-selected": {
                                                        backgroundColor: '#eff1f3',
                                                        borderRadius: '0px'
                                                    },
                                                    "& .MuiButtonBase-root.MuiPaginationItem-root": {
                                                        width: '40px',
                                                        height: '40px',
                                                        margin: 0,
                                                        borderRight: '1px solid #D0D5DD',
                                                        borderLeft: '1px solid #D0D5DD',
                                                        borderTop: 'none',
                                                        borderBottom: 'none',
                                                        fontFamily: 'Inter, sans-serif !important',
                                                        fontWeight: 500,
                                                        color: '#1D2939',
                                                        fontSize: '14px',
                                                        lineHeight: '20px',
                                                        borderRadius: '0px'
                                                    },
                                                    "& .MuiPagination-ul": {
                                                        border: '1px solid #D0D5DD',
                                                        borderRadius: '8px'
                                                    },
                                                    "& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext": {
                                                        borderRadius: '0px',
                                                        border: 'none'
                                                    },
                                                    "& .MuiDataGrid-columnHeaders": {
                                                        backgroundColor: '#FCFDFE'
                                                    },
                                                    "& .MuiDivider-root": {
                                                        display: 'none',
                                                        height: '0',
                                                        width: '0',
                                                        opacity: '0 !important',
                                                        borderColor: '#fff '
                                                    },
                                                    "& .MuiBox-root": {
                                                        height: 'auto'
                                                    },
                                                    "& .MuiDataGrid-cell": {
                                                        borderBottom: 'none',

                                                    },
                                                    "& .MuiDataGrid-virtualScroller": {
                                                        overflowY: 'scroll !important',
                                                        overflowX: 'scroll',
                                                        height: `${gridContentHeight - 115}px !important`,
                                                        minHeight: 'auto',
                                                    },
                                                    "& .MuiDataGrid-virtualScrollerContent": {},
                                                    // "& .MuiDataGrid-virtualScrollerRenderZone": {
                                                    //     width: '100%'
                                                    // },
                                                    "& .MuiDataGrid-row": {
                                                        width: '100%'
                                                    }
                                                }}
                                                components={{
                                                    Footer: () =>
                                                        <PropertiesGridFooter
                                                            rows={rows}
                                                            rowsPerPageOptions={rowsPerPageOptions}
                                                            handlePageChange={handlePageChange}
                                                            handleRowsPerPageChange={handleRowsPerPageChange}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                        />,
                                                    ColumnMenu: (props) => {
                                                        return (
                                                            <GridColumnMenu
                                                                hideMenu={() => {
                                                                }}
                                                                currentColumn={
                                                                    {
                                                                        field: props.currentColumn.field,
                                                                        filterable: false,
                                                                        hideable: false,
                                                                    }
                                                                }
                                                                open={true}
                                                            />
                                                        )
                                                    },
                                                    Row: CustomGridRow,
                                                }}
                                            />
                                            :
                                            <div style={{
                                                height: gridContentHeight + 115,
                                                width: '400px',
                                                margin: '0 auto',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center'
                                            }}>
                                                <div style={{
                                                    height: '300px',
                                                    width: '100%',
                                                    textAlign: 'center'
                                                }}>
                                                    {/* @ts-ignore */}
                                                    <Lottie config={{animationData: animatedLoader, loop: true, height: '60px !important'
                                                    }}/>
                                                </div>
                                            </div>
                                            // <div style={{
                                            //     height: `${gridContentHeight + 25}px`,
                                            //     display: 'flex',
                                            //     alignItems: 'center',
                                            //     justifyContent: 'center'
                                            // }}>
                                            //     <Empty/>
                                            // </div>
                                    // !activitiesGridColumns.length
                                    //     ?
                                    //     <div style={{
                                    //         height: gridContentHeight + 115,
                                    //         display: 'flex',
                                    //         alignItems: 'center',
                                    //         justifyContent: 'center'
                                    //     }}>
                                    //         <Empty/>
                                    //     </div>
                                    //     :

                                }
                            </>
                        </Box>

                    </div>

                </div>
                <CommonActivityModal modalTitle={`Activity ${editMode ? currentRowValues.REF! : ''}`}
                                     gridType={"Activity"} height={gridContentHeight - 15}
                                     onCloseModal={onCloseModal}
                                     width={'650px'}
                >
                    <div>
                        <div className={s.tabButtons}>
                            <button
                                onClick={() => dispatch(onSetCurrentModalTab('General'))}
                                className={currentTab === 'General' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}
                            >
                                General
                            </button>

                            <button
                                onClick={() => dispatch(onSetCurrentModalTab('Existing Links'))}
                                className={currentTab === 'Existing Links' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}
                            >
                                Existing links
                            </button>
                            {
                                currentRowValues.WE_TYPE_POSITION !== null
                                &&
                                <p className={s.badge}>{currentRowValues.WE_TYPE_POSITION}</p>
                            }

                        </div>
                        {
                            currentTab === 'General'
                                ?
                                <ActivitiesGridForm withButtons={false} editMode={editMode} maxWidthInputs={true}
                                                    disabledInputs={currentRowValues.REQ_REF! === null}/>
                                :
                                checkedProperties.length || checkedSurfaces.length
                                    ?
                                    <div>
                                        <>
                                            {checkedProperties.length
                                                ?
                                                <div className={propertiesStyles.messageInner__properties}>
                                                    <p>Properties:</p>
                                                    <div className={propertiesStyles.messageInner__propertiesInner}>
                                                        {checkedProperties.map((p: any) => {
                                                            return (
                                                                <div className={propertiesStyles.messageInner__property}
                                                                     key={p.REF}>


                                                                    <span>{p.REF} {p.DESCRIPTION ? '-' : null} {p.DESCRIPTION}</span>
                                                                    <button
                                                                        onClick={() => dispatch(onDeleteCheckedProperty({prop_id: p.REF!, withoutReq: false, req_id: currentRowValues.REF, isSoloReq: true}))}
                                                                        type={'button'}
                                                                        disabled={currentRowValues?.ACT_TYPE === 'Documentation'}
                                                                        style={{
                                                                            cursor: currentRowValues?.ACT_TYPE === 'Documentation' ? 'not-allowed' : 'pointer'
                                                                        }}
                                                                    >
                                                                        <svg width="12" height="12" viewBox="0 0 12 12"
                                                                             fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                            <g clipPath="url(#clip0_1387_36685)">
                                                                                <path
                                                                                    d="M6.46967 5.99974L9.233 3.2364C9.28761 3.17264 9.31614 3.09061 9.3129 3.00672C9.30966 2.92283 9.27488 2.84325 9.21552 2.78388C9.15616 2.72452 9.07658 2.68974 8.99268 2.6865C8.90879 2.68326 8.82677 2.71179 8.763 2.7664L5.99967 5.52974L3.23633 2.76307C3.17356 2.7003 3.08843 2.66504 2.99967 2.66504C2.9109 2.66504 2.82577 2.7003 2.763 2.76307C2.70023 2.82584 2.66497 2.91097 2.66497 2.99974C2.66497 3.0885 2.70023 3.17364 2.763 3.2364L5.52967 5.99974L2.763 8.76307C2.7281 8.79295 2.69976 8.82972 2.67976 8.87108C2.65975 8.91243 2.64851 8.95748 2.64673 9.00338C2.64496 9.04929 2.65269 9.09507 2.66945 9.13784C2.68621 9.18062 2.71163 9.21947 2.74412 9.25195C2.7766 9.28444 2.81545 9.30986 2.85822 9.32661C2.901 9.34337 2.94678 9.35111 2.99268 9.34933C3.03859 9.34756 3.08363 9.33632 3.12499 9.31631C3.16634 9.2963 3.20312 9.26796 3.233 9.23307L5.99967 6.46974L8.763 9.23307C8.82677 9.28768 8.90879 9.31621 8.99268 9.31297C9.07658 9.30973 9.15616 9.27496 9.21552 9.21559C9.27488 9.15623 9.30966 9.07665 9.3129 8.99275C9.31614 8.90886 9.28761 8.82684 9.233 8.76307L6.46967 5.99974Z"
                                                                                    fill={'rgba(0, 0, 0, 0.54)'}/>
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_1387_36685">
                                                                                    <rect width="12" height="12"
                                                                                          fill="white"/>
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>

                                                                    </button>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                :
                                                null}
                                        </>
                                        <>
                                            {checkedSurfaces.length
                                                ?
                                                <div className={propertiesStyles.messageInner__properties}>
                                                    <p>Surfaces:</p>
                                                    <div className={propertiesStyles.messageInner__propertiesInner}>
                                                        {checkedSurfaces.map((p: any) => {
                                                            return (
                                                                <div className={propertiesStyles.messageInner__property}
                                                                     key={p.VALUE_01}>


                                                                    <span>{p.VALUE_01} {p.LABEL_03 ? '-' : null} {p.LABEL_03} {p.VALUE_03 ? '-' : null} {p.VALUE_03}</span>
                                                                    <button
                                                                        onClick={() => dispatch(onDeleteCheckedSurface({surface_id: p.VALUE_01!, req_id: currentRowValues.REF!, isSoloReq: true}))}
                                                                        type={'button'}
                                                                        disabled={currentRowValues?.ACT_TYPE === 'Documentation'}
                                                                        style={{
                                                                            cursor: currentRowValues?.ACT_TYPE === 'Documentation' ? 'not-allowed' : 'pointer'
                                                                        }}
                                                                    >
                                                                        <svg width="12" height="12" viewBox="0 0 12 12"
                                                                             fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                            <g clipPath="url(#clip0_1387_36685)">
                                                                                <path
                                                                                    d="M6.46967 5.99974L9.233 3.2364C9.28761 3.17264 9.31614 3.09061 9.3129 3.00672C9.30966 2.92283 9.27488 2.84325 9.21552 2.78388C9.15616 2.72452 9.07658 2.68974 8.99268 2.6865C8.90879 2.68326 8.82677 2.71179 8.763 2.7664L5.99967 5.52974L3.23633 2.76307C3.17356 2.7003 3.08843 2.66504 2.99967 2.66504C2.9109 2.66504 2.82577 2.7003 2.763 2.76307C2.70023 2.82584 2.66497 2.91097 2.66497 2.99974C2.66497 3.0885 2.70023 3.17364 2.763 3.2364L5.52967 5.99974L2.763 8.76307C2.7281 8.79295 2.69976 8.82972 2.67976 8.87108C2.65975 8.91243 2.64851 8.95748 2.64673 9.00338C2.64496 9.04929 2.65269 9.09507 2.66945 9.13784C2.68621 9.18062 2.71163 9.21947 2.74412 9.25195C2.7766 9.28444 2.81545 9.30986 2.85822 9.32661C2.901 9.34337 2.94678 9.35111 2.99268 9.34933C3.03859 9.34756 3.08363 9.33632 3.12499 9.31631C3.16634 9.2963 3.20312 9.26796 3.233 9.23307L5.99967 6.46974L8.763 9.23307C8.82677 9.28768 8.90879 9.31621 8.99268 9.31297C9.07658 9.30973 9.15616 9.27496 9.21552 9.21559C9.27488 9.15623 9.30966 9.07665 9.3129 8.99275C9.31614 8.90886 9.28761 8.82684 9.233 8.76307L6.46967 5.99974Z"
                                                                                    fill={'rgba(0, 0, 0, 0.54)'}/>
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_1387_36685">
                                                                                    <rect width="12" height="12"
                                                                                          fill="white"/>
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>

                                                                    </button>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                :
                                                null}
                                        </>
                                    </div>
                                    :
                                    <div className={s.reqGridText}>
                                        Add the Properties / Surfaces to the activity.
                                    </div>
                        }

                    </div>

                    {
                        editMode && currentRowValues.REQ_REF! !== null
                            ?
                            <div className={s.form__buttons}>
                                <Button
                                    type='default'
                                    style={{
                                        width:  currentRowValues.ACT_TYPE !== 'Documentation' ?  '295px' : '100%',
                                        height: '44px',
                                        marginRight: '12px'
                                    }}
                                    onClick={onCloseModal}
                                >
                                    Cancel
                                </Button>
                                {
                                    modalFormFields.domain === ''
                                    || modalFormFields.domain === null
                                    || modalFormFields.type === ''
                                    || modalFormFields.type === null
                                    || modalFormFields.kind === ''
                                    || modalFormFields.kind === null
                                    || !isActivityTypeKindWithRightValue
                                        ?
                                        <Tooltip
                                            text={(
                                                (isActivityTypeKindWithRightValue && modalFormFields.domain === '')
                                                || modalFormFields.domain === null
                                                || modalFormFields.type === ''
                                                || modalFormFields.type === null
                                                || modalFormFields.kind === ''
                                                || modalFormFields.kind === null
                                            )
                                                ? `Please select the domain, activity type, and activity kind in order to save changes.`
                                                : `Please change your activity kind to the one that is correct with the system kinds`
                                            }>
                                            <Button
                                                type='primary'
                                                htmlType='submit'
                                                style={{
                                                    height: '44px',
                                                    backgroundColor: 'rgba(7, 7, 250, 0.26)',
                                                    color: '#fff',
                                                    width: '295px',
                                                    border: '1px solid transparent',
                                                    display: currentRowValues.ACT_TYPE !== 'Documentation' ? 'flex' : 'none',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',

                                                }}
                                                onClick={onCreateNewActivity}
                                                disabled={
                                                    modalFormFields.domain === ''
                                                    || modalFormFields.domain === null
                                                    || modalFormFields.type === ''
                                                    || modalFormFields.type === null
                                                    || modalFormFields.kind === ''
                                                    || modalFormFields.kind === null
                                                    || !isActivityTypeKindWithRightValue
                                                }
                                                loading={loading}
                                            >
                                                Save
                                            </Button>
                                        </Tooltip>
                                        :
                                        <Button
                                            type='primary'
                                            htmlType='submit'
                                            style={{
                                                height: '44px',
                                                backgroundColor: '#0707FA',
                                                color: '#fff',
                                                width: '295px',
                                                border: '1px solid transparent',
                                                display: currentRowValues.ACT_TYPE !== 'Documentation' ? 'flex' : 'none',
                                                alignItems: 'center',
                                                justifyContent: 'center',

                                            }}
                                            onClick={onCreateNewActivity}
                                            onDoubleClick={() => setIsLoading(true)}
                                            disabled={!checkedSurfaces.length && !activitiesProperties.length}
                                            loading={loading}
                                        >
                                            Save
                                        </Button>
                                }

                            </div>
                            :
                            checkedSurfaces.length || activitiesProperties.length
                                ?
                                isActivityTypeKindWithRightValue
                                ?
                                <Button
                                    type='primary'
                                    htmlType='submit'
                                    style={{
                                        height: '40px',
                                        backgroundColor: '#0707FA',
                                        border: '1px solid #0707FA',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '10px'

                                    }}
                                    onClick={onCreateNewActivity}
                                    onDoubleClick={() => setIsLoading(true)}
                                    loading={loading}
                                >
                                    Add
                                </Button>
                                    :
                                    <Tooltip
                                        text={(
                                            (isActivityTypeKindWithRightValue && modalFormFields.domain === '')
                                            || modalFormFields.domain === null
                                            || modalFormFields.type === ''
                                            || modalFormFields.type === null
                                            || modalFormFields.kind === ''
                                            || modalFormFields.kind === null
                                        )
                                            ? `Please select the domain, activity type, and activity kind in order to save changes.`
                                            : `Please change your activity kind to the one that is correct with the system kinds`
                                        }
                                        classname={'addressButtonsTooltip'}>
                                        <Button
                                            type='primary'
                                            htmlType='submit'
                                            style={{
                                                height: '40px',
                                                backgroundColor: 'rgba(7, 7, 250, 0.26)',
                                                border: '1px solid transparent',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginTop: '10px',
                                                width: '100%'

                                            }}
                                            onClick={onCreateNewActivity}
                                            onDoubleClick={() => setIsLoading(true)}
                                            disabled={true}
                                            loading={loading}
                                        >
                                        Add
                                    </Button>
                                    </Tooltip>
                                :
                                null

                    }

                </CommonActivityModal>
            </div>
            {
                popConfirmModalOpened
                &&
                <div className={s.popConfirm}>
                    <div className={s.popConfirm__content} style={{
                        height: 'auto'
                    }}>
                        <div className={s.popConfirm__warningIcon}>
                            <WarningActivityIcon/>
                        </div>
                        <p className={s.popConfirm__deleteTitle}>Unsaved links</p>
                        <p className={s.popConfirm__deleteText}>Are you sure you want to leave this requirement? If you
                            change the requirement the links will be deleted.</p>
                        <div className={s.popConfirm__deleteBtns}>
                            <Button
                                type='primary'
                                htmlType='submit'
                                style={{
                                    height: '44px',
                                    backgroundColor: '#1755E7',
                                    color: '#fff',
                                    width: '170px',
                                    border: '1px solid #1755E7',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',

                                }}

                                onClick={() => setPopConfirmModalOpened(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='default'
                                style={{
                                    width: '170px',
                                    height: '44px',
                                }}
                                onClick={onCancelActivityCreation}
                            >
                                Leave
                            </Button>

                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

const CustomCell = React.memo(({
                                   field,
                                   value,
                                   params,
                                   properties,
                                   dataType,

                               }: any) => {
    const dispatch = useAppDispatch()
    const currentProp = properties.find((p: any) => p.REF === Number(value))
    const checkedPropertiesIds = useAppSelector(selectCurrentActivitiesCheckedPropertiesIds)
    const [disabledCheckbox, setDisabledCheckbox] = useState(false)
    const currentRowValues = useAppSelector(selectActivitiesCurrentRow)
    const onGetPropertiesSurface = (value: string) => {

        if (!currentProp.surfaces.length) {
            dispatch(GetActivitiesSurfaceThunk({prop_id: params.row.REF, req_id: 1357}))
        } else {
            dispatch(onSetActivitiesSurfaceIsOpened({isOpened: !currentProp.isOpened, ref: value}))
        }
    }

    if (field === "REF") {


        const handleCheckProperties = (value: any) => {
            setDisabledCheckbox(true)
            dispatch(onSetCurrentModalTab('Existing Links'))
            if (value.target.checked === true) {
                dispatch(onCheckActivitiesPropertiesIds({property: value.target.name, isChecked: value.target.checked}))
                dispatch(onCheckActivitiesProperties({property: params.row, isChecked: value.target.checked, gridType: 'Activities/Properties'}))
            } else {
                dispatch(onCheckActivitiesPropertiesIds({property: value.target.name, isChecked: value.target.checked}))
                dispatch(onCheckActivitiesProperties({property: params.row, isChecked: value.target.checked, gridType: 'Activities/Properties'}))
            }
            setTimeout(() => {
                setDisabledCheckbox(false)
            }, 50)
        }
        return (
            <div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                }}>
                    <div>
                        <Checkbox
                            onChange={handleCheckProperties}
                            name={value}
                            checked={checkedPropertiesIds.includes(value)}
                            disabled={disabledCheckbox || currentRowValues.ACT_TYPE === 'Documentation'}
                            style={{
                                marginRight: '8px'
                            }}
                        />
                        <span>{value}</span>
                    </div>
                    {
                         params.row.withSurface
                            ?
                            <>
                                <button
                                    onClick={() => onGetPropertiesSurface(value)}
                                    className={propertiesStyles.surfaceBtn}
                                >
                                    Surfaces
                                    <img src={surfaceArrow} alt="arrow-down"/>
                                </button>
                            </>
                            :
                            null
                    }

                </div>
            </div>

        )
    } else {
        if (dataType === 'class java.math.BigDecimal') {
            const europeanString = value !== null ? value?.toLocaleString('de-DE') : '';
            return <div>{europeanString}</div>;
        } else {
            return <div>{value}</div>;
        }

    }
});


const CustomGridRow = React.memo((props: any) => {
    const dispatch = useAppDispatch()
    const {row} = props;
    const checkedSurfacesIds = useAppSelector(selectActivitiesCheckedSurfacesIds)
    const [disabledCheckbox, setDisabledCheckbox] = useState(false)
    const currentRowValues = useAppSelector(selectActivitiesCurrentRow)
    const handleCheckSurfaces = (value: any) => {
        setDisabledCheckbox(true)
        dispatch(onSetCurrentModalTab('Existing Links'))
        if (value.target.checked === true) {
            dispatch(onCheckSurfacesIds({
                surfaceId: Number(value.target.value.VALUE_01),
                isChecked: value.target.checked
            }))
            dispatch(onCheckSurfaces({surface: value.target.value, isChecked: value.target.checked}))
        } else {
            dispatch(onCheckSurfacesIds({
                surfaceId: Number(value.target.value.VALUE_01),
                isChecked: value.target.checked
            }))
            dispatch(onCheckSurfaces({surface: value.target.value, isChecked: value.target.checked}))
        }
        setTimeout(() => {
            setDisabledCheckbox(false)
        }, 50)
    }

    return (
        <div className={propertiesStyles.customRow}>
            <GridRow {...props} />
            {
                row.isOpened
                    ?
                    <div className={propertiesStyles.surfaceInner}>
                        {row.surfaces.map((surf: any, index: number) => {
                            return (
                                <div key={index} style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Checkbox
                                        onChange={handleCheckSurfaces}
                                        name={row.PROP_ID}
                                        value={surf}
                                        checked={checkedSurfacesIds.includes(Number(surf.VALUE_01))}
                                        style={{
                                            marginRight: '8px'
                                        }}
                                        disabled={disabledCheckbox || currentRowValues.ACT_TYPE === 'Documentation'}
                                    />
                                    <span
                                        className={propertiesStyles.surfaceList}>REF {surf?.VALUE_01} | {surf?.LABEL_02} {surf?.VALUE_02} | {surf?.LABEL_03} {surf?.VALUE_03} | {surf?.LABEL_04} {surf?.VALUE_04} | {surf?.LABEL_05} {surf?.VALUE_05} | {surf?.LABEL_06} {surf?.VALUE_06}</span>
                                </div>
                            )
                        })}

                    </div>
                    :
                    null
            }
        </div>
    );
});


export default OrganizationActivitiesProperties1;
