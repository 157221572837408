import React, {useEffect, useRef, useState} from 'react';
import s from "../../PropertiesPage/Properties.module.scss";
import {
    onSetLocalSortModel,
    selectAllPropertiesGridHiddenColumns,
    selectAllPropertiesGridShownColumns,
    selectPropertiesGridHiddenColumns,
    selectPropertiesGridShownColumns,
    selectSurfacesGridHiddenColumns,
    selectSurfacesGridShownColumns,
    setHiddenColumns,
    setShownColumns
} from "../../../store/propertiesReducer";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    onSetReqLocalSortModel, selectRequirementsHiddenColumns, selectRequirementsShownColumns,
    setRequirementsHiddenColumns, setRequirementsShownColumns
} from "../../../store/requirementsReducer";
import {
    onSetActivitiesCreationLocalSortModel,
    onSetActivitiesLocalSortModel, selectActivitiesCreationGridHiddenColumns,
    selectActivitiesCreationGridShownColumns,
    selectActivitiesGridHiddenColumns,
    selectActivitiesGridShownColumns,
    setActivitiesCreationGridHiddenColumns,
    setActivitiesCreationGridShownColumns,
    setActivitiesHiddenColumns,
    setActivitiesShownColumns
} from "../../../store/activitesGrid";
import {
    onSetBrokerageReqsLocalSortModel,
    selectBrokerageHiddenColumns,
    selectBrokerageShownColumns, setBrokerageReqsHiddenColumns,
    setBrokerageReqsShownColumns
} from "../../../store/brokerageReqsReducer";


interface PropertiesViewOptionsType {
    checked: string[]
    filtersShown: boolean
    setChecked: (checkedArray: string[]) => void
    setFilterShown: (isShown: boolean) => void
    gridType: string
    isMobileMenu?: boolean
}

const PropertiesViewOptions = ({
                                   checked,
                                   filtersShown,
                                   setChecked,
                                   setFilterShown,
                                   gridType,
                                   isMobileMenu
                               }: PropertiesViewOptionsType) => {
    const dispatch = useAppDispatch()
    const propertiesGridShownColumns = useAppSelector(selectPropertiesGridShownColumns)
    const surfacesGridShownColumns = useAppSelector(selectSurfacesGridShownColumns)
    const allPropertiesGridShownColumns = useAppSelector(selectAllPropertiesGridShownColumns)
    const propertiesGridHiddenColumns = useAppSelector(selectPropertiesGridHiddenColumns)
    const allPropertiesGridHiddenColumns = useAppSelector(selectAllPropertiesGridHiddenColumns)
    const surfacesGridHiddenColumns = useAppSelector(selectSurfacesGridHiddenColumns)
    const requirementsShownColumns = useAppSelector(selectRequirementsShownColumns)
    const requirementsHiddenColumns = useAppSelector(selectRequirementsHiddenColumns)
    const activitiesShownColumns = useAppSelector(selectActivitiesGridShownColumns)
    const activitiesHiddenColumns = useAppSelector(selectActivitiesGridHiddenColumns)
    const brokerageShownColumns = useAppSelector(selectBrokerageShownColumns)
    const brokerageHiddenColumns = useAppSelector(selectBrokerageHiddenColumns)
    const activitiesCreateGridShownColumns = useAppSelector(selectActivitiesCreationGridShownColumns)
    const activitiesCreateGridHiddenColumns = useAppSelector(selectActivitiesCreationGridHiddenColumns)

    const shownColumns =
        gridType === 'Properties'
            ? propertiesGridShownColumns
            : gridType === 'Surfaces'
                ? surfacesGridShownColumns
                : gridType === 'Requirements'
                    ? requirementsShownColumns
                    : gridType === 'Activities Grid'
                        ? activitiesShownColumns
                        : gridType === 'Brokerage/Requirements'
                            ? brokerageShownColumns
                            : gridType === 'Activities Grid/Create/Update'
                                ? activitiesCreateGridShownColumns
                                : allPropertiesGridShownColumns
    const hiddenColumns = gridType === 'Properties'
        ? propertiesGridHiddenColumns
        : gridType === 'Surfaces'
            ? surfacesGridHiddenColumns
            : gridType === 'Requirements'
                ? requirementsHiddenColumns
                : gridType === 'Activities Grid'
                    ? activitiesHiddenColumns
                    : gridType === 'Brokerage/Requirements'
                        ? brokerageHiddenColumns
                        : gridType === 'Activities Grid/Create/Update'
                            ? activitiesCreateGridHiddenColumns
                            : allPropertiesGridHiddenColumns
    const paginatedPageDivRef = useRef<HTMLDivElement>(null);
    const [draggingColPos, setDraggingColPos] = useState<null | number>()
    // useEffect for tracking outside the View options click to close it , when user click outside the View options
    useEffect(() => {
        const closeDivOutsideClick = (event: MouseEvent) => {
            const target = event.target as Node;
            const isCheckbox = target instanceof HTMLInputElement && target.type === 'checkbox';
            if (paginatedPageDivRef.current && !paginatedPageDivRef.current.contains(target) && !isCheckbox) {
                setFilterShown(false);
            }
        };
        document.addEventListener('click', closeDivOutsideClick);
        return () => {
            document.removeEventListener('click', closeDivOutsideClick);
        };
    }, [setFilterShown]);

    // Check/uncheck the view options
    const handleCheck = (event: any) => {
        const name = event.target.name;
        if (event.target.checked) {
            const checkedArray = [...checked, name]
            const newShownColumns = shownColumns.filter(
                (column: any) => !checkedArray.includes(column.field)
            );
            const newHiddenColumns = hiddenColumns.concat(
                shownColumns.filter((column: any) => checkedArray.includes(column.field))
            );
            setChecked(checkedArray);
            dispatch(onSetLocalSortModel({sort_model: [], gridType: gridType}));
            dispatch(onSetReqLocalSortModel({sort_model: []}))
            dispatch(onSetActivitiesLocalSortModel({sort_model: []}))
            dispatch(onSetBrokerageReqsLocalSortModel({sort_model: []}))
            if (gridType === 'Reqs') {
                dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Reqs'}))
            } else if (gridType === 'Properties') {
                dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Properties'}))
            } else if (gridType === 'Surfaces') {
                dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Surfaces'}))
            }
            if (gridType === 'Requirements') {
                setTimeout(() => {

                    dispatch(setRequirementsShownColumns({
                        columns: newShownColumns.map((c: any) => c && {
                            ...c,
                            width: c.width || 180
                        }).filter((col: any) => col.field !== 'SURFACE_EXISTED')
                    }))
                    dispatch(setRequirementsHiddenColumns({columns: newHiddenColumns}))

                }, 400)

            } else if (gridType === 'Activities Grid') {
                setTimeout(() => {

                    dispatch(setActivitiesShownColumns({
                        columns: newShownColumns.map((c: any) => c && {
                            ...c,
                            width: c.width || 180
                        }).filter((col: any) => col.field !== 'SURFACE_EXISTED')
                    }))
                    dispatch(setActivitiesHiddenColumns({columns: newHiddenColumns}))

                }, 400)
            } else if (gridType === 'Brokerage/Requirements') {
                setTimeout(() => {

                    dispatch(setBrokerageReqsShownColumns({
                        columns: newShownColumns.map((c: any) => c && {
                            ...c,
                            width: c.width || 180
                        }).filter((col: any) => col.field !== 'SURFACE_EXISTED')
                    }))
                    dispatch(setBrokerageReqsHiddenColumns({columns: newHiddenColumns}))

                }, 400)
            } else if (gridType === 'Activities Grid/Create/Update') {
                setTimeout(() => {

                    dispatch(setActivitiesCreationGridShownColumns({
                        columns: newShownColumns.map((c: any) => c && {
                            ...c,
                            width: c.width || 180
                        }).filter((col: any) => col.field !== 'SURFACE_EXISTED')
                    }))
                    dispatch(setActivitiesCreationGridHiddenColumns({columns: newHiddenColumns}))

                }, 400)
            } else {
                setTimeout(() => {

                    dispatch(setShownColumns({
                        columns: newShownColumns.map((c: any) => c && {
                            ...c,
                            width: c.width || 180
                        }).filter((col: any) => col.field !== 'SURFACE_EXISTED'),
                        gridType: gridType
                    }))
                    dispatch(setHiddenColumns({columns: newHiddenColumns, gridType: gridType}))

                }, 400)
            }

        } else {
            const checkedArray = checked.filter((c: any) => c !== name)
            const newHiddenColumns = hiddenColumns.filter(
                (column: any) => checkedArray.includes(column.field)
            );
            const newShownColumns = shownColumns.concat(
                hiddenColumns.filter((column: any) => !checkedArray.includes(column.field))
            );
            setChecked(checkedArray)
            if (gridType === 'Requirements') {
                dispatch(setRequirementsHiddenColumns({columns: newHiddenColumns}))
                dispatch(setRequirementsShownColumns({
                    columns: newShownColumns.map((c: any) => c && {
                        ...c,
                        width: 180
                    }).sort((a: any, b: any) => a.col_pos - b.col_pos)
                }))

            } else if (gridType === 'Activities Grid') {
                dispatch(setActivitiesHiddenColumns({columns: newHiddenColumns}))
                dispatch(setActivitiesShownColumns({
                    columns: newShownColumns.map((c: any) => c && {
                        ...c,
                        width: 180
                    }).sort((a: any, b: any) => a.col_pos - b.col_pos)
                }))
            } else if (gridType === 'Brokerage/Requirements') {
                dispatch(setBrokerageReqsHiddenColumns({columns: newHiddenColumns}))
                dispatch(setBrokerageReqsShownColumns({
                    columns: newShownColumns.map((c: any) => c && {
                        ...c,
                        width: 180
                    }).sort((a: any, b: any) => a.col_pos - b.col_pos)
                }))
            } else if (gridType === 'Activities Grid/Create/Update') {
                dispatch(setActivitiesCreationGridHiddenColumns({columns: newHiddenColumns}))
                dispatch(setActivitiesCreationGridShownColumns({
                    columns: newShownColumns.map((c: any) => c && {
                        ...c,
                        width: 180
                    }).sort((a: any, b: any) => a.col_pos - b.col_pos)
                }))
            } else {
                dispatch(setHiddenColumns({columns: newHiddenColumns, gridType: gridType}))
                dispatch(setShownColumns({
                    columns: newShownColumns.map((c: any) => c && {
                        ...c,
                        width: 180
                    }).filter((col: any) => col.field !== 'SURFACE_EXISTED').sort((a: any, b: any) => a.col_pos - b.col_pos),
                    gridType: gridType
                }))
            }


        }
    };

    const onShowAll = () => {
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'Properties'}));
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'All Properties/REQ'}))
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'All Properties/Upcoming'}))
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'Surfaces'}))
        if (gridType === 'Reqs') {
            dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Reqs'}))
        } else if (gridType === 'Properties') {
            dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Properties'}))
        } else if (gridType === 'Surfaces') {
            dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Surfaces'}))
        }
        dispatch(onSetReqLocalSortModel({sort_model: []}))
        dispatch(onSetActivitiesLocalSortModel({sort_model: []}))
        dispatch(onSetBrokerageReqsLocalSortModel({sort_model: []}))
        if (gridType === 'Requirements') {
            setTimeout(() => {
                const allShownColumn = shownColumns.concat(hiddenColumns)
                dispatch(setRequirementsShownColumns({
                    columns: allShownColumn.filter((c: any) => c.field !== 'SURFACE_EXISTED').map((c: any) => c && {
                        ...c,
                        width: 220
                    }).sort((a: any, b: any) => a.col_pos - b.col_pos)
                }))
                dispatch(setRequirementsHiddenColumns({columns: []}))
                setChecked([])
            }, 400)

        } else if (gridType === 'Activities Grid') {
            setTimeout(() => {
                const allShownColumn = shownColumns.concat(hiddenColumns)
                dispatch(setActivitiesShownColumns({
                    columns: allShownColumn.filter((c: any) => c.field !== 'SURFACE_EXISTED').map((c: any) => c && {
                        ...c,
                        width: 220
                    }).sort((a: any, b: any) => a.col_pos - b.col_pos)
                }))
                dispatch(setActivitiesHiddenColumns({columns: []}))
                setChecked([])
            }, 400)
        } else if (gridType === 'Brokerage/Requirements') {
            setTimeout(() => {
                const allShownColumn = shownColumns.concat(hiddenColumns)
                dispatch(setBrokerageReqsShownColumns({
                    columns: allShownColumn.filter((c: any) => c.field !== 'SURFACE_EXISTED').map((c: any) => c && {
                        ...c,
                        width: 220
                    }).sort((a: any, b: any) => a.col_pos - b.col_pos)
                }))
                dispatch(setBrokerageReqsHiddenColumns({columns: []}))
                setChecked([])
            }, 400)
        } else if (gridType === 'Activities Grid/Create/Update') {
            setTimeout(() => {
                const allShownColumn = shownColumns.concat(hiddenColumns)
                dispatch(setActivitiesCreationGridShownColumns({
                    columns: allShownColumn.filter((c: any) => c.field !== 'SURFACE_EXISTED').map((c: any) => c && {
                        ...c,
                        width: 220
                    }).sort((a: any, b: any) => a.col_pos - b.col_pos)
                }))
                dispatch(setActivitiesCreationGridHiddenColumns({columns: []}))
                setChecked([])
            }, 400)
        } else {
            setTimeout(() => {
                const allShownColumn = shownColumns.concat(hiddenColumns)
                dispatch(setShownColumns({
                    columns: allShownColumn.filter((c: any) => c.field !== 'SURFACE_EXISTED').map((c: any) => c && {
                        ...c,
                        width: 220
                    }).sort((a: any, b: any) => a.col_pos - b.col_pos), gridType: gridType
                }))
                dispatch(setHiddenColumns({columns: [], gridType: gridType}))
                setChecked([])
            }, 400)
        }


    }

    const onHideAll = () => {
        dispatch(onSetLocalSortModel({sort_model: [], gridType: gridType}));
        dispatch(onSetReqLocalSortModel({sort_model: []}))
        dispatch(onSetActivitiesLocalSortModel({sort_model: []}))
        dispatch(onSetBrokerageReqsLocalSortModel({sort_model: []}))
        if (gridType === 'Reqs') {
            dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Reqs'}))
        } else if (gridType === 'Properties') {
            dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Properties'}))
        } else if (gridType === 'Surfaces') {
            dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Surfaces'}))
        }
        const localShownColumns = shownColumns.filter((col: any) => col.field === 'PROP_ID' || col.field === 'SURF_REF' || col.field === 'ACTIVITY_EXISTED' || col.field === 'REQ_ID' || col.field === 'REF')
        const localHiddenColumns = shownColumns.filter((col: any) => col.field !== 'PROP_ID' && col.field !== 'SURF_REF' && col.field !== 'ACTIVITY_EXISTED' && col.field !== 'REQ_ID' && col.field !== 'REF')
        if (gridType === 'Requirements') {
            setTimeout(() => {
                const allHiddenColumn = localHiddenColumns.concat(localShownColumns)
                dispatch(setRequirementsHiddenColumns({
                    columns: localHiddenColumns.sort((a: any, b: any) => a.col_pos - b.col_pos)
                }))
                dispatch(setRequirementsShownColumns({columns: localShownColumns}))
                setChecked(allHiddenColumn.map((c: any) => c.field))
            }, 400)

        } else if (gridType === 'Activities Grid') {
            setTimeout(() => {
                const allHiddenColumn = localHiddenColumns.concat(localShownColumns)
                dispatch(setBrokerageReqsHiddenColumns({
                    columns: localHiddenColumns.sort((a: any, b: any) => a.col_pos - b.col_pos)
                }))
                dispatch(setActivitiesShownColumns({columns: localShownColumns}))
                setChecked(allHiddenColumn.map((c: any) => c.field))
            }, 400)
        } else if (gridType === 'Brokerage/Requirements') {
            setTimeout(() => {
                const allHiddenColumn = localHiddenColumns.concat(localShownColumns)
                dispatch(setActivitiesHiddenColumns({
                    columns: localHiddenColumns.sort((a: any, b: any) => a.col_pos - b.col_pos)
                }))
                dispatch(setBrokerageReqsShownColumns({columns: localShownColumns}))
                setChecked(allHiddenColumn.map((c: any) => c.field))
            }, 400)
        } else if (gridType === 'Activities Grid/Create/Update') {
            setTimeout(() => {
                const allHiddenColumn = localHiddenColumns.concat(localShownColumns)
                dispatch(setActivitiesCreationGridHiddenColumns({
                    columns: localHiddenColumns.sort((a: any, b: any) => a.col_pos - b.col_pos)
                }))
                dispatch(setActivitiesCreationGridShownColumns({columns: localShownColumns}))
                setChecked(allHiddenColumn.map((c: any) => c.field))
            }, 400)
        } else {
            setTimeout(() => {
                const allHiddenColumn = localHiddenColumns.concat(localShownColumns)
                dispatch(setHiddenColumns({
                    columns: localHiddenColumns.sort((a: any, b: any) => a.col_pos - b.col_pos),
                    gridType: gridType
                }))
                dispatch(setShownColumns({columns: localShownColumns, gridType: gridType}))
                setChecked(allHiddenColumn.map((c: any) => c.field))
            }, 400)
        }
    }


    const handleDragStart = (e: any, colPos: any) => {
        setDraggingColPos(colPos);
        // Other drag start logic...
    };
    // const handleDragOver = (e: any, index: any) => {
    //     e.preventDefault(); // Necessary for the drop event to fire.
    // };


    const handleDrop = (e: any, dropColPos: any) => {
        e.preventDefault();

        const draggingIndex = shownColumns.findIndex((item: any) => item.col_pos === draggingColPos);
        const dropIndex = shownColumns.findIndex((item: any) => item.col_pos === dropColPos);

        if (draggingIndex === -1 || dropIndex === -1 || draggingIndex === dropIndex) {
            setDraggingColPos(null);
            return;
        }

        const newItems = [...shownColumns];
        const [reorderedItem] = newItems.splice(draggingIndex, 1);
        newItems.splice(dropIndex, 0, reorderedItem);

        // Recalculate col_pos for each item
        const updatedItems = newItems.map((item, index) => ({
            ...item,
            col_pos: index + 1
        }));
        if (gridType === 'Requirements') {
            dispatch(setRequirementsShownColumns({columns: updatedItems}))

        } else if (gridType === 'Activities Grid') {
            dispatch(setActivitiesShownColumns({columns: updatedItems}))
        } else if (gridType === 'Brokerage/Requirements') {
            dispatch(setBrokerageReqsShownColumns({columns: updatedItems}))
        } else {
            dispatch(setShownColumns({columns: updatedItems, gridType: gridType}))
        }
        // setItems(updatedItems);
        setDraggingColPos(null);
    };


    return (
        <>
            {filtersShown &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    position: !isMobileMenu ? 'absolute' : 'relative',
                    maxHeight: '580px',
                    overflowY: 'scroll',
                    width: !isMobileMenu ? '350px' : '100%',
                    zIndex: '15',
                    top: !isMobileMenu ? gridType === 'Activities Grid/Create/Update' ? '54px' : '-84px' : '0'
                }}
                     className={s.filtersModal}
                     ref={paginatedPageDivRef}
                >
                    <div
                        style={{
                            width: '100%'
                        }}
                    >
                        <div className={s.filtersModal__header}>
                            <h3>View Option</h3>
                        </div>
                        {
                            isMobileMenu
                            &&
                            <div className={s.mobileDivider}></div>
                        }
                        <div className={s.filtersModal__shownHidden}>
                            <div className={s.filtersSwitcherInner}>
                                <h4>Shown:</h4>
                                <button disabled={!shownColumns.length} onClick={onHideAll}
                                        className={s.hideShowSwitcher}>Hide
                                    all
                                </button>
                            </div>
                            {shownColumns.filter((col: any) => col?.width !== 0 && col?.field !== 'PROP_ID' && col.field !== 'ACTIVITY_EXISTED' && col?.field !== 'SURF_REF' && col?.field !== 'REF' && col?.field !== 'ACTIONS' && col?.field !== 'CONTACT_REF' && col?.field !== 'COMPANY_REF').map((column: any, index: number) => {
                                    return (
                                        <div
                                            key={column.field}
                                            className={s.filtersModal__item}
                                            draggable={true}
                                            onDragStart={(e) => handleDragStart(e, column.col_pos)}
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={(e) => handleDrop(e, column.col_pos)}
                                        >
                                            <svg width="11" height="17" viewBox="0 0 11 17" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M3.38447 4.675C4.08544 4.675 4.6537 4.10416 4.6537 3.4C4.6537 2.69584 4.08544 2.125 3.38447 2.125C2.68349 2.125 2.11523 2.69584 2.11523 3.4C2.11523 4.10416 2.68349 4.675 3.38447 4.675Z"
                                                    fill="#808080"/>
                                                <path
                                                    d="M7.61493 4.675C8.31591 4.675 8.88416 4.10416 8.88416 3.4C8.88416 2.69584 8.31591 2.125 7.61493 2.125C6.91396 2.125 6.3457 2.69584 6.3457 3.4C6.3457 4.10416 6.91396 4.675 7.61493 4.675Z"
                                                    fill="#808080"/>
                                                <path
                                                    d="M3.38447 9.7751C4.08544 9.7751 4.6537 9.20426 4.6537 8.5001C4.6537 7.79593 4.08544 7.2251 3.38447 7.2251C2.68349 7.2251 2.11523 7.79593 2.11523 8.5001C2.11523 9.20426 2.68349 9.7751 3.38447 9.7751Z"
                                                    fill="#808080"/>
                                                <path
                                                    d="M7.61493 9.7751C8.31591 9.7751 8.88416 9.20426 8.88416 8.5001C8.88416 7.79593 8.31591 7.2251 7.61493 7.2251C6.91396 7.2251 6.3457 7.79593 6.3457 8.5001C6.3457 9.20426 6.91396 9.7751 7.61493 9.7751Z"
                                                    fill="#808080"/>
                                                <path
                                                    d="M3.38447 14.875C4.08544 14.875 4.6537 14.3041 4.6537 13.6C4.6537 12.8958 4.08544 12.325 3.38447 12.325C2.68349 12.325 2.11523 12.8958 2.11523 13.6C2.11523 14.3041 2.68349 14.875 3.38447 14.875Z"
                                                    fill="#808080"/>
                                                <path
                                                    d="M7.61493 14.8752C8.31591 14.8752 8.88416 14.3044 8.88416 13.6002C8.88416 12.896 8.31591 12.3252 7.61493 12.3252C6.91396 12.3252 6.3457 12.896 6.3457 13.6002C6.3457 14.3044 6.91396 14.8752 7.61493 14.8752Z"
                                                    fill="#808080"/>
                                            </svg>
                                            <label className={s.checkbox}>
                                                {
                                                    column.field !== "REQ_ID"
                                                    &&
                                                    <input
                                                        type="checkbox"
                                                        name={column.field}
                                                        checked={checked.includes(column.field)}
                                                        onChange={handleCheck}
                                                        style={{display: 'none'}}
                                                    />
                                                }
                                                <p>{column.headerName!?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())}</p>
                                                {
                                                    column.field !== "REQ_ID"
                                                    &&
                                                    <svg className={'eye-checkbox'} width="18"
                                                         height="18"
                                                         viewBox="0 0 18 18" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M9 6.75C9.59674 6.75 10.169 6.98705 10.591 7.40901C11.0129 7.83097 11.25 8.40326 11.25 9C11.25 9.59674 11.0129 10.169 10.591 10.591C10.169 11.0129 9.59674 11.25 9 11.25C8.40326 11.25 7.83097 11.0129 7.40901 10.591C6.98705 10.169 6.75 9.59674 6.75 9C6.75 8.40326 6.98705 7.83097 7.40901 7.40901C7.83097 6.98705 8.40326 6.75 9 6.75ZM9 3.375C12.75 3.375 15.9525 5.7075 17.25 9C15.9525 12.2925 12.75 14.625 9 14.625C5.25 14.625 2.0475 12.2925 0.75 9C2.0475 5.7075 5.25 3.375 9 3.375ZM2.385 9C2.99119 10.2377 3.93248 11.2806 5.10186 12.0099C6.27124 12.7393 7.6218 13.126 9 13.126C10.3782 13.126 11.7288 12.7393 12.8981 12.0099C14.0675 11.2806 15.0088 10.2377 15.615 9C15.0088 7.76227 14.0675 6.71944 12.8981 5.99006C11.7288 5.26068 10.3782 4.87401 9 4.87401C7.6218 4.87401 6.27124 5.26068 5.10186 5.99006C3.93248 6.71944 2.99119 7.76227 2.385 9Z"
                                                            fill="#333333"/>
                                                    </svg>
                                                }
                                            </label>
                                        </div>
                                    )
                                }
                            )
                            }
                        </div>
                        {
                            isMobileMenu
                            &&
                            <div className={s.mobileDivider}></div>
                        }
                        <div className={s.filtersModal__shownHidden}>
                            <div className={s.filtersSwitcherInner}>
                                <h4>Hidden:</h4>
                                <button onClick={onShowAll} className={s.hideShowSwitcher}>Show
                                    all
                                </button>
                            </div>
                            {hiddenColumns.filter((col) => col?.field !== 'ACTIONS' && col?.field !== 'CONTACT_REF' && col?.field !== 'COMPANY_REF').map((column: any) => (
                                <div key={column.field} className={s.filtersModal__item}>
                                    <svg width="11" height="17" viewBox="0 0 11 17" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3.38447 4.675C4.08544 4.675 4.6537 4.10416 4.6537 3.4C4.6537 2.69584 4.08544 2.125 3.38447 2.125C2.68349 2.125 2.11523 2.69584 2.11523 3.4C2.11523 4.10416 2.68349 4.675 3.38447 4.675Z"
                                            fill="#808080"/>
                                        <path
                                            d="M7.61493 4.675C8.31591 4.675 8.88416 4.10416 8.88416 3.4C8.88416 2.69584 8.31591 2.125 7.61493 2.125C6.91396 2.125 6.3457 2.69584 6.3457 3.4C6.3457 4.10416 6.91396 4.675 7.61493 4.675Z"
                                            fill="#808080"/>
                                        <path
                                            d="M3.38447 9.7751C4.08544 9.7751 4.6537 9.20426 4.6537 8.5001C4.6537 7.79593 4.08544 7.2251 3.38447 7.2251C2.68349 7.2251 2.11523 7.79593 2.11523 8.5001C2.11523 9.20426 2.68349 9.7751 3.38447 9.7751Z"
                                            fill="#808080"/>
                                        <path
                                            d="M7.61493 9.7751C8.31591 9.7751 8.88416 9.20426 8.88416 8.5001C8.88416 7.79593 8.31591 7.2251 7.61493 7.2251C6.91396 7.2251 6.3457 7.79593 6.3457 8.5001C6.3457 9.20426 6.91396 9.7751 7.61493 9.7751Z"
                                            fill="#808080"/>
                                        <path
                                            d="M3.38447 14.875C4.08544 14.875 4.6537 14.3041 4.6537 13.6C4.6537 12.8958 4.08544 12.325 3.38447 12.325C2.68349 12.325 2.11523 12.8958 2.11523 13.6C2.11523 14.3041 2.68349 14.875 3.38447 14.875Z"
                                            fill="#808080"/>
                                        <path
                                            d="M7.61493 14.8752C8.31591 14.8752 8.88416 14.3044 8.88416 13.6002C8.88416 12.896 8.31591 12.3252 7.61493 12.3252C6.91396 12.3252 6.3457 12.896 6.3457 13.6002C6.3457 14.3044 6.91396 14.8752 7.61493 14.8752Z"
                                            fill="#808080"/>
                                    </svg>
                                    <label className={s.checkbox}>
                                        <input
                                            type="checkbox"
                                            name={column.field}
                                            checked={checked.includes(column.field)}
                                            onChange={handleCheck}
                                            style={{display: 'none'}}
                                        />
                                        <p>{column.headerName!?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())}</p>
                                        <svg width="18" height="18" viewBox="0 0 18 18"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_1387_36977)">
                                                <path
                                                    d="M9 6.75C9.59674 6.75 10.169 6.98705 10.591 7.40901C11.0129 7.83097 11.25 8.40326 11.25 9C11.25 9.59674 11.0129 10.169 10.591 10.591C10.169 11.0129 9.59674 11.25 9 11.25C8.40326 11.25 7.83097 11.0129 7.40901 10.591C6.98705 10.169 6.75 9.59674 6.75 9C6.75 8.40326 6.98705 7.83097 7.40901 7.40901C7.83097 6.98705 8.40326 6.75 9 6.75ZM9 3.375C12.75 3.375 15.9525 5.7075 17.25 9C15.9525 12.2925 12.75 14.625 9 14.625C5.25 14.625 2.0475 12.2925 0.75 9C2.0475 5.7075 5.25 3.375 9 3.375ZM2.385 9C2.99119 10.2377 3.93248 11.2806 5.10186 12.0099C6.27124 12.7393 7.6218 13.126 9 13.126C10.3782 13.126 11.7288 12.7393 12.8981 12.0099C14.0675 11.2806 15.0088 10.2377 15.615 9C15.0088 7.76227 14.0675 6.71944 12.8981 5.99006C11.7288 5.26068 10.3782 4.87401 9 4.87401C7.6218 4.87401 6.27124 5.26068 5.10186 5.99006C3.93248 6.71944 2.99119 7.76227 2.385 9Z"
                                                    fill="#333333"/>
                                                <path
                                                    d="M1.5 3.9525L2.46 3L15 15.54L14.0475 16.5L11.7375 14.19C10.875 14.475 9.96 14.625 9 14.625C5.25 14.625 2.0475 12.2925 0.75 9C1.2675 7.68 2.0925 6.5175 3.1425 5.595L1.5 3.9525ZM9 6.75C9.59674 6.75 10.169 6.98705 10.591 7.40901C11.0129 7.83097 11.25 8.40326 11.25 9C11.2504 9.25542 11.2073 9.50905 11.1225 9.75L8.25 6.8775C8.49095 6.79274 8.74458 6.74962 9 6.75ZM9 3.375C12.75 3.375 15.9525 5.7075 17.25 9C16.6379 10.5549 15.5977 11.9045 14.25 12.8925L13.185 11.82C14.2222 11.1025 15.0587 10.1318 15.615 9C15.0087 7.76245 14.0673 6.71983 12.898 5.99063C11.7286 5.26143 10.3781 4.87491 9 4.875C8.1825 4.875 7.38 5.01 6.63 5.25L5.475 4.1025C6.555 3.6375 7.7475 3.375 9 3.375ZM2.385 9C2.99133 10.2375 3.93268 11.2802 5.10205 12.0094C6.27142 12.7386 7.6219 13.1251 9 13.125C9.5175 13.125 10.0275 13.0725 10.5 12.9675L8.79 11.25C8.26812 11.1941 7.78112 10.9612 7.40998 10.59C7.03884 10.2189 6.80594 9.73188 6.75 9.21L4.2 6.6525C3.4575 7.29 2.835 8.085 2.385 9Z"
                                                    fill="#333333"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1387_36977">
                                                    <rect width="18" height="18"
                                                          fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default PropertiesViewOptions;

