import React, {useEffect, useLayoutEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {
    DataGridPro,
    GridFilterPanel,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import s from "./Audit.module.scss";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    GetUserActivitiesThunk,
    onAuditResetFiltersButtonsSelected,
    onSetFiltersForAuditGrid,
    selectAuditDefaultColumns, selectAuditGridColumnsForFilters,
    selectAuditGridFiltersForArray,
    selectUserActivities,
    selectUserActivitiesColumns,
    setAuditColumns,
    setAuditGridColumnsForFilters,
    setUserActivities
} from "../../store/parametersReducer";
import {debounce} from 'lodash';
import moment from 'moment';
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
import {customSortFunction} from "../../helpers/sortingDataHelper";
import {Pagination} from "@mui/material";
import {Empty, Select} from "antd";
import {Lottie} from "@crello/react-lottie";
import animatedLoader from "../../img/Loader.json";
import {
    selectPropertiesGridLoaderMessage
} from "../../store/propertiesReducer";
import PropertiesSearchInput from "../PropertiesCommonComponents/PropertiesSearchInput/PropertiesSearchInput";
import {DateRangePicker} from "rsuite";
import {selectUserData} from "../../store/userReducer";
import PropertiesFilterButtons from "../PropertiesCommonComponents/PropertiesFilterButtons/PropertiesFilterButtons";
import PropertyFilterModal from "../PropertiesPage/PropertyFilterModal/PropertyFilterModal";
import {selectCurrentScreenWidth, selectIsMobileFiltersOpened} from "../../store/utilityReducer";
import {predefinedRanges} from "../../helpers/dateHelper";
import MobileFiltersButton from "../common/MobileFiltersButton/MobileFiltersButton";
import MobileFiltersMenu from "../common/MobileFiltersMenu/MobileFiltersMenu";
import {getShownAndHiddenColumns} from "../../commonGridFunctions/getShownAndHiddenColumns";
import {sessionStorageGridFilters} from "../../helpers/sessionStorageHelper";
import {gridFiltering} from "../../customFunctions/gridFiltering";



const AuditGrid = () => {
    return (
        <PageWithSidebar>
            <AuditGridComponent/>
        </PageWithSidebar>
    )
}

const AuditGridComponent = () => {
    const dispatch = useAppDispatch()
    const [height, setHeight] = useState(window.innerHeight);
    const [gridContentHeight, setGridContentHeight] = useState(height - 500);
    const apiRef = useGridApiRef();
    let tableRows = useAppSelector(selectUserActivities)
    const rowsPerPageOptions = [300, 400, 500];
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [page, setPage] = React.useState(1);
    const [isGridDataLoading, setIsGridDataLoading] = useState(true)
    const gridLoaderMessage = useAppSelector(selectPropertiesGridLoaderMessage)
    const [searchTerm, setSearchTerm] = useState('');
    const gridColumns = useAppSelector(selectUserActivitiesColumns)
    const userData = useAppSelector(selectUserData)
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)
    const twoMonthsAgo = new Date(); // Get today's date
    // twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2); // Set three months ago
    twoMonthsAgo.setDate(twoMonthsAgo.getDate() - 7); // Set three months ago
    const today = new Date(); // Get today's date
    const defaultAuditGridColumns = useAppSelector(selectAuditDefaultColumns)
    const gridFilters = useAppSelector(selectAuditGridFiltersForArray)
    const auditGridColumnsForFilters = useAppSelector(selectAuditGridColumnsForFilters)
    const [selectedDateRange, setSelectedDateRange] = useState<[any | null, any | null]>([
        twoMonthsAgo,
        today,
    ]);
    const isMobileMenuOpened = useAppSelector(selectIsMobileFiltersOpened)
    useEffect(() => {
        dispatch(setAuditColumns({columns: []}))
    }, [dispatch])

    useEffect(() => {
        setIsGridDataLoading(true)
        dispatch(GetUserActivitiesThunk(
            {
                p_agent_ref: Number(userData.user_ref),
                p_beg_date: moment().utc().startOf('week').subtract(7, 'days'),
                p_end_date: moment().utc(),
                p_sort_order: null
            }
        ))
            .then(() => {
                setIsGridDataLoading(false)
            })
    }, [dispatch, userData.user_ref])


    let columns = gridColumns.map((g: any) => {

            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: g.COL_WIDTH,
                dataType: g.COL_TYPE,
                col_pos: g.COL_POS,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
                filterable: false
            }


    }).filter((c: any) => c.width !== 0).sort((a, b) => a.col_pos - b.col_pos)
    const [sortModel, setSortModel] = useState<any>([
        {field: 'activity_time', sort: 'desc'},
    ]);

    const handleSortModelChange = (newModel: any) => {
        setSortModel(newModel);
    };

    useLayoutEffect(() => {
        const handleResize = debounce(function () {
            setHeight(window.innerHeight);
            const heightForPage = window.innerHeight - 500;
            setGridContentHeight(heightForPage);
        }, 100);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const sortedRows = customSortFunction(tableRows, sortModel);

    const onFilterPanelClose = () => {
        apiRef.current.hideFilterPanel()
    };


    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    const handleSearchInputChange = (event: any) => {
        setSearchTerm(event.target.value);
    };

    const rows = sortedRows.reduce((acc, row) => {
        const values = Object.values(row);
        const useStartsWith = searchTerm.endsWith('%');
        const processedSearchTerm = searchTerm.slice(0, -1).toLowerCase();
        if (values.some(value => {
            const stringValue = String(value).toLowerCase();
            return useStartsWith ? stringValue.startsWith(processedSearchTerm) : stringValue.includes(searchTerm.toLowerCase());
        })) {
            acc.push(row);
        }
        return acc;
    }, []);


    function handleRowsPerPageChange(event: any) {
        setRowsPerPage(event);
        setPage(Math.ceil(rows?.length / event))
    }

    let paginatedRows = rows.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);


    const handleDateChange = (dates: any) => {
        setSelectedDateRange(dates);
        if (dates !== null){
            setIsGridDataLoading(true)
            const endDate = new Date(dates[1]);
            endDate.setHours(20, 59, 59, 999);
            const startDate = new Date(dates[0]);
            startDate.setHours(0, 0, 0, 0);
            dispatch(GetUserActivitiesThunk(
                {
                    p_end_date: moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                    p_beg_date: moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                    p_agent_ref: Number(userData.user_ref),
                    p_sort_order: null
                }
            ))
                .then(() => {
                    setIsGridDataLoading(false)
                })
        }
    };

    const onResetFilters = () => {
        dispatch(setUserActivities(defaultAuditGridColumns))
        dispatch(onAuditResetFiltersButtonsSelected())
        dispatch(onSetFiltersForAuditGrid({gridFilters: []}))
        sessionStorageGridFilters.removeAuditGridFilters()
    }

    const onResetSorting = () => {
        setSortModel([])
    }

    useEffect(() => {
        dispatch(setAuditGridColumnsForFilters({
            columns: gridColumns.filter((c: any) => c.COL_NAME !== 'Actions').map((g: any) => {

                return {
                    title: g.COL_NAME,
                    headerName: g.COL_TITLE,
                    value: null,
                    condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                    dataType: g.COL_TYPE,
                    isModalBtnOpened: false,
                    isFilterSelected: false,
                    filterable: false,
                }
            })
        }))
    }, [dispatch, gridColumns])

    useEffect(() => {
        const filterColumnsFromSessionStorage = sessionStorageGridFilters.getAuditGridFilters()
        if (filterColumnsFromSessionStorage && filterColumnsFromSessionStorage.length) {
            dispatch(setAuditGridColumnsForFilters({
                columns: gridColumns.filter((c: any) => c.COL_NAME !== 'Actions').map((g: any) => {

                    return {
                        title: g.COL_NAME,
                        headerName: g.COL_TITLE,
                        value: null,
                        condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                        dataType: g.COL_TYPE,
                        isModalBtnOpened: false,
                        isFilterSelected: false,
                        filterable: false,
                    }
                })
            }))
            dispatch(onSetFiltersForAuditGrid({gridFilters: filterColumnsFromSessionStorage}))
        }
        dispatch(setAuditGridColumnsForFilters({
            columns: gridColumns.filter((c: any) => c.COL_NAME !== 'Actions').map((g: any) => {

                return {
                    title: g.COL_NAME,
                    headerName: g.COL_TITLE,
                    value: null,
                    condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                    dataType: g.COL_TYPE,
                    isModalBtnOpened: false,
                    isFilterSelected: false,
                    filterable: false,
                }
            })
        }))
    }, [dispatch, gridColumns])


    useEffect(() => {
        const filterColumnsFromSessionStorage = sessionStorageGridFilters.getAuditGridFilters()
        if (filterColumnsFromSessionStorage) {
            gridFiltering(filterColumnsFromSessionStorage, defaultAuditGridColumns, auditGridColumnsForFilters, 'Audit', dispatch)
        }
    }, [dispatch, defaultAuditGridColumns, auditGridColumnsForFilters])

    return (
        <div className={s.inner}>
            <div className={s.tableInner}>
                <h1 className={s.tableTitle}>Audit</h1>

                <div style={{
                    display: 'flex',
                    alignItems: currentScreenWidth! > 768 ? 'center' : 'flex-start',
                    marginBottom: '0px',
                    marginLeft: '17px',
                    flexDirection: currentScreenWidth! > 768 ? 'row' : 'column'
                }}>
                    <PropertiesSearchInput
                        searchTerm={searchTerm}
                        setSearchTerm={(searchTerm: string) => setSearchTerm(searchTerm)}
                        handleSearchInputChange={handleSearchInputChange}
                        isActivity={false}
                        isAudit={true}
                    />
                    <div
                    style={{
                        display: currentScreenWidth! > 768 ? 'block' : 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center'
                    }}
                    >
                        <div style={{
                            marginLeft: currentScreenWidth! > 768 ? '30px' : 0,
                            marginBottom: '10px',
                            marginTop: '0px'
                        }}>
                            {/* @ts-ignore */}
                            <DateRangePicker onChange={(value) => handleDateChange(value)} ranges={currentScreenWidth! > 768 ? predefinedRanges : []}
                                             placeholder="Start date - End date"
                                             style={{ width: currentScreenWidth! > 760 ? 280 : 260}}
                                             defaultCalendarValue={selectedDateRange}
                                             value={selectedDateRange}
                            />
                        </div>
                        {
                            currentScreenWidth! <= 768
                            &&
                            <MobileFiltersButton/>
                        }
                    </div>
                </div>
                {
                    currentScreenWidth! > 768
                    &&
                    <div style={{
                        marginLeft: '20px'
                    }}>
                        <PropertiesFilterButtons
                            shownColumns={columns}
                            onResetFilters={onResetFilters}
                            onResetSorting={onResetSorting}
                            gridType={'Audit'}
                        />
                    </div>
                }

                <div style={{
                    position: 'relative',
                    width: '100%',
                }}>
                    {columns.map((c: any) => {
                        return (
                            <PropertyFilterModal
                                gridType={'Audit'}
                                textValue={c.field}
                            />
                        )
                    })}
                </div>
                <Box sx={{position: 'relative', width: '100%', padding: '0 10px'}}>
                    {
                        isGridDataLoading
                            ?
                            <div style={{
                                height: gridContentHeight + 235,
                                width: '400px',
                                margin: '0 auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}>
                                <div style={{
                                    height: '300px',
                                    width: '100%',
                                    textAlign: 'center'
                                }}>
                                    <Lottie
                                        // @ts-ignore
                                        config={{animationData: animatedLoader, loop: true, height: '60px !important'}}
                                    />
                                </div>

                                <p style={{
                                    textAlign: 'center'
                                }}>{gridLoaderMessage}</p>
                            </div>
                            :
                            gridColumns.length
                            ?
                                <>
                                    {/*<PropertiesViewOptions*/}
                                    {/*    checked={checked}*/}
                                    {/*    filtersShown={filtersShown}*/}
                                    {/*    setChecked={(checkedArray: string[]) => setChecked(checkedArray)}*/}
                                    {/*    setFilterShown={(isShown: boolean) => setFiltersShown(isShown)}*/}
                                    {/*    gridType={'Audit'}*/}
                                    {/*/>*/}
                                    <DataGridPro
                                        columns={columns.map((column: any, index: number) => ({
                                            ...column,

                                            renderCell: (params: any) => {
                                                return (
                                                    <CustomCell field={column.field}
                                                                value={params.value}
                                                                params={params}
                                                                properties={paginatedRows}
                                                                key={index}
                                                                dataType={column.dataType}
                                                    />
                                                )


                                            },
                                            renderHeader: (params: any) => {
                                                return (
                                                    <div style={{
                                                        display: 'flex',
                                                    }}>
                                                        <span
                                                            className={s.columnHeader}>
                                                            {params?.colDef?.headerName?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())}
                                                        </span>
                                                    </div>
                                                );
                                                // }

                                            },
                                        }))}
                                        sx={{
                                            borderRight: 'none',
                                            borderLeft: 'none',
                                            borderBottom: 'none',
                                            borderRadius: '0px',
                                            "& .MuiDataGrid-cellContent": {
                                                fontFamily: 'Inter, sans-serif',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#111827'
                                            },
                                            "& .MuiDataGrid-columnHeaderTitle": {
                                                fontFamily: 'Inter, sans-serif',
                                                fontWeight: 600,
                                                color: '#6B7280',
                                                fontSize: '12px',
                                                lineHeight: '20px',
                                            },
                                            "& .MuiDataGrid-row:hover": {
                                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                            },
                                            "& .MuiDataGrid-row.Mui-selected": {
                                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                            },
                                            "& .MuiDataGrid-row.Mui-selected:hover": {
                                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                            },
                                            "& .MuiDataGrid-cell:focus": {
                                                outline: 'none'
                                            },
                                            "& .MuiDataGrid-toolbarContainer": {
                                                color: '#454545',
                                                position: 'absolute',
                                                top: '-50px',
                                                right: '0px'
                                            },
                                            "& .MuiTablePagination-displayedRows": {
                                                margin: '0',
                                                fontFamily: 'Inter, sans-serif !important',
                                                fontWeight: 400,
                                                color: '#6B7280',
                                                fontSize: '12px',
                                                lineHeight: '20px',
                                            },
                                            "& .MuiPagination-root": {
                                                display: 'flex',
                                                justifyContent: 'center',
                                                padding: '7px 0px'
                                            },
                                            "& .MuiButtonBase-root.Mui-selected": {
                                                backgroundColor: '#F9FAFB',
                                                borderRadius: '0px'
                                            },
                                            "& .MuiButtonBase-root.MuiPaginationItem-root": {
                                                width: '40px',
                                                height: '40px',
                                                margin: 0,
                                                borderRight: '1px solid #D0D5DD',
                                                borderLeft: '1px solid #D0D5DD',
                                                borderTop: 'none',
                                                borderBottom: 'none',
                                                fontFamily: 'Inter, sans-serif !important',
                                                fontWeight: 500,
                                                color: '#1D2939',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                borderRadius: '0px'
                                            },
                                            "& .MuiPagination-ul": {
                                                border: '1px solid #D0D5DD',
                                                borderRadius: '8px'
                                            },
                                            "& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext": {
                                                borderRadius: '0px',
                                                border: 'none'
                                            },
                                            "& .MuiDataGrid-row:nth-of-type(odd)": {
                                                backgroundColor: '#F9FAFB'
                                            },
                                            "& .MuiDataGrid-virtualScrollerRenderZone": {
                                                width: '100%'
                                            },
                                            "& .MuiDataGrid-virtualScroller": {
                                                overflowY: 'scroll !important',
                                                overflowX: 'scroll',
                                                height: `${gridContentHeight + 95}px !important`,
                                                minHeight: 'auto',
                                            },
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: '#FCFDFE'
                                            },
                                            "& .MuiDataGrid-root": {
                                                marginTop: '50px',
                                            }
                                        }}
                                        apiRef={apiRef}
                                        getRowId={e => e.id}
                                        rows={paginatedRows}
                                        {...paginatedRows}
                                        headerHeight={39}
                                        hideFooterSelectedRowCount
                                        onSortModelChange={handleSortModelChange}
                                        sortModel={sortModel}
                                        autoPageSize={true}
                                        autoHeight={true}
                                        rowHeight={25}
                                        sortingOrder={['desc', 'asc']}
                                        // filterModel={filterModel}
                                        // onFilterModelChange={(model) => setFilterModel(model)}
                                        components={{
                                            Footer: () =>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent:  currentScreenWidth! > 768 ? 'space-between' : 'center',
                                                    alignItems: 'center',
                                                    width: '100%'
                                                }}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:  currentScreenWidth! > 768 ? 'space-between' : 'center',
                                                            alignItems: 'center',

                                                        }}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent:  currentScreenWidth! > 768 ? 'space-between' : 'center',
                                                                alignItems: 'center',
                                                            }}>
                                                            {
                                                                currentScreenWidth! > 768
                                                                &&
                                                                <>
                                                                    <p
                                                                        className={s.footerText}
                                                                    >
                                                                        Rows per page:
                                                                    </p>
                                                                    <Select
                                                                        value={rowsPerPage}
                                                                        onChange={handleRowsPerPageChange}
                                                                        className={s.rowsPerPage}
                                                                    >
                                                                        {rowsPerPageOptions.map((option) => (
                                                                            <Select.Option key={option} value={option}>
                                                                                {option}
                                                                            </Select.Option>
                                                                        ))}
                                                                    </Select>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <Pagination
                                                        // count={Math.ceil(sortedRows.length / rowsPerPage)}
                                                        count={Math.ceil(rows.length / rowsPerPage)}
                                                        // count={Math.ceil(gridFilters.length ? (rows.length / rowsPerPage) : (defaultAuditGridColumns.length / rowsPerPage))}
                                                        page={page}
                                                        onChange={handlePageChange}
                                                        variant="outlined"
                                                        shape="rounded"
                                                    />
                                                    {
                                                        currentScreenWidth! > 768
                                                        &&
                                                        // <p className={s.footerText}>
                                                        //     Total rows: {sortedRows.length}
                                                        // </p>
                                                        <p className={s.footerText}>
                                                        Total{gridFilters.length ? '/Filtered' : ''} rows: {gridFilters.length ? defaultAuditGridColumns.length : sortedRows.length}{gridFilters.length ? `/${sortedRows.length}` : ''}
                                                        </p>
                                                    }
                                                </div>,
                                            FilterPanel: () => <CustomPanel handleClick={onFilterPanelClose}/>
                                            // Panel: CustomPanel,
                                        }}
                                    />
                                </>


                                :
                                <div style={{
                                    height: `${gridContentHeight + 150}px`,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Empty/>
                                </div>
                    }

                </Box>

            </div>
            {
                isMobileMenuOpened
                &&
                <MobileFiltersMenu
                    checkedViewOptions={[]}
                    filtersShown={true}
                    setCheckedViewOptions={() => {}}
                    setFiltersShown={() => {}}
                    gridType={'Audit'}
                    shownColumns={getShownAndHiddenColumns(columns as any[], 'Shown')}
                    hiddenColumns={[]}
                    userData={userData}
                    today={today}
                    threeMonthsAgo={twoMonthsAgo}
                    gridColumns={gridColumns}
                    setSearchTerm={setSearchTerm}
                    setLocalSortModel={() => {}}
                    localSortModel={[]}
                    handleFiltersShownClick={() => {}}
                    resetFilters={onResetFilters}
                    top={'0px'}
                />
            }
        </div>
    );
};


const CustomPanel = (props: any) => {

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            <GridFilterPanel/>
            <div
                onClick={props.handleClick}
                style={{
                    alignSelf: 'flex-end',
                    color: '#1976d2',
                    marginRight: '20px',
                    marginBottom: '20px',
                    cursor: 'pointer',
                    marginTop: '-35px',
                    textTransform: 'uppercase',
                    fontSize: '0.875rem',
                    fontWeight: '500',
                    fontFamily: 'Roboto, Helvetica, Arial ,sans-serif'
                }}
            >
                Close
            </div>
        </div>
    )
}


const CustomCell = React.memo(({
                                   value,
                                   field,
                                   onActivityDelete
                               }: any) => {

    if (field === 'ACTIONS') {
        return <button
            className={s.deleteBtn}
            onClick={onActivityDelete}
        >
            Delete
        </button>
    } else {
        return (

            <div>{value}</div>
        )
    }


});

export default AuditGrid;