import React, {ChangeEvent, memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {gridDataHelper} from "../../helpers/localStorageHelper";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectUserData} from "../../store/userReducer";
import {DataGridPro, GridColumnMenu, GridRow, useGridApiRef} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import {
    deletePropertyEmail,
    deleteSurfaceForEmail,
    GetPropertiesEmailDomain,
    GetPropertiesEmailGreeting,
    GetPropertiesEmailLinkText,
    GetPropertiesEmailPhotosForLinks,
    GetPropertiesEmailSubject,
    GetPropertiesGridContacts,
    GetPropertiesGridContactsByReqId,
    GetPropertiesGridThunk,
    GetPropertiesSurface,
    GetPropertyActivities,
    GetPropertyAgentAddress,
    onAddCheckedProperties,
    onAddCheckedSurfaces,
    onChangeColumnsGridPosition,
    onChangeOfferMessageGreeting,
    onClearPropertyActivities,
    onFilterPropertiesGrid,
    onResetFiltersButtonsSelected,
    onSetLocalSortModel,
    onSetOfferToValuesInitial,
    onSetPropertiesGridLoaderMessage,
    onSetPropertiesOfferFormError,
    onSetProperties,
    onSetSurfaceIsOpened,
    selectCheckedSurfaces,
    selectContactsForEmail,
    selectDefaultContact,
    selectEmailGreeting,
    selectPropertiesGridHiddenColumns,
    selectIsGridColumnsInitialCall,
    selectOfferMessageGreeting,
    selectPropertiesGridCheckedProperties,
    selectPropertiesGridDefaultColumnsValues,
    selectPropertiesGridLoaderMessage,
    selectPropertiesGridLocalSortModel,
    selectPropertiesGridSortingRule,
    selectPropertiesTest,
    selectPropertiesTestColumns,
    selectPropertiesGridShownColumns,
    setColumnsForFilters,
    setHiddenColumns,
    setShownColumns,
    GetPropertiesGridContactsUncheckedByReqId,
    selectPropertyContactLang,
    onChangeColumnsGridWidth,
    selectAllPropertiesCurrentGrid,
    clearPropertiesForEmail,
    onClearCheckedProperties,
    onChangeCurrentGridTab,
    GetSurfaceGridThunk,
    selectSurfacesGridData,
    selectSurfacesGridDataColumns,
    selectSurfacesGridShownColumns,
    onClearCheckedSurfaces,
    clearSurfacesForEmail,
    onSetAllSurfaceClosed,
    onResetPropertiesGridColumns,
    selectSurfacesGridHiddenColumns,
    selectSurfacesDefaultGridColumns,
    selectSurfacesGridLocalSortModel,
    selectSurfacesGridSortingRule,
    onSetIsActivityModalOpened,
    selectActivitiesModalOpened,
    onSetPropertiesMessageBody,
    selectCreateContactModalOpened,
    selectIs404Error,
    setSurfacesColumnsForFilters,
    selectPropertiesColumnsForFilters,
    selectSurfacesColumnsForFilters,
    selectPropertiesGridFiltersForArray, selectSurfacesGridFiltersForArray, onFilterSurfacesGrid,
} from "../../store/propertiesReducer";
import {Button, Checkbox, Empty, Select} from "antd";
import PageTitle from "../common/PageTitle/PageTitle";
import s from './Properties.module.scss'
import {ReactComponent as RedDot} from './../../img/icons/redDot.svg'
import {ReactComponent as GreenDot} from './../..//img/icons/greenDot.svg'
import PropertyFilterModal from "./PropertyFilterModal/PropertyFilterModal";
import 'swiper/css';
import SwiperCore, {Navigation} from 'swiper';
import {CreateAndUpdateActivityRequest} from "../../app/api";
import surfaceArrow from './../../img/icons/chevron-down.svg'
import PropertiesHeaderButtons from "../PropertiesCommonComponents/PropertiesHeaderButtons/PropertiesHeaderButtons";
import PropertiesSearchInput from "../PropertiesCommonComponents/PropertiesSearchInput/PropertiesSearchInput";
import PropertiesFilterButtons from "../PropertiesCommonComponents/PropertiesFilterButtons/PropertiesFilterButtons";
import PropertiesViewOptions from "../PropertiesCommonComponents/PropertiesViewOptions/PropertiesViewOptions";
import PropertiesOfferModal from "./PropertiesOfferModal/PropertiesOfferModal";
import PropertiesActivityModal from "./PropertiesActivityModal/PropertiesActivityModal";
import {Lottie} from "@crello/react-lottie";
import animatedLoader from './../../img/Loader.json'
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
import {customSortFunction} from "../../helpers/sortingDataHelper";
import CommonActivityModal from "../common/CommonActivityModal/CommonActivityModal";
import ActivitiesGridForm from "../ActivitiesGridForm/ActivitiesGridForm";
import {
    CreateNewActivityThunk,
    GetActivitiesContactsThunk,
    onChangeModalActivityFormField,
    onCheckActivitiesProperties,
    onCheckActivitiesPropertiesIds,
    onCheckSurfaces,
    onCheckSurfacesIds, onClearActivitiesPropertiesAndSurfaces,
    onDeleteCheckedProperty,
    onDeleteCheckedSurface,
    onSetActivitiesContactId,
    onSetActivitiesOrganizationId,
    onSetCurrentContactRef,
    onSetCurrentModalTab,
    onSetIsDescriptionFieldError,
    onSetIsSavingButtonDisabled,
    selectActivitiesCheckedSurfaces,
    selectActivitiesCheckedSurfacesIds,
    selectActivitiesKindNumberEntity,
    selectActivitiesModalFormFields,
    selectActivitiesTypeNumberEntity,
    selectActivityModalCurrentTab,
    selectContactId,
    selectCurrentActivitiesCheckedProperties,
    selectCurrentActivitiesCheckedPropertiesIds,
    selectOrganizationId,
} from "../../store/activitesGrid";
import moment from "moment";
import plusImg from "../../img/icons/plus.svg";
import {GetCompanyByReqRefThunk} from "../../store/companies";
import propertiesStyles from "./Properties.module.scss";
import Tooltip from "../Tooltip/Tooltip";
import CreateContactModal from "../PropertiesCommonComponents/CreateContactModal/CreateContactModal";
import {GetSurfaceUsagesTypes, selectCurrentScreenWidth, selectIsMobileFiltersOpened} from "../../store/utilityReducer";
import MobileFiltersButton from "../common/MobileFiltersButton/MobileFiltersButton";
import MobileFiltersMenu from "../common/MobileFiltersMenu/MobileFiltersMenu";
import {resetGridConfigs} from "../../commonGridFunctions/resetGridConfigs";
import {saveGridConfigs} from "../../commonGridFunctions/saveGridConfigs";
import {getShownAndHiddenColumns} from "../../commonGridFunctions/getShownAndHiddenColumns";
import {MuiGridDataColumns} from "../../types/commonTypes";
import {GetMapPropertiesStatusesThunk} from "../../store/propertiesMap";
import {sessionStorageGridFilters} from "../../helpers/sessionStorageHelper";
import {gridFiltering} from "../../customFunctions/gridFiltering";
import {Pagination} from "@mui/material";
import { throttle } from 'lodash';

SwiperCore.use([Navigation]);

export interface ColumnData {
    properties: {
        gridColumns: { field: string; sort: string }[];
        gridPropId: number;
    }[];
}

const Properties = () => {
    return (
        <PageWithSidebar>
            <PropertiesPageComponent/>
        </PageWithSidebar>
    )
}

const PropertiesPageComponent: React.FC = () => {
    const divRef = useRef(null);
    const gridRowId = gridDataHelper.getRowId()
    const gridRowTitle = gridDataHelper.getRowTitle()
    const userData = useAppSelector(selectUserData)
    const dispatch = useAppDispatch()
    const [page, setPage] = React.useState(1);
    const [currentPropId, setCurrentPropId] = useState(0)
    const [filtersShown, setFiltersShown] = useState(false)
    const contactsForEmail = useAppSelector(selectContactsForEmail)
    const propertiesGridShownColumns = useAppSelector(selectPropertiesGridShownColumns)
    const surfacesGridShownColumns = useAppSelector(selectSurfacesGridShownColumns)
    const propertiesGridHiddenColumns = useAppSelector(selectPropertiesGridHiddenColumns)
    const surfacesGridHiddenColumns = useAppSelector(selectSurfacesGridHiddenColumns)
    const emailGreeting = useAppSelector(selectEmailGreeting)
    const defaultContact = useAppSelector(selectDefaultContact)
    const propertiesGridColumns = useAppSelector(selectPropertiesTestColumns)
    const surfacesGridColumns = useAppSelector(selectSurfacesGridDataColumns)
    let propertiesTestDataRows = useAppSelector(selectPropertiesTest)
    let surfacesDataRows = useAppSelector(selectSurfacesGridData)
    const [openedActivity, setOpenedActivity] = useState(false)
    const defaultPropertiesGridColumns = useAppSelector(selectPropertiesGridDefaultColumnsValues)
    const defaultSurfacesGridColumns = useAppSelector(selectSurfacesDefaultGridColumns)
    const [height, setHeight] = useState(window.innerHeight);
    const [gridContentHeight, setGridContentHeight] = useState(height - 440);
    const offerMessageGreeting = useAppSelector(selectOfferMessageGreeting)
    const checkedProperties = useAppSelector(selectPropertiesGridCheckedProperties)
    const checkedSurfaces = useAppSelector(selectCheckedSurfaces)
    const [isDisabledSaveBtn, setIsDisabledSaveBtn] = useState(false)
    const rowsPerPageOptions = [50, 100, 150, 200, 500];
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[2]);
    const isInitialGridColumnsCall = useAppSelector(selectIsGridColumnsInitialCall)
    const gridLoaderMessage = useAppSelector(selectPropertiesGridLoaderMessage)
    const contactLang = useAppSelector(selectPropertyContactLang)
    const currentGrid = useAppSelector(selectAllPropertiesCurrentGrid)
    const gridColumns = currentGrid === 'Properties' ? propertiesGridColumns : surfacesGridColumns
    const shownColumns = currentGrid === 'Properties' ? propertiesGridShownColumns : surfacesGridShownColumns
    const hiddenColumns = currentGrid === 'Properties' ? propertiesGridHiddenColumns : surfacesGridHiddenColumns
    const propertiesSortModel = useAppSelector(selectPropertiesGridLocalSortModel)
    const surfacesSortModel = useAppSelector(selectSurfacesGridLocalSortModel)
    const propertiesSortingRule = useAppSelector(selectPropertiesGridSortingRule)
    const surfacesSortingRule = useAppSelector(selectSurfacesGridSortingRule)
    const sortModel = currentGrid === 'Properties' ? propertiesSortModel : surfacesSortModel
    const sortingRule = currentGrid === 'Properties' ? propertiesSortingRule : surfacesSortingRule
    const reqGridDomain = gridDataHelper.getReqGridDomain()
    const isCreateActivityModalOpened = useAppSelector(selectActivitiesModalOpened)
    const [loading, setIsLoading] = useState(false)
    const currentTab = useAppSelector(selectActivityModalCurrentTab)
    const reqGridRowData = gridDataHelper.getReqGridRowData()
    const activitiesProperties = useAppSelector(selectCurrentActivitiesCheckedProperties)
    const activitiesCheckedSurfaces = useAppSelector(selectActivitiesCheckedSurfaces)
    const organizationId = useAppSelector(selectOrganizationId)
    const contactId = useAppSelector(selectContactId)
    const typeNumberEntity = useAppSelector(selectActivitiesTypeNumberEntity)
    const kindNumberEntity = useAppSelector(selectActivitiesKindNumberEntity)
    const modalFormFields = useAppSelector(selectActivitiesModalFormFields)
    const checkedPropertiesIds = useAppSelector(selectCurrentActivitiesCheckedPropertiesIds)
    const checkedSurfacesIds = useAppSelector(selectActivitiesCheckedSurfacesIds)
    const isCreateModalOpened = useAppSelector(selectCreateContactModalOpened)
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)
    const isMobileMenuOpened = useAppSelector(selectIsMobileFiltersOpened)
    const threeMonthsAgo = new Date(); // Get today's date
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3); // Set three months ago
    const today = new Date(); // Get today's date
    const is404Error = useAppSelector(selectIs404Error)
    const propertiesColumnsForFilters = useAppSelector(selectPropertiesColumnsForFilters)
    const surfacesColumnsForFilters = useAppSelector(selectSurfacesColumnsForFilters)
    const propertiesGridFilters = useAppSelector(selectPropertiesGridFiltersForArray)
    const surfacesGridFilters = useAppSelector(selectSurfacesGridFiltersForArray)
    const gridFilters = currentGrid === 'Properties' ? propertiesGridFilters : surfacesGridFilters
    const defaultGridColumns = currentGrid === 'Properties' ? defaultPropertiesGridColumns : defaultSurfacesGridColumns

    useEffect(() => {
        dispatch(GetSurfaceUsagesTypes())
        dispatch(GetMapPropertiesStatusesThunk())
    }, [dispatch])

    useEffect(() => {
        if (offerMessageGreeting === '' && emailGreeting) {
            dispatch(onChangeOfferMessageGreeting(emailGreeting.message))
        }

    }, [offerMessageGreeting, emailGreeting, dispatch])

    useEffect(() => {
        if (!contactsForEmail.length) {
            dispatch(GetPropertiesGridContacts())
        }
    }, [dispatch, contactsForEmail])
    let columns: MuiGridDataColumns[] = gridColumns.map((g: any) => {
        if (g.COL_NAME === 'ACTIVITY_EXISTED') {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: g.COL_WIDTH,
                dataType: g.COL_TYPE,
                col_pos: g.COL_POS,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
                withSurface: g.withSurface
            }
        } else if (g.COL_NAME === 'LAST_ACTION') {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: g.COL_WIDTH,
                dataType: g.COL_TYPE,
                col_pos: 2,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
                withSurface: g.withSurface
            }
        } else if (g.COL_NAME === 'SURF_REF') {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: g.COL_WIDTH,
                dataType: g.COL_TYPE,
                col_pos: 1,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
                withSurface: g.withSurface
            }
        } else if (g.COL_NAME === 'PROP_REF') {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: g.COL_WIDTH,
                dataType: g.COL_TYPE,
                col_pos: 2,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
                withSurface: g.withSurface
            }
        } else {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: g.COL_WIDTH,
                dataType: g.COL_TYPE,
                col_pos: g.COL_POS,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
                withSurface: g.withSurface
            }
        }
    })


    useEffect(() => {
        if (defaultContact && defaultContact[0]) {
            dispatch(onSetOfferToValuesInitial(defaultContact[0].PE_EMAIL))
        }

    }, [defaultContact, dispatch])

    useEffect(() => {
        // const filterColumnsLC = gridDataHelper.getPropertiesGridFilters()
        const propertiesFiltersColumnsFromSessionStorage = sessionStorageGridFilters.getPropertiesGridFilters()
        const surfacesFiltersColumnsFromSessionStorage = sessionStorageGridFilters.getSurfacesGridFilters()
        if (currentGrid === 'Properties') {
            if (propertiesFiltersColumnsFromSessionStorage && propertiesFiltersColumnsFromSessionStorage.length) {
                dispatch(onFilterPropertiesGrid({
                    gridFilters: propertiesFiltersColumnsFromSessionStorage,
                    gridType: currentGrid
                }))
                dispatch(setColumnsForFilters({
                    columns: gridColumns.map((g: any) => {
                        if (g.COL_NAME === 'ACTIVITY_EXISTED') {
                            return {
                                title: g.COL_NAME,
                                headerName: 'activity_existed',
                                value: null,
                                condition: 'LIKE',
                                dataType: "class java.lang.String",
                                isModalBtnOpened: false,
                                isFilterSelected: false,
                                filterable: false,
                            }
                        } else {
                            return {
                                title: g.COL_NAME,
                                headerName: g.COL_TITLE,
                                value: null,
                                condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                                dataType: g.COL_TYPE,
                                isModalBtnOpened: false,
                                isFilterSelected: false,
                                filterable: false,
                            }
                        }
                    }), gridType: 'Properties'
                }))
            }
            dispatch(setColumnsForFilters({
                columns: gridColumns.map((g: any) => {
                    if (g.COL_NAME === 'ACTIVITY_EXISTED') {
                        return {
                            title: g.COL_NAME,
                            headerName: 'activity_existed',
                            value: null,
                            condition: 'LIKE',
                            dataType: "class java.lang.String",
                            isModalBtnOpened: false,
                            isFilterSelected: false,
                            filterable: false,
                        }
                    } else {
                        return {
                            title: g.COL_NAME,
                            headerName: g.COL_TITLE,
                            value: null,
                            condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                            dataType: g.COL_TYPE,
                            isModalBtnOpened: false,
                            isFilterSelected: false,
                            filterable: false,
                        }
                    }
                }), gridType: 'Properties'
            }))
        } else if (currentGrid === 'Surfaces') {
            if (surfacesFiltersColumnsFromSessionStorage && surfacesFiltersColumnsFromSessionStorage.length) {
                dispatch(onFilterSurfacesGrid({
                    gridFilters: surfacesFiltersColumnsFromSessionStorage,
                    gridType: currentGrid
                }))
                dispatch(setSurfacesColumnsForFilters({
                    columns: gridColumns.map((g: any) => {

                        return {
                            title: g.COL_NAME,
                            headerName: g.COL_TITLE,
                            value: null,
                            condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                            dataType: g.COL_TYPE,
                            isModalBtnOpened: false,
                            isFilterSelected: false,
                            filterable: false,
                        }

                    }), gridType: 'Surfaces'
                }))
            }
            dispatch(setSurfacesColumnsForFilters({
                columns: gridColumns.map((g: any) => {

                    return {
                        title: g.COL_NAME,
                        headerName: g.COL_TITLE,
                        value: null,
                        condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                        dataType: g.COL_TYPE,
                        isModalBtnOpened: false,
                        isFilterSelected: false,
                        filterable: false,
                    }

                }), gridType: 'Surfaces'
            }))
        }

    }, [dispatch, gridColumns, currentGrid])

    useEffect(() => {
        const propertiesFiltersColumnsFromSessionStorage = sessionStorageGridFilters.getPropertiesGridFilters()
        const surfacesFiltersColumnsFromSessionStorage = sessionStorageGridFilters.getSurfacesGridFilters()
        if (currentGrid === 'Properties') {
            gridFiltering(propertiesFiltersColumnsFromSessionStorage || [], defaultPropertiesGridColumns, propertiesColumnsForFilters, 'Properties', dispatch)
        } else if (currentGrid === 'Surfaces') {
            gridFiltering(surfacesFiltersColumnsFromSessionStorage || [], defaultSurfacesGridColumns, surfacesColumnsForFilters, 'Surfaces', dispatch)
        }
    }, [dispatch, currentGrid, defaultPropertiesGridColumns, defaultSurfacesGridColumns, propertiesColumnsForFilters, surfacesColumnsForFilters])

    useEffect(() => {
        if (reqGridDomain === 'RR') {
            dispatch(onChangeCurrentGridTab('Surfaces'))
        } else {
            dispatch(onChangeCurrentGridTab('Properties'))
        }

    }, [dispatch, reqGridDomain])


    useEffect(() => {
        dispatch(GetPropertiesEmailSubject({req_id: gridRowId, user_id: userData.user_ref}))
        dispatch(GetPropertyAgentAddress(userData.user_ref))
    }, [gridRowId, userData.user_ref, dispatch])


    useEffect(() => {
        dispatch(GetPropertiesEmailGreeting({req_id: gridRowId}))

    }, [gridRowId, dispatch])


    useEffect(() => {
        dispatch(GetPropertiesEmailDomain({req_id: gridRowId}))

    }, [gridRowId, dispatch])

    useEffect(() => {
        dispatch(GetPropertiesGridContactsByReqId({reqId: gridRowId}))
        dispatch(GetPropertiesGridContactsUncheckedByReqId({reqId: gridRowId}))

    }, [gridRowId, dispatch])


    useEffect(() => {
        setLocalSortModel([])
        dispatch(onSetLocalSortModel({sort_model: [], gridType: currentGrid}));
        if (currentGrid === 'Properties' && reqGridDomain !== 'RR') {
            dispatch(onSetLocalSortModel({sort_model: [], gridType: 'Surfaces'}))
            dispatch(onResetPropertiesGridColumns([]))
            dispatch(onSetProperties({properties: [], gridType: 'Surfaces'}))
            setTimeout(() => {
                dispatch(GetPropertiesGridThunk({
                    requestData: {
                        p_agent_ref: Number(userData.user_ref),
                        p_row_req: gridRowId,
                        p_sort_order: sortingRule,
                        page: 0,
                        size: 0,
                    },
                    isInitialCall: isInitialGridColumnsCall
                }))
            }, 3500)
            if (gridLoaderMessage === 'Reset data is in progress') {
                dispatch(onSetPropertiesGridLoaderMessage('Reset data is in progress'))
            } else {
                dispatch(onSetPropertiesGridLoaderMessage('Loading'))
            }
        } else if (currentGrid === 'Surfaces') {
            dispatch(onSetLocalSortModel({sort_model: [], gridType: 'Properties'}))
            dispatch(onResetPropertiesGridColumns([]))
            dispatch(onSetProperties({properties: [], gridType: 'Properties'}))
            setTimeout(() => {
                dispatch(GetSurfaceGridThunk({
                    requestData: {
                        p_agent_ref: Number(userData.user_ref),
                        p_row_req: gridRowId,
                        p_sort_order: sortingRule,
                        page: 0,
                        size: 0,
                        p_prop_req: null
                    }
                }))
            }, 3500)
            if (gridLoaderMessage === 'Reset data is in progress') {
                dispatch(onSetPropertiesGridLoaderMessage('Reset data is in progress'))
            } else {
                dispatch(onSetPropertiesGridLoaderMessage('Loading'))
            }
        }
        // dispatch(onSetLocalSortModel(currentPropColumns?.gridColumns.flat(1)))
    }, [dispatch, gridRowId, userData.user_ref, currentGrid, gridLoaderMessage, isInitialGridColumnsCall, reqGridDomain, sortingRule])


    const [localSortModel, setLocalSortModel] = useState<any>({});

    const [checkedViewOptions, setCheckedViewOptions] = useState<any>([]);

    const [hiddenModal, setHiddenModal] = useState(false)
    const handleSortModelChange = (newModel: any) => {
        const newLocalModel = {...localSortModel};
        newModel.forEach((model: any) => {
            newLocalModel[model.field] = model;
        });
        setLocalSortModel(newLocalModel);
        const mergedArray = [...sortModel, ...newModel];

        // Find the index of the matching field object in the merged array
        const index = mergedArray.findIndex(item => item?.field === sortModel[0]?.field);

        // Check if the sort value is different and update it
        if (index !== -1 && mergedArray[index]?.sort !== newModel[0]?.sort) {
            // eslint-disable-next-line
            const updatedObject = {
                ...mergedArray[index],
                sort: newModel[0]?.sort
            };
            mergedArray[index] = updatedObject;
        }


        dispatch(onSetLocalSortModel({sort_model: mergedArray, gridType: currentGrid}));
    };

    useEffect(() => {
        const sortModelObject: any = {};
        for (const item of sortModel) {
            sortModelObject[item.field] = {
                field: item.field,
                sort: item.sort
            };
        }
        setLocalSortModel(sortModelObject)
    }, [sortModel])

    const onSaveGridConfigs = () => {
        saveGridConfigs(shownColumns, hiddenColumns, currentGrid === 'Properties' ? propertiesGridColumns : surfacesGridColumns, localSortModel, userData, threeMonthsAgo, today, currentGrid, dispatch, gridRowId)
    }

    const onResetConfigs = () => {
        resetGridConfigs(dispatch, userData, threeMonthsAgo, today, setSearchTerm, setLocalSortModel, currentGrid, gridRowId, isInitialGridColumnsCall)
    }

    const apiRef = useGridApiRef();

    useEffect(() => {
        dispatch(setShownColumns({
            columns: getShownAndHiddenColumns(columns as MuiGridDataColumns[], 'Shown'), gridType: currentGrid
        }))
        dispatch(setHiddenColumns({
            columns: getShownAndHiddenColumns(columns as MuiGridDataColumns[], 'Hidden'), gridType: currentGrid
        }))
        setCheckedViewOptions(columns.filter((c: any) => c?.width === 0).map((c: any) => c.field))

        // eslint-disable-next-line
    }, [gridColumns, currentGrid, dispatch])

    const onSetFiltersShown = () => {
        setFiltersShown(!filtersShown)
    }


    const handleFiltersShownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // Prevent event bubbling to document click event listener
        onSetFiltersShown();
    };

    useEffect(() => {
        if (!checkedSurfaces.length && !checkedProperties.length) {
            dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
        }
    }, [dispatch, checkedSurfaces, checkedProperties])


    const onHideModal = () => {
        setHiddenModal(!hiddenModal)
        if (hiddenModal) {
            dispatch(onClearPropertyActivities())
            setOpenedActivity(false)
        }
    }

    const onCloseActivityModal = () => {
        dispatch(onClearPropertyActivities())
        setOpenedActivity(false)
        setIsDisabledSaveBtn(true)
        setTimeout(() => {
            setIsDisabledSaveBtn(false)
        }, 1000)
    }

    function handleRowsPerPageChange(event: any) {
        setRowsPerPage(event);
        setPage(1)
    }

    const onResetFilters = () => {
        if (currentGrid === 'Properties') {
            dispatch(onSetProperties({
                properties: currentGrid === 'Properties' ? defaultPropertiesGridColumns : defaultSurfacesGridColumns,
                gridType: currentGrid
            }))
            const resetShownColumns = shownColumns.map((c: any) => c && {
                ...c,
                isFilterSelected: false,
                isModalBtnOpened: false
            })
            dispatch(setShownColumns({columns: resetShownColumns, gridType: currentGrid}))
            dispatch(onResetFiltersButtonsSelected(currentGrid))
            dispatch(onFilterPropertiesGrid({gridFilters: [], gridType: currentGrid}))
            gridDataHelper.removePropertiesGridFilters()
            sessionStorageGridFilters.removePropertiesGridFilters()
        } else {
            dispatch(onSetProperties({
                properties: currentGrid === 'Properties' ? defaultPropertiesGridColumns : defaultSurfacesGridColumns,
                gridType: currentGrid
            }))
            const resetShownColumns = shownColumns.map((c: any) => c && {
                ...c,
                isFilterSelected: false,
                isModalBtnOpened: false
            })
            dispatch(setShownColumns({columns: resetShownColumns, gridType: currentGrid}))
            dispatch(onResetFiltersButtonsSelected(currentGrid))
            dispatch(onFilterPropertiesGrid({gridFilters: [], gridType: currentGrid}))
            gridDataHelper.removePropertiesGridFilters()
            sessionStorageGridFilters.removeSurfacesGridFilters()
        }
    }

    const onResetSorting = () => {
        dispatch(onSetLocalSortModel({sort_model: [], gridType: currentGrid}))
        setLocalSortModel({})
    }

    const handlePageChange = (params: any, value: number) => {
        setPage(value);
        afterPaginationPageChangeScrollToTop();
    }
    const afterPaginationPageChangeScrollToTop = () => {
        const gridApi = apiRef.current;
        if (!gridApi) return;
        gridApi.scrollToIndexes({rowIndex: 0});
    };


    const [searchTerm, setSearchTerm] = useState('');

    let sortedRows = customSortFunction(currentGrid === 'Properties' ? propertiesTestDataRows : surfacesDataRows, sortModel);


    const rows = sortedRows.reduce((acc, row) => {
        const values = Object.values(row);
        const useStartsWith = searchTerm.endsWith('%');
        const processedSearchTerm = searchTerm.slice(0, -1).toLowerCase();
        if (values.some(value => {
            const stringValue = String(value).toLowerCase();
            return useStartsWith ? stringValue.startsWith(processedSearchTerm) : stringValue.includes(searchTerm.toLowerCase());
        })) {
            acc.push(row);
        }
        return acc;
    }, []);


    const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    // layout effect to always adapt grid height to the user screen device height
    useLayoutEffect(() => {
        const handleResize = debounce(function () {
            setHeight(window.innerHeight);
            const heightForPage = window.innerHeight - 500;
            setGridContentHeight(heightForPage);
        }, 100);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const debounce = (func: Function, wait: number) => {
        let timeout: ReturnType<typeof setTimeout> | null;
        return function (this: any, ...args: any[]) {
            const context = this;
            clearTimeout(timeout!);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    };

    const onRowDoubleClick = (value: any) => {
        if (value.row.ACTIVITY_EXISTED === 1) {
            dispatch(GetPropertyActivities({req_id: gridRowId, row_pro: value.id, user_id: Number(userData.user_ref)}))
            setHiddenModal(true)
            setCurrentPropId(value.id)
            setOpenedActivity(true)
            dispatch(onClearActivitiesPropertiesAndSurfaces())
            dispatch(onSetIsActivityModalOpened(false))
            //
            dispatch(onSetLocalSortModel({sort_model: [], gridType: currentGrid}))
            dispatch(onFilterPropertiesGrid({gridFilters: [], gridType: currentGrid}))
            dispatch(onSetProperties({
                properties: currentGrid === 'Properties' ? defaultPropertiesGridColumns : defaultSurfacesGridColumns,
                gridType: currentGrid
            }))
        }
    }

    // We have custom column position in our state, so default function, provided MUI data grid doesn't fit our app. So we get the column order from state and then sort them in our state in redux
    const handleStateChange = (params: any) => {
        // Access the grid state
        dispatch(onChangeColumnsGridPosition({columns: params.columns.all, gridType: currentGrid}))
        dispatch(onChangeColumnsGridWidth({columns: params.columns.lookup, gridType: currentGrid}))
    };

    const onChangeCurrentGridType = (gridType: 'All Properties/REQ' | 'All Properties/Upcoming' | 'Address Properties' | 'Properties' | 'Surfaces') => {
        dispatch(onChangeCurrentGridTab(gridType))
        dispatch(onSetLocalSortModel({sort_model: [], gridType: currentGrid}))
        dispatch(onFilterPropertiesGrid({gridFilters: [], gridType: currentGrid}))
        gridDataHelper.removePropertiesGridFilters()
    }
    const onCreateNewActivity = () => {
        setIsLoading(true)
        const maxListLength = checkedPropertiesIds.length + checkedSurfacesIds.length;
        const link_type_list = []
        const link_entity_list = []
        let link_ref_list = Array.from({length: maxListLength + 3}, () => null)
        for (let i = 0; i < maxListLength; i++) {
            if (i < checkedPropertiesIds.length) {
                link_type_list.push(0);
            }
            if (i < checkedSurfacesIds.length) {
                link_type_list.push(14);
            }
        }
        for (let i = 0; i < maxListLength; i++) {
            if (i < checkedPropertiesIds.length) {
                link_entity_list.push(checkedPropertiesIds[i]);
            }
            if (i < checkedSurfacesIds.length) {
                link_entity_list.push(checkedSurfacesIds[i]);
            }
        }
        link_entity_list.unshift(gridRowId)
        link_type_list.unshift(1)
        link_type_list.unshift(2, 3)
        link_entity_list.unshift(organizationId, contactId)

        if (modalFormFields.description !== '') {
            dispatch(onSetIsSavingButtonDisabled(true))
            const req: CreateAndUpdateActivityRequest = {
                date_begin: moment(modalFormFields.date_from),
                date_end: moment(modalFormFields.date_till),
                description: modalFormFields.description !== '' ? modalFormFields.description : '',
                link_entity_list: link_entity_list,
                link_ref_list: link_ref_list,
                link_type_list: link_type_list,
                priority: modalFormFields.urgency === 'low' ? 0 : modalFormFields.urgency === 'avg' ? 1 : 2,
                remark: modalFormFields.remark !== '' ? modalFormFields.remark : '',
                type: typeNumberEntity,
                type_kind: kindNumberEntity
            }
            dispatch(CreateNewActivityThunk({activityData: req, isActivityGrid: false}))
                .then(() => {
                    setIsLoading(false)
                    dispatch(onSetIsActivityModalOpened(false))
                    dispatch(onClearActivitiesPropertiesAndSurfaces())
                })
                .then(() => {
                    /////reset modal fields
                    dispatch(onSetIsActivityModalOpened(false))
                    dispatch(clearPropertiesForEmail([]))
                    dispatch(clearSurfacesForEmail([]))
                    dispatch(onSetAllSurfaceClosed())
                    dispatch(onClearCheckedProperties())
                    dispatch(onClearCheckedSurfaces())
                    dispatch(clearSurfacesForEmail([]))
                    dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
                    dispatch(onSetPropertiesMessageBody(''))
                    dispatch(onClearActivitiesPropertiesAndSurfaces())
                    //
                    dispatch(onSetLocalSortModel({sort_model: [], gridType: currentGrid}))
                    dispatch(onFilterPropertiesGrid({gridFilters: [], gridType: currentGrid}))
                    dispatch(onSetProperties({
                        properties: currentGrid === 'Properties' ? defaultPropertiesGridColumns : defaultSurfacesGridColumns,
                        gridType: currentGrid
                    }))
                    dispatch(onChangeModalActivityFormField({value: '', inputName: 'Type'}))
                    dispatch(onChangeModalActivityFormField({value: '', inputName: 'Kind'}))
                    dispatch(onChangeModalActivityFormField({value: '', inputName: 'Description'}))
                    dispatch(onChangeModalActivityFormField({value: '', inputName: 'Remark'}))
                })
        } else {
            dispatch(onSetIsDescriptionFieldError(true))
            dispatch(onSetCurrentModalTab('General'))
            setIsLoading(false)
        }
    }

    const onCloseModal = () => {
        dispatch(onSetIsActivityModalOpened(false))
        dispatch(onSetIsDescriptionFieldError(false))
        dispatch(clearPropertiesForEmail([]))
        dispatch(clearSurfacesForEmail([]))
        dispatch(onSetAllSurfaceClosed())
        dispatch(onClearCheckedProperties())
        dispatch(onClearCheckedSurfaces())
        dispatch(clearSurfacesForEmail([]))
        dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
        dispatch(onSetPropertiesMessageBody(''))
        dispatch(onClearActivitiesPropertiesAndSurfaces())
        //
        // dispatch(onSetLocalSortModel({sort_model: [], gridType: currentGrid}))
        // dispatch(onFilterPropertiesGrid({gridFilters: [], gridType: currentGrid}))
        // dispatch(onSetProperties({
        //     properties: currentGrid === 'Properties' ? defaultPropertiesGridColumns : defaultSurfacesGridColumns,
        //     gridType: currentGrid
        // }))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Type'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Kind'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Description'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Remark'}))
    }

    useEffect(() => {
        if (isCreateActivityModalOpened) {
            let parts = reqGridRowData.EMAIL_LIST.split(':');
            let textBeforeColon = parts[1];
            dispatch(GetCompanyByReqRefThunk(gridRowId))
                .then((res) => {
                    dispatch(onSetActivitiesOrganizationId(res.payload[0].REF))
                    dispatch(GetActivitiesContactsThunk(res.payload[0].REF))
                        .then((res) => {
                            const contact = res.payload.find((c: any) => c.PE_EMAIL.trim() === textBeforeColon.trim())
                            dispatch(onChangeModalActivityFormField({
                                value: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME} ${contact.PE_EMAIL !== null ? `| ${contact.PE_EMAIL}` : ''} ${contact.TEL_NUMBER !== null ? `| Phone: ${contact.TEL_NUMBER}` : ''}`,
                                inputName: 'Contacts'
                            }))
                            dispatch(onSetCurrentContactRef(contact.PE_REF))
                            dispatch(onSetActivitiesContactId(contact.PE_REF))
                        })
                })
        }
    }, [isCreateActivityModalOpened, dispatch, gridRowId, reqGridRowData.EMAIL_LIST])


    const onOpenActivityModal = () => {
        //apply sorting for the grid, to show properties for the las 6 month
        // dispatch(onSetLocalSortModel({sort_model: [], gridType: currentGrid}))
        // if (currentGrid === 'Properties') {
        //     dispatch(onSetLocalSortModel({sort_model: [{field: "DATE_OFFERED", sort: "desc"}], gridType: currentGrid}))
        // }
        dispatch(onSetCurrentModalTab('General'))
        //
        dispatch(clearPropertiesForEmail([]))
        dispatch(onClearActivitiesPropertiesAndSurfaces())
        dispatch(clearSurfacesForEmail([]))
        dispatch(onSetAllSurfaceClosed())
        dispatch(onClearCheckedProperties())
        dispatch(onClearCheckedSurfaces())
        dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
        dispatch(onSetPropertiesMessageBody(''))
        dispatch(onSetIsActivityModalOpened(true))
        let agentEmail = reqGridRowData.EMAIL_LIST.split(':');
        let textAfterColon = agentEmail[1];
        dispatch(GetCompanyByReqRefThunk(gridRowId))
            .then((res) => {
                dispatch(onSetActivitiesOrganizationId(res.payload[0].REF))
                dispatch(GetActivitiesContactsThunk(res.payload[0].REF))
                    .then((res) => {
                        const contact = res.payload.find((c: any) => c.PE_EMAIL.trim() === textAfterColon.trim())
                        dispatch(onChangeModalActivityFormField({
                            value: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME} ${contact.PE_EMAIL !== null ? `| ${contact.PE_EMAIL}` : ''} ${contact.TEL_NUMBER !== null ? `| Phone: ${contact.TEL_NUMBER}` : ''}`,
                            inputName: 'Contacts'
                        }))
                        dispatch(onSetCurrentContactRef(contact.PE_REF))
                        dispatch(onSetActivitiesContactId(contact.PE_REF))
                    })
            })
    }

    const onDeleteActivitySurfaces = (surfaceId: number, req_id: number) => {
        dispatch(deleteSurfaceForEmail(surfaceId))
        dispatch(onDeleteCheckedSurface({surface_id: surfaceId, req_id: req_id, isSoloReq: false}))
    }

    const getRowId = useCallback((e: any) => e.PROP_ID || e.id, [])
    const ROW_CHUNK_SIZE = 50;
    const [loadedRows, setLoadedRows] = useState<any>([]);
    const [currentChunk, setCurrentChunk] = useState(1);

    const propertiesDataRowsPaginated = useMemo(() => {
        return rows.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    }, [rows, page, rowsPerPage]);

    // Compute the rows based on the current chunk
    const paginatedData = useMemo(() => {
        const end = currentChunk * (ROW_CHUNK_SIZE + 1);
        return propertiesDataRowsPaginated.slice(0, end);
    }, [propertiesDataRowsPaginated, currentChunk]);


    useEffect(() => {
        // Check if the data itself has changed, not just the length
        const isDataDifferent = JSON.stringify(paginatedData) !== JSON.stringify(loadedRows);

        if (isDataDifferent) {
            setLoadedRows(paginatedData);
        }
        // eslint-disable-next-line
    }, [paginatedData]);

    // eslint-disable-next-line
    const handleRowsScrollEnd = useCallback(

        throttle(() => {
            const gridContainer = document.querySelector('.MuiDataGrid-virtualScroller');
            if (gridContainer) {
                const { scrollTop, scrollHeight, clientHeight } = gridContainer;

                // Ensure this logic runs only when really needed
                if (scrollTop + clientHeight >= scrollHeight - 50 && currentChunk * ROW_CHUNK_SIZE < propertiesDataRowsPaginated.length) {
                    setCurrentChunk(prev => prev + 1);
                }
            }
        }, 500), // Increased throttle time to avoid excessive triggers
        [currentChunk, propertiesDataRowsPaginated, ROW_CHUNK_SIZE]
    );

    useEffect(() => {
        const gridContainer = document.querySelector('.MuiDataGrid-virtualScroller');
        if (gridContainer) {
            gridContainer.addEventListener('scroll', handleRowsScrollEnd);
        }
        return () => {
            if (gridContainer) {
                gridContainer.removeEventListener('scroll', handleRowsScrollEnd);
            }
        };
    }, [handleRowsScrollEnd]);


    return (
        <div style={{
            padding: !hiddenModal ? '0px 20px 20px 20px' : '0 0 20px 20px',
        }}>
            <div>
                <div style={{
                    padding: '14px 0px 14px 24px',
                    height: '60px'
                }}>
                    <PageTitle
                        isBackButton={true}
                        backLinkSecondText={`${gridRowId} ${gridRowTitle ? gridRowTitle : ''} 
                        ${defaultContact[0]?.PE_TEL_NUMBER && defaultContact[0]?.PE_TEL_NUMBER !== null ? `| Telephone: ${defaultContact[0]?.PE_TEL_NUMBER}` : ''}
                        ${defaultContact[0]?.PE_TEL2_NUMBER && defaultContact[0]?.PE_TEL2_NUMBER !== null ? `| Telephone 2: ${defaultContact[0]?.PE_TEL2_NUMBER}` : ''}
                        ${defaultContact[0]?.PE_MOBILE_NUMBER && defaultContact[0]?.PE_MOBILE_NUMBER !== null ? `| Mobile: ${defaultContact[0]?.PE_MOBILE_NUMBER}` : ''}
                        
                        `}
                    />
                </div>
            </div>
            <div className={s.contentInner}>
                <div
                    className={s.tableInner}
                >
                    <div style={{
                        paddingLeft: '6px'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: currentScreenWidth!! < 870 ? 'column' : 'row',
                            justifyContent: 'space-between',
                            alignItems: currentScreenWidth!! < 870 ? 'flex-start' : 'center'
                        }}>

                            <div className={s.tabButtons}>
                                {
                                    reqGridDomain !== 'RR' &&
                                    <button
                                        className={currentGrid === 'Properties' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}
                                        onClick={() => onChangeCurrentGridType('Properties')}
                                    >
                                        Properties
                                    </button>
                                }

                                {
                                    reqGridDomain !== 'CD' && reqGridDomain !== 'RS'
                                    &&
                                    <button
                                        className={currentGrid === 'Surfaces' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}
                                        onClick={() => onChangeCurrentGridType('Surfaces')}
                                    >
                                        Surfaces
                                    </button>
                                }

                            </div>

                            {
                                currentScreenWidth! > 768
                                &&
                                <div style={{
                                    display: 'flex'
                                }}>

                                    <PropertiesHeaderButtons
                                        onSetFiltersShown={handleFiltersShownClick}
                                        onResetConfigs={onResetConfigs}
                                        onSaveGridConfigs={onSaveGridConfigs}
                                        isDisabledSaveBtn={isDisabledSaveBtn}
                                        gridType={currentGrid}
                                        resetFilters={() => {
                                        }}
                                        disabledBtns={is404Error}
                                    />
                                    <button className={s.addActivityBtn} onClick={onOpenActivityModal} style={{
                                        marginLeft: '8px',
                                        minWidth: '121px'
                                    }} disabled={is404Error}>
                                        <img
                                            alt={'plus'}
                                            src={plusImg} style={{
                                            marginRight: '8px'
                                        }}/> Activity
                                    </button>
                                </div>
                            }

                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                            <PropertiesSearchInput
                                searchTerm={searchTerm}
                                setSearchTerm={(searchTerm: string) => setSearchTerm(searchTerm)}
                                handleSearchInputChange={handleSearchInputChange}
                            />
                            <div style={{
                                display: 'flex'
                            }}>
                                {
                                    currentScreenWidth! <= 768
                                    &&
                                    <>
                                        <button className={s.addActivityBtn} onClick={onOpenActivityModal} style={{
                                            marginLeft: '8px',
                                            minWidth: '121px',
                                            height: '40px'
                                        }}>
                                            <img
                                                src={plusImg} alt={'plus'} style={{
                                                marginRight: '8px'
                                            }}/> Activity
                                        </button>
                                        <MobileFiltersButton/>
                                    </>
                                }
                            </div>
                        </div>
                        {
                            currentScreenWidth! > 768
                            &&
                            <PropertiesFilterButtons
                                shownColumns={shownColumns}
                                onResetFilters={onResetFilters}
                                onResetSorting={onResetSorting}
                                gridType={currentGrid}
                            />
                        }
                        {
                            currentScreenWidth! > 768
                            &&
                            <div style={{
                                position: 'relative',
                                width: '100%',
                            }}>
                                {shownColumns.map((c: any) => {
                                    return (
                                        <PropertyFilterModal
                                            gridType={currentGrid}
                                            textValue={c.field}
                                        />
                                    )
                                })}
                            </div>
                        }
                    </div>
                    <Box sx={{position: 'relative', width: '100%'}}>
                        <>
                            <PropertiesViewOptions
                                checked={checkedViewOptions}
                                filtersShown={filtersShown}
                                setChecked={(checkedArray: string[]) => setCheckedViewOptions(checkedArray)}
                                setFilterShown={(isShown: boolean) => setFiltersShown(isShown)}
                                gridType={currentGrid}
                            />
                            {
                                is404Error
                                    ?
                                    <div style={{
                                        height: gridContentHeight + 115,
                                        width: '400px',
                                        margin: '0 auto',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}>
                                        <Empty/>
                                    </div>
                                    :
                                    gridColumns.length
                                        ?

                                        <DataGridPro
                                            rows={loadedRows}
                                            columns={shownColumns.filter((col: any) => col?.width !== 0).map((column: any, index: number) => ({
                                                ...column,
                                                renderCell: (params: any) =>
                                                    <CustomCell field={column.field}
                                                                value={params.value}
                                                                params={params}
                                                                properties={propertiesDataRowsPaginated}
                                                                key={index}
                                                                setHiddenModal={setHiddenModal}
                                                                setOpenedActivity={setOpenedActivity}
                                                                dataType={column.dataType}
                                                                contactLang={contactLang}

                                                    />,
                                                renderHeader: (params: any) => {
                                                    return (
                                                        <div style={{
                                                            display: 'flex',
                                                        }}>
                                                        <span
                                                            className={s.columnHeader}>
                                                            {params?.colDef?.headerName === 'ACTIVITY_EXISTED'
                                                                ? 'ACTIVITY'.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())
                                                                : params?.colDef?.headerName?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())}
                                                        </span>
                                                        </div>
                                                    );
                                                },
                                            }))}
                                            apiRef={apiRef}
                                            autoHeight={true}
                                            getRowId={getRowId}
                                            pageSize={rowsPerPage}
                                            sortingOrder={['desc', 'asc']}
                                            sortModel={Object.values(localSortModel)}
                                            onSortModelChange={handleSortModelChange}
                                            paginationMode="client"
                                            disableColumnSelector={true}
                                            onRowDoubleClick={onRowDoubleClick}
                                            onStateChange={handleStateChange}
                                            rowCount={propertiesDataRowsPaginated.length}
                                            onRowsScrollEnd={handleRowsScrollEnd}
                                            rowHeight={25}
                                            sx={{
                                                borderRight: 'none',
                                                borderLeft: 'none',
                                                borderBottom: 'none',
                                                borderRadius: '0px',
                                                // minHeight: '600px',
                                                // height: height > 950 ? `${gridContentHeight}px !important` : height > 900 ? `${gridContentHeight - 150}px !important` : `${gridContentHeight + 150}px !important`,
                                                "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                                    opacity: 0
                                                },
                                                "& .MuiDataGrid-cellContent": {
                                                    fontFamily: 'Inter, sans-serif',
                                                    fontWeight: 500,
                                                    fontSize: '14px',
                                                    lineHeight: '20px',
                                                    color: '#111827'
                                                },
                                                "& .MuiDataGrid-columnHeaderTitle": {
                                                    fontFamily: 'Inter, sans-serif',
                                                    fontWeight: 600,
                                                    color: '#6B7280',
                                                    fontSize: '12px',
                                                    lineHeight: '20px',
                                                },
                                                "& .MuiDataGrid-row:hover": {
                                                    backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                                },
                                                "& .MuiDataGrid-row.Mui-selected": {
                                                    backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                                },
                                                "& .MuiDataGrid-row.Mui-selected:hover": {
                                                    backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                                },
                                                "& .MuiDataGrid-cell:focus": {
                                                    outline: 'none'
                                                },
                                                "& .MuiDataGrid-toolbarContainer": {
                                                    color: '#454545',
                                                    position: 'absolute',
                                                    top: '-50px',
                                                    right: '0px'
                                                },
                                                "& .MuiTablePagination-displayedRows": {
                                                    margin: '0',
                                                    fontFamily: 'Inter, sans-serif !important',
                                                    fontWeight: 400,
                                                    color: '#6B7280',
                                                    fontSize: '12px',
                                                    lineHeight: '20px',
                                                },
                                                "& .MuiPagination-root": {
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    padding: '15px 0px'
                                                },
                                                "& .MuiButtonBase-root.Mui-selected": {
                                                    backgroundColor: '#eff1f3',
                                                    borderRadius: '0px'
                                                },
                                                "& .MuiButtonBase-root.MuiPaginationItem-root": {
                                                    width: '40px',
                                                    height: '40px',
                                                    margin: 0,
                                                    borderRight: '1px solid #D0D5DD',
                                                    borderLeft: '1px solid #D0D5DD',
                                                    borderTop: 'none',
                                                    borderBottom: 'none',
                                                    fontFamily: 'Inter, sans-serif !important',
                                                    fontWeight: 500,
                                                    color: '#1D2939',
                                                    fontSize: '14px',
                                                    lineHeight: '20px',
                                                    borderRadius: '0px'
                                                },
                                                "& .MuiPagination-ul": {
                                                    border: '1px solid #D0D5DD',
                                                    borderRadius: '8px'
                                                },
                                                "& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext": {
                                                    borderRadius: '0px',
                                                    border: 'none'
                                                },
                                                "& .MuiDataGrid-columnHeaders": {
                                                    backgroundColor: '#FCFDFE'
                                                },
                                                "& .MuiDivider-root": {
                                                    display: 'none',
                                                    height: '0',
                                                    width: '0',
                                                    opacity: '0 !important',
                                                    borderColor: '#fff '
                                                },
                                                "& .MuiBox-root": {
                                                    height: 'auto'
                                                },
                                                "& .MuiDataGrid-cell": {
                                                    borderBottom: 'none'
                                                },
                                                "& .MuiDataGrid-virtualScroller": {
                                                    overflowY: 'scroll !important',
                                                    overflowX: 'scroll',
                                                    height: `${gridContentHeight}px !important`,
                                                    minHeight: 'auto',
                                                },
                                                "& .MuiDataGrid-virtualScrollerContent": {},
                                            }}
                                            components={{
                                                Footer: () =>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: currentScreenWidth! > 768 ? 'space-between' : 'center',
                                                        alignItems: 'center',
                                                        width: '100%'
                                                    }}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: currentScreenWidth! > 768 ? 'space-between' : 'center',
                                                                alignItems: 'center',

                                                            }}>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: currentScreenWidth! > 768 ? 'space-between' : 'center',
                                                                    alignItems: 'center',
                                                                }}>
                                                                {
                                                                    currentScreenWidth! > 768
                                                                    &&
                                                                    <>
                                                                        <p
                                                                            className={s.footerText}
                                                                        >
                                                                            Rows per page:
                                                                        </p>
                                                                        <Select
                                                                            value={rowsPerPage}
                                                                            onChange={handleRowsPerPageChange}
                                                                            className={s.rowsPerPage}
                                                                        >
                                                                            {rowsPerPageOptions.map((option) => (
                                                                                <Select.Option key={option}
                                                                                               value={option}>
                                                                                    {option}
                                                                                </Select.Option>
                                                                            ))}
                                                                        </Select>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <Pagination
                                                            // count={Math.ceil(sortedRows.length / rowsPerPage)}
                                                            count={Math.ceil(rows.length / rowsPerPage)}
                                                            // count={Math.ceil(gridFilters.length ? (rows.length / rowsPerPage) : (defaultAuditGridColumns.length / rowsPerPage))}
                                                            page={page}
                                                            onChange={handlePageChange}
                                                            variant="outlined"
                                                            shape="rounded"
                                                        />
                                                        {
                                                            currentScreenWidth! > 768
                                                            &&
                                                            // <p className={s.footerText}>
                                                            //     Total rows: {sortedRows.length}
                                                            // </p>
                                                            <p className={s.footerText}>
                                                                Total{gridFilters.length ? '/Filtered' : ''} properties: {gridFilters.length ? defaultGridColumns.length : rows.length}{gridFilters.length ? `/${rows.length}` : ''}
                                                            </p>
                                                        }
                                                    </div>,
                                                ColumnMenu: (props) => {
                                                    return (
                                                        <GridColumnMenu
                                                            hideMenu={() => {
                                                            }}
                                                            currentColumn={
                                                                {
                                                                    field: props.currentColumn.field,
                                                                    filterable: true,
                                                                    hideable: false,
                                                                }
                                                            }
                                                            open={true}
                                                        />
                                                    )
                                                },
                                                Row: CustomGridRow,
                                            }}
                                            componentsProps={{
                                                row: {
                                                    setHiddenModal,
                                                    setOpenedActivity
                                                },
                                            }}
                                        />

                                        :
                                        <div style={{
                                            height: gridContentHeight + 115,
                                            width: '400px',
                                            margin: '0 auto',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center'
                                        }}>
                                            <div style={{
                                                height: '300px',
                                                width: '100%',
                                                textAlign: 'center'
                                            }}>
                                                <Lottie
                                                    // @ts-ignore
                                                    config={{animationData: animatedLoader, loop: true, height: '60px !important'
                                                    }}
                                                />
                                            </div>

                                            <p style={{
                                                textAlign: 'center'
                                            }}>{gridLoaderMessage}</p>
                                        </div>
                            }
                        </>
                    </Box>
                </div>
                <PropertiesOfferModal
                    height={height}
                    hiddenModal={hiddenModal}
                    gridContentHeight={gridContentHeight}
                    onHideModal={onHideModal}
                    divRef={divRef}
                    gridType={'Properties'}
                    contactLang={contactLang}
                    rowReqId={gridRowId}
                />
                <PropertiesActivityModal
                    openedActivity={openedActivity}
                    height={height}
                    gridContentHeight={gridContentHeight}
                    currentPropId={currentPropId}
                    onCloseActivityModal={onCloseActivityModal}
                />
                {
                    isCreateActivityModalOpened
                    &&
                    <div style={{marginLeft: '30px'}}>
                        <CommonActivityModal modalTitle={`Activity`}
                                             gridType={"Activity"} height={gridContentHeight + 145}
                                             onCloseModal={onCloseModal}
                                             width={'650px'}
                        >
                            <div>
                                <div className={s.tabButtons__activitiesModal}>
                                    <button
                                        onClick={() => dispatch(onSetCurrentModalTab('General'))}
                                        className={currentTab === 'General' ? `${s.tabButtons__activitiesModalButton} ${s.tabButtons__activitiesModalButton_active}` : s.tabButtons__activitiesModalButton}
                                    >
                                        General
                                    </button>

                                    <button
                                        onClick={() => dispatch(onSetCurrentModalTab('Existing Links'))}
                                        className={currentTab === 'Existing Links' ? `${s.tabButtons__activitiesModalButton} ${s.tabButtons__activitiesModalButton_active}` : s.tabButtons__activitiesModalButton}
                                    >
                                        Existing links
                                    </button>
                                    {/*{*/}
                                    {/*    currentRowValues.WE_TYPE_POSITION !== null*/}
                                    {/*    &&*/}
                                    {/*    <p className={s.badge}>{currentRowValues.WE_TYPE_POSITION}</p>*/}
                                    {/*}*/}

                                </div>
                                {
                                    currentTab === 'General'
                                        ?
                                        <ActivitiesGridForm withButtons={false} maxWidthInputs={true} editMode={false}
                                                            disabledInputs={true}/>
                                        :
                                        activitiesProperties.length || activitiesCheckedSurfaces.length
                                            ?
                                            <div>
                                                <>
                                                    {activitiesProperties.length
                                                        ?
                                                        <div className={propertiesStyles.messageInner__properties}>
                                                            <p>Properties:</p>
                                                            <div
                                                                className={propertiesStyles.messageInner__propertiesInner}>
                                                                {activitiesProperties.map((p: any) => {
                                                                    return (
                                                                        <div
                                                                            className={propertiesStyles.messageInner__property}
                                                                            key={p.PROP_ID}>


                                                                            <span>{p.PROP_ID} {p.ADDR_STREET ? '-' : null} {p.ADDR_STREET} {p.ADDR_ZIP ? ',' : null} {p.ADDR_ZIP} {p.ADDR_CITY ? ',' : null} {p.ADDR_CITY}</span>
                                                                            <button
                                                                                onClick={() => dispatch(onDeleteCheckedProperty({
                                                                                    prop_id: p.PROP_ID!,
                                                                                    withoutReq: false,
                                                                                    req_id: gridRowId,
                                                                                    isSoloReq: true
                                                                                }))}
                                                                                type={'button'}
                                                                                // disabled={currentRowValues?.ACT_TYPE === 'Documentation'}
                                                                                // style={{
                                                                                //     cursor: currentRowValues?.ACT_TYPE === 'Documentation' ? 'not-allowed' : 'pointer'
                                                                                // }}
                                                                            >
                                                                                <svg width="12" height="12"
                                                                                     viewBox="0 0 12 12"
                                                                                     fill="none"
                                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                                    <g clipPath="url(#clip0_1387_36685)">
                                                                                        <path
                                                                                            d="M6.46967 5.99974L9.233 3.2364C9.28761 3.17264 9.31614 3.09061 9.3129 3.00672C9.30966 2.92283 9.27488 2.84325 9.21552 2.78388C9.15616 2.72452 9.07658 2.68974 8.99268 2.6865C8.90879 2.68326 8.82677 2.71179 8.763 2.7664L5.99967 5.52974L3.23633 2.76307C3.17356 2.7003 3.08843 2.66504 2.99967 2.66504C2.9109 2.66504 2.82577 2.7003 2.763 2.76307C2.70023 2.82584 2.66497 2.91097 2.66497 2.99974C2.66497 3.0885 2.70023 3.17364 2.763 3.2364L5.52967 5.99974L2.763 8.76307C2.7281 8.79295 2.69976 8.82972 2.67976 8.87108C2.65975 8.91243 2.64851 8.95748 2.64673 9.00338C2.64496 9.04929 2.65269 9.09507 2.66945 9.13784C2.68621 9.18062 2.71163 9.21947 2.74412 9.25195C2.7766 9.28444 2.81545 9.30986 2.85822 9.32661C2.901 9.34337 2.94678 9.35111 2.99268 9.34933C3.03859 9.34756 3.08363 9.33632 3.12499 9.31631C3.16634 9.2963 3.20312 9.26796 3.233 9.23307L5.99967 6.46974L8.763 9.23307C8.82677 9.28768 8.90879 9.31621 8.99268 9.31297C9.07658 9.30973 9.15616 9.27496 9.21552 9.21559C9.27488 9.15623 9.30966 9.07665 9.3129 8.99275C9.31614 8.90886 9.28761 8.82684 9.233 8.76307L6.46967 5.99974Z"
                                                                                            fill={'rgba(0, 0, 0, 0.54)'}/>
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_1387_36685">
                                                                                            <rect width="12" height="12"
                                                                                                  fill="white"/>
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>

                                                                            </button>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        :
                                                        null}
                                                </>
                                                <>
                                                    {activitiesCheckedSurfaces.length
                                                        ?
                                                        <div className={propertiesStyles.messageInner__properties}>
                                                            <p>Surfaces:</p>
                                                            <div
                                                                className={propertiesStyles.messageInner__propertiesInner}>
                                                                {activitiesCheckedSurfaces.map((p: any) => {
                                                                    return (
                                                                        <div
                                                                            className={propertiesStyles.messageInner__property}
                                                                            key={currentGrid === 'Surfaces' ? p.SURF_REF : p?.SURF_REF}>

                                                                            <span>{p?.SURF_REF} {p.ADDR_STREET_ADDRESS ? '-' : null} {p.ADDR_STREET_ADDRESS} {p.ADDR_CITY ? ',' : null} {p.ADDR_CITY}</span>
                                                                            <button
                                                                                // onClick={() => onDeleteActivitySurfaces(p.surfaceId)}
                                                                                onClick={() => onDeleteActivitySurfaces(p.SURF_REF, gridRowId)}
                                                                                type={'button'}
                                                                                // disabled={currentRowValues?.ACT_TYPE === 'Documentation'}
                                                                                // style={{
                                                                                //     cursor: currentRowValues?.ACT_TYPE === 'Documentation' ? 'not-allowed' : 'pointer'
                                                                                // }}
                                                                            >
                                                                                <svg width="12" height="12"
                                                                                     viewBox="0 0 12 12"
                                                                                     fill="none"
                                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                                    <g clipPath="url(#clip0_1387_36685)">
                                                                                        <path
                                                                                            d="M6.46967 5.99974L9.233 3.2364C9.28761 3.17264 9.31614 3.09061 9.3129 3.00672C9.30966 2.92283 9.27488 2.84325 9.21552 2.78388C9.15616 2.72452 9.07658 2.68974 8.99268 2.6865C8.90879 2.68326 8.82677 2.71179 8.763 2.7664L5.99967 5.52974L3.23633 2.76307C3.17356 2.7003 3.08843 2.66504 2.99967 2.66504C2.9109 2.66504 2.82577 2.7003 2.763 2.76307C2.70023 2.82584 2.66497 2.91097 2.66497 2.99974C2.66497 3.0885 2.70023 3.17364 2.763 3.2364L5.52967 5.99974L2.763 8.76307C2.7281 8.79295 2.69976 8.82972 2.67976 8.87108C2.65975 8.91243 2.64851 8.95748 2.64673 9.00338C2.64496 9.04929 2.65269 9.09507 2.66945 9.13784C2.68621 9.18062 2.71163 9.21947 2.74412 9.25195C2.7766 9.28444 2.81545 9.30986 2.85822 9.32661C2.901 9.34337 2.94678 9.35111 2.99268 9.34933C3.03859 9.34756 3.08363 9.33632 3.12499 9.31631C3.16634 9.2963 3.20312 9.26796 3.233 9.23307L5.99967 6.46974L8.763 9.23307C8.82677 9.28768 8.90879 9.31621 8.99268 9.31297C9.07658 9.30973 9.15616 9.27496 9.21552 9.21559C9.27488 9.15623 9.30966 9.07665 9.3129 8.99275C9.31614 8.90886 9.28761 8.82684 9.233 8.76307L6.46967 5.99974Z"
                                                                                            fill={'rgba(0, 0, 0, 0.54)'}/>
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_1387_36685">
                                                                                            <rect width="12" height="12"
                                                                                                  fill="white"/>
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>

                                                                            </button>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        :
                                                        null}
                                                </>
                                            </div>
                                            :
                                            <div className={s.reqGridText}>
                                                Add the Properties / Surfaces to the activity.
                                            </div>
                                }
                            </div>

                            {

                                modalFormFields.type === ''
                                || modalFormFields.type === null
                                || modalFormFields.kind === ''
                                || modalFormFields.kind === null
                                    ?
                                    <Tooltip
                                        text={
                                            modalFormFields.type === ''
                                            || modalFormFields.type === null
                                            || modalFormFields.kind === ''
                                            || modalFormFields.kind === null
                                                ? `Please select the domain , activity type and activity kind in order to save changes.`
                                                : `Please change your activity kind to the one that is correct with the system kinds`}
                                        classname={'addressButtonsTooltip'}>
                                        <Button
                                            type='primary'
                                            htmlType='submit'
                                            style={{
                                                height: '44px',
                                                backgroundColor: 'rgba(7, 7, 250, 0.26)',
                                                color: '#fff',
                                                width: '100%',
                                                border: '1px solid transparent',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginTop: '10px'

                                            }}
                                            onClick={onCreateNewActivity}
                                            disabled={
                                                modalFormFields.type === ''
                                                || modalFormFields.type === null
                                                || modalFormFields.kind === ''
                                                || modalFormFields.kind === null
                                            }
                                            loading={loading}
                                        >
                                            Add
                                        </Button>
                                    </Tooltip>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        style={{
                                            height: '40px',
                                            backgroundColor: '#0707FA',
                                            border: '1px solid #0707FA',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: '10px'

                                        }}
                                        onClick={onCreateNewActivity}
                                        onDoubleClick={() => setIsLoading(true)}
                                        loading={loading}
                                    >
                                        Add
                                    </Button>

                            }

                        </CommonActivityModal>
                    </div>
                }
                {
                    isCreateModalOpened
                    &&
                    <CreateContactModal reqId={gridRowId}/>
                }
                {
                    isMobileMenuOpened
                    &&
                    <MobileFiltersMenu
                        checkedViewOptions={checkedViewOptions}
                        filtersShown={true}
                        setCheckedViewOptions={setCheckedViewOptions}
                        setFiltersShown={setFiltersShown}
                        gridType={'Properties'}
                        shownColumns={shownColumns}
                        hiddenColumns={hiddenColumns}
                        userData={userData}
                        today={today}
                        threeMonthsAgo={threeMonthsAgo}
                        gridColumns={currentGrid === 'Properties' ? propertiesGridColumns : surfacesGridColumns}
                        setSearchTerm={setSearchTerm}
                        setLocalSortModel={setLocalSortModel}
                        localSortModel={localSortModel}
                        handleFiltersShownClick={handleFiltersShownClick}
                        resetFilters={onResetFilters}
                    />
                }
            </div>

        </div>

    );
};

const CustomCell = React.memo(({
                                   field,
                                   value,
                                   params,
                                   properties,
                                   setHiddenModal,
                                   setOpenedActivity,
                                   dataType,
                                   contactLang
                               }: any) => {
    const dispatch = useAppDispatch()
    const defaultContact = useAppSelector(selectDefaultContact)
    const gridRowId = gridDataHelper.getRowId()
    const currentProp = properties.find((p: any) => p.PROP_ID === Number(value))
    const checkedProperties = useAppSelector(selectPropertiesGridCheckedProperties)
    const [disabledCheckbox, setDisabledCheckbox] = useState(false)
    const currentGrid = useAppSelector(selectAllPropertiesCurrentGrid)
    const checkedSurfaces = useAppSelector(selectCheckedSurfaces)
    const checkedPropertiesIds = useAppSelector(selectCurrentActivitiesCheckedPropertiesIds)
    const checkedSurfacesIds = useAppSelector(selectActivitiesCheckedSurfacesIds)
    const isCreateActivityModalOpened = useAppSelector(selectActivitiesModalOpened)
    const reqGridDomain = gridDataHelper.getReqGridDomain()

    const onGetPropertiesSurface = (value: string) => {
        if (!currentProp.surfaces.length) {
            dispatch(GetPropertiesSurface({
                lang: defaultContact[0]?.PE_LANG_INDEX === 10 ? 'CZ' : 'EN',
                prop_id: value,
                req_id: gridRowId
            }))
        } else {
            dispatch(onSetSurfaceIsOpened({isOpened: !currentProp.isOpened, propId: value}))
        }
    }
    if (field === 'ACTIVITY_EXISTED') {
        return <div style={{display: 'flex', alignItems: 'center'}}>{value === 0 ? <>
            <RedDot style={{marginRight: '6px'}}/>No</> : value === 1 ? <><GreenDot
            style={{marginRight: '6px'}}/>Yes</> : ''}</div>;
    }
    if (field === 'HAS_BALCONY' || field === 'HAS_PARKING' || field === 'HAS_LIFT' || field === 'FREE_LAND') {
        return <div style={{display: 'flex', alignItems: 'center'}}>{value === 'No' ? <>
            <RedDot style={{marginRight: '6px'}}/>No</> : value === 'Yes' ? <><GreenDot
            style={{marginRight: '6px'}}/>Yes</> : ''}</div>;
    }
    if (field === 'SURF_REF' || field === 'PROP_ID') {

        const handleCheckProperties = (value: any) => {
            dispatch(onSetCurrentModalTab('Existing Links'))
            dispatch(onClearPropertyActivities())
            setHiddenModal(false)
            setOpenedActivity(false)
            setDisabledCheckbox(true)
            if (value.target.checked === true) {
                dispatch(onAddCheckedProperties({property: value.target.name, isChecked: value.target.checked}))
                // if (!isCreateActivityModalOpened) {
                dispatch(GetPropertiesEmailLinkText({
                    prop_id: Number(value.target.name),
                    req_id: gridRowId,
                    propertyType: 'Property',
                    lang: contactLang,
                    gridType: 'Properties'
                }))
                if (!isCreateActivityModalOpened) {
                    dispatch(GetPropertiesEmailPhotosForLinks({
                        p_fk_type: 'Property',
                        p_row_fk: Number(value.target.name),
                        p_row_req: gridRowId,
                    }))
                }


                dispatch(onCheckActivitiesPropertiesIds({property: value.target.name, isChecked: value.target.checked}))
                dispatch(onCheckActivitiesProperties({
                    property: params.row,
                    isChecked: value.target.checked,
                    gridType: 'Properties'
                }))
            } else {
                dispatch(onAddCheckedProperties({property: value.target.name, isChecked: value.target.checked}))
                dispatch(deletePropertyEmail(Number(value.target.name)))
                dispatch(onCheckActivitiesPropertiesIds({property: value.target.name, isChecked: value.target.checked}))
                dispatch(onCheckActivitiesProperties({
                    property: params.row,
                    isChecked: value.target.checked,
                    gridType: 'Properties'
                }))
            }
            setTimeout(() => {
                setDisabledCheckbox(false)
            }, 50)
        }

        const handleCheckSurfaces = (value: any) => {
            dispatch(onSetCurrentModalTab('Existing Links'))
            dispatch(onClearPropertyActivities())
            setHiddenModal(false)
            setOpenedActivity(false)
            setDisabledCheckbox(true)
            if (value.target.checked === true) {
                dispatch(onAddCheckedSurfaces({surface: Number(params.row.SURF_REF), isChecked: value.target.checked}))
                // if (!isCreateActivityModalOpened) {
                dispatch(GetPropertiesEmailLinkText({
                    prop_id: params.row.PROP_REF,
                    req_id: gridRowId,
                    propertyType: 'Surface',
                    surfaceId: params.row.SURF_REF,
                    lang: contactLang,
                    gridType: 'Surfaces',
                    currentDomain: reqGridDomain
                }))
                if (!isCreateActivityModalOpened) {
                    dispatch(GetPropertiesEmailPhotosForLinks({
                        p_fk_type: 'Surface',
                        p_row_fk: Number(params.row.PROP_REF),
                        p_row_req: gridRowId,
                        prop_id: params.row.PROP_REF,
                        surfaceId: params.row.SURF_REF,
                    }))
                }
                dispatch(onCheckSurfacesIds({
                    surfaceId: Number(params.row.SURF_REF),
                    isChecked: value.target.checked
                }))
                dispatch(onCheckSurfaces({surface: params.row, isChecked: value.target.checked}))
            } else {
                dispatch(onAddCheckedSurfaces({surface: Number(params.row.SURF_REF), isChecked: value.target.checked}))
                dispatch(deleteSurfaceForEmail(Number(params.row.SURF_REF)))
                dispatch(onCheckSurfacesIds({
                    surfaceId: Number(params.row.SURF_REF),
                    isChecked: value.target.checked
                }))
                dispatch(onCheckSurfaces({surface: params.row, isChecked: value.target.checked}))
            }
            setTimeout(() => {
                setDisabledCheckbox(false)
            }, 50)
        }

        return (
            <div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                }}>
                    <div>
                        <Checkbox
                            onChange={currentGrid === 'Properties' ? handleCheckProperties : handleCheckSurfaces}
                            name={value}
                            checked={currentGrid === 'Properties' ? checkedProperties.includes(value) && checkedPropertiesIds.includes(value) : checkedSurfaces.includes(value) && checkedSurfacesIds.includes(value)}
                            disabled={disabledCheckbox}
                            style={{
                                marginRight: '8px'
                            }}
                        />
                        <span>{value}</span>
                    </div>
                    {
                        params.row.withSurface
                        &&
                        <>
                            <button
                                onClick={() => onGetPropertiesSurface(value)}
                                className={s.surfaceBtn}
                            >
                                Surfaces
                                <img src={surfaceArrow} alt="arrow-down"/>
                            </button>
                        </>
                    }

                </div>
            </div>

        )
    } else {
        if (dataType === 'class java.math.BigDecimal' && field !== 'PROP_REF' && field !== 'SURF_REF' && field !== 'PROP_ID') {
            const europeanString = value !== null ? value?.toLocaleString('de-DE') : '';
            return <div>{europeanString}</div>;
        } else {
            return <div>{value}</div>;
        }

    }
});


const CustomGridRow = React.memo((props: any) => {
    const dispatch = useAppDispatch()
    const gridRowId = gridDataHelper.getRowId()
    const {row} = props;
    const checkedSurfaces = useAppSelector(selectCheckedSurfaces)
    const [disabledCheckbox, setDisabledCheckbox] = useState(false)
    const contactLang = useAppSelector(selectPropertyContactLang)
    const checkedSurfacesIds = useAppSelector(selectActivitiesCheckedSurfacesIds)
    const isCreateActivityModalOpened = useAppSelector(selectActivitiesModalOpened)
    const reqGridDomain = gridDataHelper.getReqGridDomain()
    const checkedProperties = useAppSelector(selectPropertiesGridCheckedProperties)


    const handleCheckSurfaces = (value: any) => {

        dispatch(onClearPropertyActivities())
        props.setHiddenModal(false)
        props.setOpenedActivity(false)
        dispatch(onSetCurrentModalTab('Existing Links'))
        setDisabledCheckbox(true)
        if (value.target.checked === true) {
            dispatch(onAddCheckedSurfaces({surface: Number(value.target.value), isChecked: value.target.checked}))
            // if (!isCreateActivityModalOpened) {
            dispatch(GetPropertiesEmailLinkText({
                prop_id: value.target.name,
                req_id: gridRowId,
                propertyType: 'Surface',
                surfaceId: value.target.value,
                lang: contactLang,
                gridType: 'Properties',
                currentDomain: reqGridDomain
            }))

            if (!isCreateActivityModalOpened) {
                dispatch(GetPropertiesEmailPhotosForLinks({
                    p_fk_type: 'Surface',
                    p_row_fk: Number(value.target.value),
                    p_row_req: gridRowId,
                    prop_id: value.target.name,
                    gridType: 'Properties',
                    surfaceId: value.target.value,
                }))
            }

            dispatch(onCheckSurfacesIds({
                surfaceId: Number(value.target.value),
                isChecked: value.target.checked
            }))
            dispatch(onCheckSurfaces({surface: props.row.surfaces[0], isChecked: value.target.checked}))
            // dispatch(onCheckActivitiesPropertiesIds({property: value.target.name, isChecked: value.target.checked}))
            // dispatch(onCheckActivitiesProperties({property: props.row, isChecked: value.target.checked}))
        } else {
            dispatch(onAddCheckedSurfaces({surface: Number(value.target.value), isChecked: value.target.checked}))
            dispatch(deleteSurfaceForEmail(Number(value.target.value)))
            dispatch(onCheckSurfacesIds({
                surfaceId: Number(value.target.value),
                isChecked: value.target.checked
            }))
            dispatch(onCheckSurfaces({surface: props.row.surfaces[0], isChecked: value.target.checked}))
            // dispatch(onCheckActivitiesPropertiesIds({property: value.target.name, isChecked: value.target.checked}))
            // dispatch(onCheckActivitiesProperties({property: props.row, isChecked: value.target.checked}))
        }
        setTimeout(() => {
            setDisabledCheckbox(false)
        }, 50)
    }

    return (
        <div className={s.customRow}>
            <GridRow {...props} />
            {
                row.isOpened
                    ?
                    <div className={s.surfaceInner}>
                        {row.surfaces.map((surf: any, index: number) => {
                            return (
                                <div key={index} style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Checkbox
                                        onChange={handleCheckSurfaces}
                                        name={row.PROP_ID}
                                        value={surf.VALUE_01}
                                        checked={
                                            reqGridDomain === 'OR'
                                                ? checkedSurfaces.includes(Number(surf.VALUE_01)) && checkedProperties.includes(Number(row.PROP_ID))
                                                : checkedSurfaces.includes(Number(surf.VALUE_01)) && checkedSurfacesIds.includes(Number(surf.VALUE_01))}
                                        style={{
                                            marginRight: '8px'
                                        }}
                                        disabled={disabledCheckbox}
                                    />
                                    <span
                                        className={s.surfaceList}>REF {surf?.VALUE_01} | {surf?.LABEL_02} {surf?.VALUE_02} | {surf?.LABEL_03} {surf?.VALUE_03} | {surf?.LABEL_04} {surf?.VALUE_04} | {surf?.LABEL_05} {surf?.VALUE_05} | {surf?.LABEL_06} {surf?.VALUE_06}</span>
                                </div>
                            )
                        })}

                    </div>
                    :
                    null
            }
        </div>
    );
});


export default memo(Properties);


