import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
import {gridDataHelper} from "../../helpers/localStorageHelper";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectUserData} from "../../store/userReducer";
import {ReactComponent as AddressWarningIcon} from './../../img/icons/problemWithAddressIcon.svg';

import {
    GetAllPropertiesWithREQGridThunk,
    GetPropertiesGridContacts,
    GetPropertyActivities,
    GetPropertyAgentAddress,
    onChangeColumnsGridPosition,
    onChangeOfferMessageGreeting,
    onClearPropertyActivities,
    onResetFiltersButtonsSelected,
    onSetLocalSortModel,
    onSetOfferToValuesInitial,
    onSetPropertiesGridLoaderMessage,
    onSetPropertiesOfferFormError,
    onSetProperties,
    selectAllPropertiesGridCheckedProperties,
    selectCheckedSurfaces,
    selectContactsForEmail,
    selectDefaultContact,
    selectEmailGreeting,
    selectOfferMessageGreeting,
    selectPropertiesGridCheckedProperties,
    selectPropertiesGridLoaderMessage,
    selectAllPropertiesGridShownColumns,
    setHiddenColumns,
    setShownColumns,
    selectAllPropertiesCurrentGrid,
    selectAllPropertiesGridWithReqGridReqId,
    onAddAllPropertiesCheckedProperties,
    deleteAllPropertiesPropertyEmail,
    selectAllPropertiesGridHiddenColumns,
    onSetSortingString,
    setAllPropertiesGridColumns,
    ResetAllGridConfigsThunk,
    onChangeCurrentGridTab,
    GetAllPropertiesWithoutREQGridThunk,
    clearPropertiesForEmail,
    onClearCheckedProperties,
    setAllPropertiesGridPropertiesForEmail,
    selectIsAllPropertiesUpcomingLoading,
    selectIsAllPropertiesReqLoading,
    selectAllPropertiesColumnsWithReq,
    selectAllPropertiesColumnsUpcoming,
    selectAllPropertiesWithReq,
    selectAllPropertiesWithUpcoming,
    onSetAllPropertiesReqLoading,
    onSetAllPropertiesUpcomingLoading,
    selectAllPropertiesColumnsForAddressProperties,
    GetAllPropertiesAddressPropertiesGridThunk,
    selectIsAllPropertiesAddressPropertiesLoading,
    selectAllPropertiesAddressProperties,
    selectIsAllPropertiesAddressModalOpened,
    onChangeIsAddressModalOpened,
    onSetCurrentAddressPropertyData,
    GetPropertiesCountries,
    onResetPropertiesGridColumns,
    clearSurfacesForEmail,
    onSetAllSurfaceClosed,
    onClearCheckedSurfaces,
    onFilterPropertiesGrid,
    onSetIsCheckReqMatchFirstTime,
    GetPropertiesContactLang,
    selectPropertyContactLang,
    onChangeColumnsGridWidth,
    selectIsBrokerageDataUpdating,
    onSetIsBrokerageDataUpdating,
    selectUpcomingPropertiesGridLocalSortModel,
    onAddOwnerContact,
    onDeleteOwnerContact,
    GetBrokerageIsReqExisted,
    selectAllPropertiesGridLocalSortModel,
    selectIsDataUpdatedWithFilter,
    selectContactPropertiesWasNotFound,
    onSetContactPropertiesWasNotFound,
    onSetContactPropertiesSearchedAddress,
    selectContactPropertiesIsSearchInputShown,
    onSetContactPropertiesIsSearchInputShown,
    onSetFiltersButtonsSelected,
    selectContactPropertiesSearchedAddress,
    onChangeColumnForFilterValue,
    onSetPropertiesMessageBody,
    onSetIsActivityModalOpened,
    onDeleteAllPropertiesCheckedPropertyFromOffer,
    onSetActivitiesContactRows,
    selectActivitiesCheckedContactRows,
    GetPropertiesEmailBody,
    setWithReqColumnsForFilters,
    onFilterPropertiesWithReqGrid,
    setWithoutReqColumnsForFilters,
    onFilterUpcomingPropertiesGrid,
    selectUpcomingPropertiesDefaultColumnValues,
    selectBrokeragePropertiesDefaultColumnValues,
    selectUpcomingPropertiesGridFiltersForArray,
    selectUpcomingPropertiesColumnsForFilters,
    selectPropertiesWithReqGridFiltersForArray,
    selectPropertiesWithReqColumnsForFilters,
    onSetUpcomingProperties,
    onSetPropertiesWithReq,
    selectPropertyActivities,
    onSetContactPropertiesLastSearchedAddress,
    onSetFiltersButtonsModalOpened,
    onSetPropContactsRemarkEditMode,
    onSetPropContactsCurrentRowPropId,
    onSetPropContactsLoadedRows,
    selectPropContactsLoadedRows,
    selectPropContactsCurrentWindowMode,
    selectPropContactsWindowModes, onChangeWindowModesMode, onSetCurrentWindow,

} from "../../store/propertiesReducer";
import {DataGridPro, GridColumnMenu, GridRow, useGridApiRef} from "@mui/x-data-grid-pro";
import {customSortFunction, numericComparator} from "../../helpers/sortingDataHelper";
import s from "../PropertiesPage/Properties.module.scss";
import PropertiesHeaderButtons from "../PropertiesCommonComponents/PropertiesHeaderButtons/PropertiesHeaderButtons";
import PropertiesSearchInput from "../PropertiesCommonComponents/PropertiesSearchInput/PropertiesSearchInput";
import PropertiesFilterButtons from "../PropertiesCommonComponents/PropertiesFilterButtons/PropertiesFilterButtons";
import PropertyFilterModal from "../PropertiesPage/PropertyFilterModal/PropertyFilterModal";
import Box from "@mui/material/Box";
import PropertiesViewOptions from "../PropertiesCommonComponents/PropertiesViewOptions/PropertiesViewOptions";
import {Lottie} from "@crello/react-lottie";
import animatedLoader from "../../img/Loader.json";
import PropertiesOfferModal from "../PropertiesPage/PropertiesOfferModal/PropertiesOfferModal";
import PropertiesActivityModal from "../PropertiesPage/PropertiesActivityModal/PropertiesActivityModal";
import {ReactComponent as RedDot} from "../../img/icons/redDot.svg";
import {ReactComponent as GreenDot} from "../../img/icons/greenDot.svg";
import {Button, Checkbox, Empty, Input, message, Select} from "antd";
import moment from "moment";
import {CreateAndUpdateActivityRequest, gridApi} from "../../app/api";
import AllPropertiesAddressForm from "../AllPropertiesAddressForm/AllPropertiesAddressForm";
import PropertiesMap from "../PropertiesMap/PropertiesMap";
import {DateRangePicker} from "rsuite";
import {addMonths, addDays, startOfWeek, endOfWeek, subDays, startOfMonth, endOfMonth} from 'date-fns';
import {useLocation, useNavigate} from "react-router";
import {onChangeOfferType, setBrokerageReqProp} from "../../store/brokerageReqsReducer";
import {
    GetMapPropertiesStatusesThunk,
} from "../../store/propertiesMap";
import {
    onSetBrokeragePropsCurrentStatus, onSetFilteredInputDynamicWidth, onSetLastUpcomingPropsCurrentStatus,
    selectUpcomingPropsCurrentStatus,
} from "../../store/parametersReducer";
import {getAbortController} from '../../helpers/AbortControllerManager';
import plusImg from "../../img/icons/plus.svg";
import {
    CreateNewActivityThunk,
    onChangeModalActivityFormField,
    onCheckActivitiesProperties,
    onClearActivitiesContacts, onClearActivitiesFormFields,
    onClearActivitiesPropertiesAndSurfaces, onClearPropContactsCheckedProperties,
    onDeleteCheckedProperty,
    onSetActivitiesContactId,
    onSetActivitiesOrganizationId,
    onSetCurrentContactRef,
    onSetCurrentModalTab,
    onSetIsDescriptionFieldError,
    onSetIsSavingButtonDisabled,
    selectActivitiesCheckedSurfacesIds,
    selectActivitiesKindNumberEntity,
    selectActivitiesModalFormFields,
    selectActivitiesTypeKind,
    selectActivitiesTypeNumberEntity,
    selectActivityModalCurrentTab,
    selectContactId,
    selectCurrentActivitiesCheckedProperties,
    selectOrganizationId
} from "../../store/activitesGrid";
import CommonActivityModal from "../common/CommonActivityModal/CommonActivityModal";
import ActivitiesGridForm from "../ActivitiesGridForm/ActivitiesGridForm";
import propertiesStyles from "../PropertiesPage/Properties.module.scss";
import Tooltip from "../Tooltip/Tooltip";
import {
    GetCompanyByRefThunk,
} from "../../store/companies";
import {sessionStorageGridFilters} from "../../helpers/sessionStorageHelper";
import {gridFiltering} from "../../customFunctions/gridFiltering";
import {Pagination} from "@mui/material";
import {selectCurrentScreenWidth} from "../../store/utilityReducer";
import {throttle} from "lodash";


const AllProperties = () => {
    return (
        <PageWithSidebar>
            <AllPropertiesPageComponent/>
        </PageWithSidebar>
    )
}

const AllPropertiesPageComponent = () => {
    const divRef = useRef(null);
    const gridRowId = gridDataHelper.getRowId()
    // const gridRowTitle = gridDataHelper.getRowTitle()
    const userData = useAppSelector(selectUserData)
    const dispatch = useAppDispatch()
    const [page, setPage] = React.useState(1);
    const [currentPropId, setCurrentPropId] = useState(0)
    const [filtersShown, setFiltersShown] = useState(false)
    const contactsForEmail = useAppSelector(selectContactsForEmail)
    let shownColumns = useAppSelector(selectAllPropertiesGridShownColumns)
    const hiddenColumns = useAppSelector(selectAllPropertiesGridHiddenColumns)
    const emailGreeting = useAppSelector(selectEmailGreeting)
    const defaultContact = useAppSelector(selectDefaultContact)
    const allPropertiesColumnsWithReq = useAppSelector(selectAllPropertiesColumnsWithReq)
    const allPropertiesColumnsUpcoming = useAppSelector(selectAllPropertiesColumnsUpcoming)
    const allPropertiesColumnsForAddressProperties = useAppSelector(selectAllPropertiesColumnsForAddressProperties)
    const allPropertiesDataWithReq = useAppSelector(selectAllPropertiesWithReq)
    const allPropertiesDataUpcoming = useAppSelector(selectAllPropertiesWithUpcoming)
    const allPropertiesAddressProperties = useAppSelector(selectAllPropertiesAddressProperties)
    const [openedActivity, setOpenedActivity] = useState(false)
    const upcomingPropertiesDefaultGridColumns = useAppSelector(selectUpcomingPropertiesDefaultColumnValues)
    const brokeragePropertiesDefaultGridColumns = useAppSelector(selectBrokeragePropertiesDefaultColumnValues)
    const [height, setHeight] = useState(window.innerHeight);
    const [gridContentHeight, setGridContentHeight] = useState(height - 440);
    const offerMessageGreeting = useAppSelector(selectOfferMessageGreeting)
    const checkedProperties = useAppSelector(selectPropertiesGridCheckedProperties)
    const checkedSurfaces = useAppSelector(selectCheckedSurfaces)
    const [isDisabledSaveBtn, setIsDisabledSaveBtn] = useState(false)
    const rowsPerPageOptions = [50, 100, 150, 200, 500];
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[2]);
    // const sortingRule = useAppSelector(selectAllPropertiesGridSortingRule)
    const gridLoaderMessage = useAppSelector(selectPropertiesGridLoaderMessage)
    const currentGrid = useAppSelector(selectAllPropertiesCurrentGrid)
    const currentReqId = useAppSelector(selectAllPropertiesGridWithReqGridReqId)
    const isAllPropertiesUpcomingLoading = useAppSelector(selectIsAllPropertiesUpcomingLoading)
    const isAllPropertiesReqLoading = useAppSelector(selectIsAllPropertiesReqLoading)
    const isAllPropertiesAddressPropertiesLoading = useAppSelector(selectIsAllPropertiesAddressPropertiesLoading)
    const gridColumns = currentGrid === 'All Properties/REQ' ? allPropertiesColumnsWithReq : currentGrid === 'All Properties/Upcoming' ? allPropertiesColumnsUpcoming : allPropertiesColumnsForAddressProperties
    let propertiesDataRows = currentGrid === 'All Properties/REQ' ? allPropertiesDataWithReq : currentGrid === 'All Properties/Upcoming' ? allPropertiesDataUpcoming : allPropertiesAddressProperties
    const isAddressModalOpened = useAppSelector(selectIsAllPropertiesAddressModalOpened)
    const contactLang = useAppSelector(selectPropertyContactLang)
    const addressPropertiesGridColumns = [...gridColumns]
    const isBrokerageDataUpdating = useAppSelector(selectIsBrokerageDataUpdating)
    const threeMonthsAgo = new Date(); // Get today's date
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3); // Set three months ago
    const today = new Date(); // Get today's date
    const navigate = useNavigate()
    const upcomingPropsSortModel = useAppSelector(selectUpcomingPropertiesGridLocalSortModel)
    const allPropsSortModel = useAppSelector(selectAllPropertiesGridLocalSortModel)
    const sortModel = currentGrid === 'All Properties/Upcoming' ? upcomingPropsSortModel : allPropsSortModel
    const isDataUpdatedWithFilter = useAppSelector(selectIsDataUpdatedWithFilter)
    const [selectedDateRange, setSelectedDateRange] = useState<[any | null, any | null]>([
        threeMonthsAgo,
        today,
    ]);
    const searchedAddress = useAppSelector(selectContactPropertiesSearchedAddress)
    const propertiesWasNotFound = useAppSelector(selectContactPropertiesWasNotFound)
    const isSearchInputShown = useAppSelector(selectContactPropertiesIsSearchInputShown)
    const upcomingPropsStatus = useAppSelector(selectUpcomingPropsCurrentStatus)
    const currentTab = useAppSelector(selectActivityModalCurrentTab)
    const activitiesProperties = useAppSelector(selectCurrentActivitiesCheckedProperties)
    const typeNumberEntity = useAppSelector(selectActivitiesTypeNumberEntity)
    const activitiesTypeKind = useAppSelector(selectActivitiesTypeKind)
    const modalFormFields = useAppSelector(selectActivitiesModalFormFields)
    const checkedSurfacesIds = useAppSelector(selectActivitiesCheckedSurfacesIds)
    const [loading, setIsLoading] = useState(false)
    const checkedActivitiesProperties = useAppSelector(selectActivitiesCheckedContactRows)
    const kindNumberEntity = useAppSelector(selectActivitiesKindNumberEntity)
    const defaultAllPropertiesGridColumns = currentGrid === 'All Properties/REQ' ? brokeragePropertiesDefaultGridColumns : upcomingPropertiesDefaultGridColumns
    const brokeragePropertiesColumnsForFilters = useAppSelector(selectPropertiesWithReqColumnsForFilters)
    const upcomingPropertiesColumnsForFilters = useAppSelector(selectUpcomingPropertiesColumnsForFilters)
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)
    const propContactGridFilters = useAppSelector(selectUpcomingPropertiesGridFiltersForArray)
    const brokerageGridFilters = useAppSelector(selectPropertiesWithReqGridFiltersForArray)
    const gridFilters = currentGrid === 'All Properties/REQ' ? brokerageGridFilters : propContactGridFilters
    let propertyActivities = useAppSelector(selectPropertyActivities)
    const organizationId = useAppSelector(selectOrganizationId)
    const contactId = useAppSelector(selectContactId)
    const loadedRows = useAppSelector(selectPropContactsLoadedRows)
    const propContactsWindowModes = useAppSelector(selectPropContactsWindowModes)
    const emailButtonMode = propContactsWindowModes?.find((m) => m.modeName === 'Email')
    const activityButtonMode = propContactsWindowModes?.find((m) => m.modeName === 'Activity')
    const location = useLocation()
    const currentRoute = location.pathname

    const onDateChange = (values: any) => {
        setSelectedDateRange(values);
    }

    useEffect(() => {
        if (checkedActivitiesProperties.length === 1 && modalFormFields.contacts === null && modalFormFields.organisations === null) {
            dispatch(GetCompanyByRefThunk(Number(checkedActivitiesProperties[0].CO_REF)))
                .then((res) => {
                    dispatch(onSetActivitiesOrganizationId(res.payload[0].REF))
                    dispatch(onChangeModalActivityFormField({
                        value: `${checkedActivitiesProperties[0].PE_FIRST_NAME !== null ? ` 
                        ${checkedActivitiesProperties[0].PE_FIRST_NAME}` : ''} ${checkedActivitiesProperties[0].PE_LAST_NAME !== null ? ` 
                        ${checkedActivitiesProperties[0].PE_LAST_NAME}` : ''} ${checkedActivitiesProperties[0].PE_EMAIL !== null ? `| 
                        ${checkedActivitiesProperties[0].PE_EMAIL}` : ''} ${checkedActivitiesProperties[0].PE_TEL !== null ? `| Phone: ${checkedActivitiesProperties[0].PE_TEL}` : ''}`,
                        inputName: 'Contacts'
                    }))
                    dispatch(onSetCurrentContactRef(res.payload[0].PE_REF))
                    dispatch(onSetActivitiesContactId(res.payload[0].PE_REF))
                })
            // dispatch(GetActivitiesContactsForPropContactsThunk(checkedActivitiesProperties[0].CO_REF))
            //     .then((res) => {
            //         const contact = res.payload.find((c: any) => c && c.PE_EMAIL !== null ? c.PE_EMAIL!.trim() === agentEmail.trim() : null)
            //         console.log(contact)
            //         console.log(res.payload)
            //         if (contact) {
            //             console.log(contact)
            //             dispatch(onChangeModalActivityFormField({
            //                 value: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME} ${contact.PE_EMAIL !== null ? `| ${contact.PE_EMAIL}` : ''} ${contact.TEL_NUMBER !== null ? `| Phone: ${contact.TEL_NUMBER}` : ''}`,
            //                 inputName: 'Contacts'
            //             }))
            //             dispatch(onSetCurrentContactRef(contact.PE_REF))
            //             dispatch(onSetActivitiesContactId(contact.PE_REF))
            //         }
            //
            //     })
        }

    }, [checkedActivitiesProperties, modalFormFields, dispatch])

    useEffect(() => {
        if (offerMessageGreeting === '' && emailGreeting && currentGrid !== 'Address Properties') {
            dispatch(onChangeOfferMessageGreeting(emailGreeting.message))
        }

    }, [offerMessageGreeting, emailGreeting, dispatch, currentGrid])


    useEffect(() => {
        if (!contactsForEmail.length && currentGrid !== 'Address Properties') {
            dispatch(GetPropertiesGridContacts())
        }
    }, [dispatch, contactsForEmail, currentGrid])
    let columns: any = []
    if (!columns.length) {
        columns = gridColumns.map((g: any) => {
            if (g.COL_NAME === 'ACTIVITY_EXISTED') {
                return {
                    field: g.COL_NAME,
                    headerName: g.COL_TITLE,
                    width: g.COL_WIDTH,
                    dataType: g.COL_TYPE,
                    col_pos: g.COL_POS,
                    col_value: g.COL_VALUE,
                    col_options: g.COL_OPTIONS,
                    withSurface: g.withSurface
                }
            } else if (g.COL_NAME === 'STATUS') {
                return {
                    field: g.COL_NAME,
                    headerName: g.COL_TITLE,
                    width: g.COL_WIDTH,
                    dataType: g.COL_TYPE,
                    col_pos: 1,
                    col_value: g.COL_VALUE,
                    col_options: g.COL_OPTIONS,
                    withSurface: g.withSurface
                }
            } else if (g.COL_NAME === 'PE_REMARKS') {
                return {
                    field: g.COL_NAME,
                    headerName: g.COL_TITLE,
                    width: g.COL_WIDTH,
                    flex: 1,
                    dataType: g.COL_TYPE,
                    col_pos: g.COL_POS,
                    col_value: g.COL_VALUE,
                    col_options: g.COL_OPTIONS,
                    withSurface: g.withSurface
                }
            } else {
                return {
                    field: g.COL_NAME,
                    headerName: g.COL_TITLE,
                    width: g.COL_WIDTH,
                    dataType: g.COL_TYPE,
                    col_pos: g.COL_POS,
                    col_value: g.COL_VALUE,
                    col_options: g.COL_OPTIONS,
                    withSurface: g.withSurface
                }
            }
        })
    }
    useEffect(() => {
        dispatch(onSetProperties({properties: [], gridType: 'Properties'}))
        dispatch(onSetProperties({properties: [], gridType: 'All Properties/REQ'}))
        dispatch(onSetProperties({properties: [], gridType: 'All Properties/Upcoming'}))
        dispatch(setShownColumns({columns: [], gridType: 'Properties'}))
        dispatch(setShownColumns({columns: [], gridType: 'All Properties/REQ'}))
        dispatch(setShownColumns({columns: [], gridType: 'All Properties/Upcoming'}))
        dispatch(onSetProperties({properties: [], gridType: 'Surfaces'}))
        dispatch(setShownColumns({columns: [], gridType: 'Surfaces'}))
        dispatch(onResetPropertiesGridColumns([]))
        dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
        dispatch(onSetContactPropertiesWasNotFound(null))
        gridDataHelper.removeConditionGridFiltersString()
        gridDataHelper.removePropertiesGridConfig()
        gridDataHelper.removeRowId()
        gridDataHelper.removeRowTitle()
        gridDataHelper.removePropertiesGridFilters()
        dispatch(clearPropertiesForEmail([]))
        dispatch(clearSurfacesForEmail([]))
        dispatch(onSetAllSurfaceClosed())
        dispatch(onClearCheckedProperties())
        dispatch(onClearCheckedSurfaces())
        dispatch(onFilterPropertiesGrid({gridFilters: [], gridType: 'Properties'}))
        dispatch(onSetSortingString({sorting_string: null, gridType: 'Properties'}))
        dispatch(onSetSortingString({sorting_string: null, gridType: 'All Properties/REQ'}));
        dispatch(onSetSortingString({sorting_string: null, gridType: 'All Properties/Upcoming'}))
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'Properties'}));
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'All Properties/REQ'}))
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'All Properties/Upcoming'}))
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'Surfaces'}))
        dispatch(onFilterPropertiesGrid({gridFilters: [], gridType: 'Surfaces'}))
        dispatch(onSetSortingString({sorting_string: null, gridType: 'Surfaces'}));
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'Surfaces'}));
        dispatch(onSetPropertiesGridLoaderMessage(''))
        dispatch(onChangeOfferType('Offer subscription'))
        gridDataHelper.removeIsCheckReqMatchFirstTime()
        dispatch(onSetIsCheckReqMatchFirstTime(false))
        setLocalSortModel({})
        if(currentRoute === '/contactProperties'){
            dispatch(onChangeCurrentGridTab('All Properties/Upcoming'))
            dispatch(onSetPropertiesWithReq({properties: [], gridType: 'All Properties/REQ'}))
        }
        else{
            dispatch(onChangeCurrentGridTab('All Properties/REQ'))
            dispatch(onSetUpcomingProperties({properties: [], gridType: 'All Properties/Upcoming'}))
        }
        dispatch(onSetContactPropertiesIsSearchInputShown(false))
        dispatch(setAllPropertiesGridColumns([]))

    }, [dispatch, currentRoute])
    useEffect(() => {
        if (defaultContact && defaultContact[0] && currentGrid === 'All Properties/REQ') {
            dispatch(onSetOfferToValuesInitial(defaultContact[0].PE_EMAIL))
        }

    }, [defaultContact, dispatch, currentGrid])

    // useEffect(() => {
    //     const localGridFilters = gridDataHelper.getAttrGridFilters()
    //
    //     if (localGridFilters && localGridFilters.length) {
    //         dispatch(setAttrCodesGridColumnsForFilters({
    //             columns: gridColumns.filter((c: any) => c.COL_NAME === 'DOMAIN_CD' || c.COL_NAME === 'POSITION').map((g: any) => {
    //
    //                 return {
    //                     title: g.COL_NAME,
    //                     headerName: g.COL_TITLE,
    //                     value: null,
    //                     condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
    //                     dataType: g.COL_TYPE,
    //                     isModalBtnOpened: false,
    //                     isFilterSelected: false,
    //                     filterable: false,
    //                 }
    //             })
    //         }))
    //         dispatch(onSetFiltersForAttrCodesGrid({gridFilters: localGridFilters}))
    //     } else {
    //         dispatch(setAttrCodesGridColumnsForFilters({
    //             columns: gridColumns.filter((c: any) => c.COL_NAME === 'DOMAIN_CD' || c.COL_NAME === 'POSITION').map((g: any) => {
    //
    //                 return {
    //                     title: g.COL_NAME,
    //                     headerName: g.COL_TITLE,
    //                     value: null,
    //                     condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
    //                     dataType: g.COL_TYPE,
    //                     isModalBtnOpened: false,
    //                     isFilterSelected: false,
    //                     filterable: false,
    //                 }
    //             })
    //         }))
    //     }
    //
    // }, [dispatch, gridColumns])


    useEffect(() => {
        const withReqProperties = sessionStorageGridFilters.getPropertiesWithReqGridFilters()
        if (currentGrid === 'All Properties/REQ' && !withReqProperties && !withReqProperties.length) {
            sessionStorageGridFilters.setPropertiesWithReqGridFilters([{
                "property": "STATUS",
                "value": "1",
                "type": "class java.lang.String",
                "condition": "0 ",
                "dateCondition": "onDate",
                "date": ""
            }])
            dispatch(onSetFiltersButtonsSelected({
                headerName: "STATUS",
                isFilterSelected: true,
                gridType: currentGrid
            }))
        }
    }, [currentGrid, dispatch])

    useEffect(() => {
        if (currentGrid !== 'Address Properties') {
            const withReqProperties = sessionStorageGridFilters.getPropertiesWithReqGridFilters()
            const withoutReqProperties = sessionStorageGridFilters.getContactPropertiesGridFilters()
            const filterColumnsLC = currentGrid === 'All Properties/REQ' ? withReqProperties : withoutReqProperties
            if (filterColumnsLC) {
                const updatedArray = gridColumns.map((item: any) => {
                    const secondArrayMatch = filterColumnsLC.find((secItem: any) => secItem.property === item.COL_NAME);
                    if (secondArrayMatch) {
                        return {
                            title: secondArrayMatch.property,
                            headerName: item.COL_TITLE,
                            value: secondArrayMatch.value,
                            condition: item.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                            dataType: item.COL_TYPE,
                            isModalBtnOpened: false,
                            isFilterSelected: true,
                            filterable: false,
                        }
                    } else {
                        return {
                            title: item.COL_NAME,
                            headerName: item.COL_TITLE,
                            value: null,
                            condition: item.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                            dataType: item.COL_TYPE,
                            isModalBtnOpened: false,
                            isFilterSelected: false,
                            filterable: false,
                        }
                    }

                });
                if (currentGrid === 'All Properties/REQ') {
                    dispatch(setWithReqColumnsForFilters({
                        columns: updatedArray,
                        gridType: currentGrid
                    }))
                    dispatch(onFilterPropertiesWithReqGrid({gridFilters: filterColumnsLC, gridType: currentGrid}))
                } else if (currentGrid === 'All Properties/Upcoming') {
                    dispatch(setWithoutReqColumnsForFilters({
                        columns: gridColumns.map((g: any) => {
                            if (g.COL_NAME === 'ACTIVITY_EXISTED') {
                                return {
                                    title: g.COL_NAME,
                                    headerName: 'activity_existed',
                                    value: null,
                                    condition: 'LIKE',
                                    dataType: "class java.lang.String",
                                    isModalBtnOpened: false,
                                    isFilterSelected: false,
                                    filterable: false,
                                }
                            } else {
                                return {
                                    title: g.COL_NAME,
                                    headerName: g.COL_TITLE,
                                    value: null,
                                    condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                                    dataType: g.COL_TYPE,
                                    isModalBtnOpened: false,
                                    isFilterSelected: false,
                                    filterable: false,
                                }
                            }
                        }),
                        gridType: currentGrid
                    }))
                    dispatch(onFilterUpcomingPropertiesGrid({gridFilters: filterColumnsLC, gridType: currentGrid}))
                }

            }
            if (currentGrid === 'All Properties/REQ') {
                dispatch(setWithReqColumnsForFilters({
                    columns: gridColumns.map((g: any) => {
                        if (g.COL_NAME === 'ACTIVITY_EXISTED') {
                            return {
                                title: g.COL_NAME,
                                headerName: 'activity_existed',
                                value: null,
                                condition: 'LIKE',
                                dataType: "class java.lang.String",
                                isModalBtnOpened: false,
                                isFilterSelected: false,
                                filterable: false,
                            }
                        } else {
                            return {
                                title: g.COL_NAME,
                                headerName: g.COL_TITLE,
                                value: null,
                                condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                                dataType: g.COL_TYPE,
                                isModalBtnOpened: false,
                                isFilterSelected: false,
                                filterable: false,
                            }
                        }
                    }),
                    gridType: currentGrid
                }))
            } else if (currentGrid === 'All Properties/Upcoming') {
                dispatch(setWithoutReqColumnsForFilters({
                    columns: gridColumns.map((g: any) => {
                        if (g.COL_NAME === 'ACTIVITY_EXISTED') {
                            return {
                                title: g.COL_NAME,
                                headerName: 'activity_existed',
                                value: null,
                                condition: 'LIKE',
                                dataType: "class java.lang.String",
                                isModalBtnOpened: false,
                                isFilterSelected: false,
                                filterable: false,
                            }
                        } else {
                            return {
                                title: g.COL_NAME,
                                headerName: g.COL_TITLE,
                                value: null,
                                condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                                dataType: g.COL_TYPE,
                                isModalBtnOpened: false,
                                isFilterSelected: false,
                                filterable: false,
                            }
                        }
                    }),
                    gridType: currentGrid
                }))
            }

        }


    }, [dispatch, gridColumns, currentGrid])

    // useEffect(() => {
    //     const filterColumnsFromSessionStorage = sessionStorageGridFilters.getRequirementsGridFilters()
    //     if (filterColumnsFromSessionStorage) {
    //         gridFiltering(filterColumnsFromSessionStorage, defaultRequirementGridColumns, requirementsColumnsForFilters, 'Requirements', dispatch)
    //     }
    // }, [dispatch, defaultRequirementGridColumns])


    useEffect(() => {
        const withReqProperties = sessionStorageGridFilters.getPropertiesWithReqGridFilters()
        const withoutReqProperties = sessionStorageGridFilters.getContactPropertiesGridFilters()
        if (currentGrid === 'All Properties/REQ') {
            if (withReqProperties) {
                gridFiltering(withReqProperties, defaultAllPropertiesGridColumns, brokeragePropertiesColumnsForFilters, currentGrid, dispatch)
            }
        } else if (currentGrid === 'All Properties/Upcoming') {
            if (withoutReqProperties) {
                gridFiltering(withoutReqProperties, defaultAllPropertiesGridColumns, upcomingPropertiesColumnsForFilters, currentGrid, dispatch)
            }
        }
        // eslint-disable-next-line
    }, [isDataUpdatedWithFilter, defaultAllPropertiesGridColumns, currentGrid, dispatch])

    useEffect(() => {
        if (currentReqId !== false) {
            dispatch(GetPropertiesContactLang(currentReqId))
        }

    }, [dispatch, currentReqId])

    useEffect(() => {
        // if (currentGrid === 'All Properties/REQ') {
        //     dispatch(GetPropertiesEmailSubject({req_id: currentReqId, user_id: userData.user_ref}))
        // }
        if (currentGrid !== 'Address Properties') {
            dispatch(GetPropertyAgentAddress(userData.user_ref))
        }


    }, [currentReqId, userData.user_ref, dispatch, currentGrid])


    const predefinedRanges = [
        {
            label: 'Today',
            value: [new Date(), new Date()],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date(), -1), addDays(new Date(), -1)],
            placement: 'left'
        },
        {
            label: 'This week',
            value: [startOfWeek(new Date()), endOfWeek(new Date())],
            placement: 'left'
        },
        {
            label: 'Last 7 days',
            value: [subDays(new Date(), 6), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'This year',
            value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
            placement: 'left'
        },
        {
            label: 'Last year',
            value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
            placement: 'left'
        },
        {
            label: 'All time',
            value: [new Date(new Date().getFullYear() - 4, 0, 1), new Date()],
            placement: 'left'
        },
        {
            label: 'Last week',
            closeOverlay: false,
            value: (value: any) => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, {weekStartsOn: 0}), -7),
                    addDays(endOfWeek(start, {weekStartsOn: 0}), -7)
                ];
            },
            appearance: 'default'
        },
        {
            label: 'Next week',
            closeOverlay: false,
            value: (value: any) => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, {weekStartsOn: 0}), 7),
                    addDays(endOfWeek(start, {weekStartsOn: 0}), 7)
                ];
            },
            appearance: 'default'
        }
    ];

    useEffect(() => {
        if (currentGrid === 'Address Properties' && selectedDateRange && !isBrokerageDataUpdating) {
            const endDate = new Date(selectedDateRange[1]);
            endDate.setHours(20, 59, 59, 999);
            const startDate = new Date(selectedDateRange[0]);
            startDate.setHours(0, 0, 0, 0);
            dispatch(GetAllPropertiesAddressPropertiesGridThunk(
                {
                    date_from: moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                    date_to: moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                }
            ))
        }

        if (gridLoaderMessage === 'Reset data is in progress') {
            dispatch(onSetPropertiesGridLoaderMessage('Reset data is in progress'))
        } else {
            dispatch(onSetPropertiesGridLoaderMessage('Loading'))
        }

        // dispatch(onSetLocalSortModel(currentPropColumns?.gridColumns.flat(1)))
    }, [dispatch, gridRowId, userData.user_ref, currentGrid, selectedDateRange, gridLoaderMessage, isBrokerageDataUpdating])


    useEffect(() => {
        const withReqProperties = sessionStorageGridFilters.getPropertiesWithReqGridFilters()
        let statusColumn;
        if (withReqProperties) {
            statusColumn = withReqProperties?.find((c: any) => c.property === 'STATUS')
        }
        if (currentGrid === 'All Properties/REQ') {
            dispatch(GetAllPropertiesWithREQGridThunk({
                requestData: {
                    p_agent_ref: Number(userData.user_ref),
                    p_status: statusColumn ? Number(statusColumn.value) : 1,
                },
            }))
        }
        if (gridLoaderMessage === 'Reset data is in progress') {
            dispatch(onSetPropertiesGridLoaderMessage('Reset data is in progress'))
        } else {
            dispatch(onSetPropertiesGridLoaderMessage('Loading'))
        }
    }, [dispatch, userData, currentGrid, gridLoaderMessage])


    useEffect(() => {
        if (currentGrid === 'All Properties/Upcoming' && !isBrokerageDataUpdating) {
            dispatch(GetMapPropertiesStatusesThunk())
            // dispatch(onSetUpcomingPropsCurrentStatus('1'))
        } else {
            dispatch(onSetBrokeragePropsCurrentStatus('1'))
        }
        if (gridLoaderMessage === 'Reset data is in progress') {
            dispatch(onSetPropertiesGridLoaderMessage('Reset data is in progress'))
        } else {
            dispatch(onSetPropertiesGridLoaderMessage('Loading'))
        }
    }, [dispatch, userData, currentGrid, gridLoaderMessage, isBrokerageDataUpdating])

    useEffect(() => {
        if (currentGrid === 'Address Properties') {
            dispatch(GetPropertiesCountries())
        }
    }, [currentGrid, dispatch])


    useEffect(() => {
        const sortModelObject: any = {};
        for (const item of sortModel) {
            sortModelObject[item.field] = {
                field: item.field,
                sort: item.sort ? item.sort : 'desc'
            };
        }
        setLocalSortModel(sortModelObject)
    }, [sortModel])


    const [localSortModel, setLocalSortModel] = useState<any>({});

    const [checked, setChecked] = useState<any>([]);

    const [hiddenModal, setHiddenModal] = useState(false)


    const handleSortModelChange = (newModel: any) => {
        if (currentGrid !== 'Address Properties') {
            const newLocalModel = {...localSortModel};
            newModel.forEach((model: any) => {
                newLocalModel[model.field] = model;
            });
            setLocalSortModel(newLocalModel);
            const mergedArray = [...sortModel, ...newModel];
            const index = mergedArray.findIndex(item => item?.field === sortModel[0]?.field);
            if (index !== -1 && mergedArray[index]?.sort !== newModel[0]?.sort) {
                // eslint-disable-next-line
                const updatedObject = {
                    ...mergedArray[index],
                    sort: newModel[0]?.sort
                };
                mergedArray[index] = updatedObject;
            }

            dispatch(onSetLocalSortModel({sort_model: mergedArray, gridType: currentGrid}))


        } else {
            setLocalSortModel(newModel);
        }

    };


    const onSaveGridConfigs = () => {
        gridDataHelper.setPropertiesGridConfig(true)
        // dispatch(onSetAllPropertiesReqLoading(true))
        // dispatch((onSetAllPropertiesUpcomingLoading(true)))
        let hiddenColumnsResult;
        let shownColumnsResult;
        if (hiddenColumns.length) {
            hiddenColumnsResult = hiddenColumns.map((yItem: any, index: number) => {
                let xMatch = gridColumns.find((xItem: any) => xItem.headerName === yItem.headerName);
                xMatch = {...xMatch, COL_WIDTH: 0, COL_POS: shownColumns.length + index}
                return xMatch ? {...yItem, ...xMatch} : yItem;
            });
        } else if (!hiddenColumns.length) {
            hiddenColumnsResult = []
        }
        if (shownColumns.length) {
            shownColumnsResult = shownColumns.map((yItem: any) => {
                let xMatch = gridColumns.find((xItem: any) => xItem.headerName === yItem.headerName);
                xMatch = {...xMatch, COL_WIDTH: yItem.width}
                return xMatch ? {...yItem, ...xMatch} : yItem;
            });
        } else if (!shownColumns.length) {
            shownColumnsResult = shownColumns
        }
        const shownColumnsResultFinal = shownColumnsResult?.map((g: any, index: number) => {
            return {
                COL_NAME: g.field,
                COL_OPTIONS: g.col_options,
                COL_POS: index,
                COL_TITLE: g.headerName,
                COL_TYPE: g.dataType,
                COL_VALUE: g.col_value,
                COL_WIDTH: g.COL_WIDTH
            }
        }).filter((c: any) => c.COL_NAME !== 'ACTIVITY_EXISTED' && c.COL_NAME !== 'SURFACE_EXISTED')
        const hiddenColumnsResultFinal = hiddenColumnsResult?.map((g: any) => {
            return {
                COL_NAME: g.field,
                COL_OPTIONS: g.col_options,
                COL_POS: g.COL_POS,
                COL_TITLE: g.headerName,
                COL_TYPE: g.dataType,
                COL_VALUE: g.col_value,
                COL_WIDTH: g.COL_WIDTH
            }
        }).filter((c: any) => c.COL_NAME !== 'ACTIVITY_EXISTED' && c.COL_NAME !== 'SURFACE_EXISTED')

        function mergeArrays(hiddenColumns: any, shownColumns: any) {
            const result = [];
            for (const hiddenColumn of hiddenColumns) {
                const matchingObjS = shownColumns.find((objS: any) => objS.COL_NAME === hiddenColumn.COL_NAME);
                if (matchingObjS) {
                    hiddenColumn.COL_WIDTH = matchingObjS.COL_WIDTH;
                }
                result.push(hiddenColumn);
            }
            for (const shownColumn of shownColumns) {
                if (!result.find(obj => obj.COL_NAME === shownColumn.COL_NAME)) {
                    result.push(shownColumn);
                }
            }
            return result;
        }

        const sortConditionStrArray = Object.keys(localSortModel).map(key => {
            // @ts-ignore
            return {
                "field": localSortModel[key].field, "sort": localSortModel[key].sort
            };
        });
        const sortConditionStr = sortConditionStrArray?.map((item: any) => `${item.field} ${item.sort.toUpperCase()}`).join(', ');
        const withReqData = {
            p_agent_ref: Number(userData.user_ref),
            p_grid_columns: mergeArrays(hiddenColumnsResultFinal, shownColumnsResultFinal),
            // p_row_req: null,
            p_sort_order: sortConditionStr && sortConditionStr !== '' ? sortConditionStr : 'PROP_ID DESC',
            grid_type: "PROP_WITH_REQ_DATA",
        }
        const withoutReqData = {
            p_agent_ref: Number(userData.user_ref),
            p_grid_columns: mergeArrays(hiddenColumnsResultFinal, shownColumnsResultFinal),
            // p_row_req: null,
            p_sort_order: sortConditionStr && sortConditionStr !== '' ? sortConditionStr : 'PROP_ID DESC',
            grid_type: "PROP_CONTACT",
        }
        if (currentGrid === 'All Properties/REQ') {
            // dispatch(onSetIsBrokerageDataUpdating(true))
            gridApi.updateGridColumnData(withReqData)
                .then(() => {
                    message.success('Grid configs were saved')
                })
            // .then(() => {
            //     dispatch(GetAllPropertiesWithREQGridThunk({
            //         requestData: {
            //             p_agent_ref: Number(userData.user_ref),
            //             p_status: 1,
            //         },
            //     }))
            // })
            // .catch((e) => {
            //     console.log(e)
            // })
        } else {
            // dispatch(onSetIsBrokerageDataUpdating(true))
            gridApi.updateGridColumnData(withoutReqData)
                .then(() => {
                    dispatch((onSetAllPropertiesUpcomingLoading(false)))
                    message.success('Grid configs were saved')
                    // if (currentAddrStreet) {
                    //     dispatch(GetAllPropertiesWithoutREQGridThunk({
                    //         requestData: {
                    //             p_agent_ref: Number(userData.user_ref),
                    //             p_function: null,
                    //             p_sort_order: null,
                    //             p_address: currentAddrStreet!.value.toLowerCase().trim(),
                    //             p_status: currentStatus !== null ? Number(currentStatus) : currentStatus
                    //         },
                    //     }))
                    // } else {
                    //     dispatch(GetAllPropertiesWithoutREQGridThunk({
                    //         requestData: {
                    //             p_agent_ref: Number(userData.user_ref),
                    //             p_function: null,
                    //             p_sort_order: null,
                    //             p_status: currentStatus !== null ? Number(currentStatus) : currentStatus,
                    //             p_page: 1,
                    //             p_size: 20
                    //         },
                    //     }))
                    // }

                })
                .catch((e) => {
                    console.log(e)
                })
        }
        // dispatch(setShownColumns({columns: [], gridType: 'All Properties/REQ'}))
        // dispatch(setShownColumns({columns: [], gridType: 'All Properties/Upcoming'}))
        // dispatch(setAllPropertiesGridColumns([]))
        // dispatch(onSetSortingString({sorting_string: null, gridType: 'All Properties/REQ'}));
        // dispatch(onSetLocalSortModel({sort_model: [], gridType: 'All Properties/REQ'}));
        // dispatch(onSetSortingString({sorting_string: null, gridType: 'All Properties/Upcoming'}));
        // dispatch(onSetLocalSortModel({sort_model: [], gridType: 'All Properties/Upcoming'}));
        // dispatch(onSetPropertiesGridLoaderMessage('Saving data is in progress'))
    }

    const onResetConfigs = () => {
        const columnsWithSorting = gridDataHelper.getPropertiesGridColumnsWithSorting()
        dispatch(onSetAllPropertiesReqLoading(true))
        dispatch((onSetAllPropertiesUpcomingLoading(true)))
        dispatch(setShownColumns({columns: [], gridType: 'All Properties/REQ'}))
        dispatch(setShownColumns({columns: [], gridType: 'All Properties/Upcoming'}))
        dispatch(setAllPropertiesGridColumns([]))
        setSearchTerm('')
        // setSortModel([])
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'All Properties/REQ'}))
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'All Properties/Upcoming'}))
        setLocalSortModel({})
        if (currentGrid === 'All Properties/REQ') {
            dispatch(onSetIsBrokerageDataUpdating(true))
            dispatch(ResetAllGridConfigsThunk({
                p_agent_ref: Number(userData.user_ref),
                p_row_req: null,
                grid_type: "PROP_WITH_REQ_DATA"
            }))
                .then(() => {
                    dispatch((onSetAllPropertiesUpcomingLoading(false)))
                    dispatch(GetAllPropertiesWithREQGridThunk({
                        requestData: {
                            p_agent_ref: Number(userData.user_ref),
                            p_status: 1,
                        },
                    }))

                })
        } else {
            dispatch(onSetIsBrokerageDataUpdating(true))
            dispatch(ResetAllGridConfigsThunk({
                p_agent_ref: Number(userData.user_ref),
                p_row_req: null,
                grid_type: "PROP_CONTACT"
            }))
                .then(() => {
                    dispatch((onSetAllPropertiesUpcomingLoading(false)))
                    dispatch(GetAllPropertiesWithoutREQGridThunk({
                        requestData: {
                            p_agent_ref: Number(userData.user_ref),
                            p_function: null,
                            p_sort_order: null,
                            p_address: searchedAddress.toLowerCase().trim(),
                            p_status: null
                        },
                    }))
                })
        }

        const propertyIndex = columnsWithSorting.properties.findIndex((property: any) => Number(property.gridPropId) === Number(gridRowId));
        if (Number(propertyIndex) !== -1 && columnsWithSorting.properties.length !== 1) {
            const filteredColumns = columnsWithSorting.properties.filter((c: any) => c.gridPropId !== gridRowId)
            gridDataHelper.setPropertiesGridColumnsWithSorting({
                properties: filteredColumns.map((column: any) => {
                    return {
                        gridColumns: column.gridColumns,
                        gridPropId: column.gridPropId,
                    };
                })
            })
        } else if (columnsWithSorting.properties.length === 1 && Number(propertyIndex) !== -1) {
            gridDataHelper.removePropertiesGridColumnsWithSorting()
        }
        gridDataHelper.removePropertiesGridConfig()
        gridDataHelper.removePropertiesGridFilters()
        dispatch(onSetPropertiesGridLoaderMessage('Reset data is in progress'))
    }

    const apiRef = useGridApiRef();

    useEffect(() => {

        if (columns.length) {
            dispatch(setShownColumns({
                // eslint-disable-next-line
                columns: columns.filter((c: any) => c?.width !== 0).map((c: any, index: number) => {
                    if (c.field === 'ACTIVITY_EXISTED') {
                        return {
                            field: 'ACTIVITY_EXISTED',
                            headerName: 'activity',
                            width: 100,
                            checked: false,
                            id: index,
                            dataType: "class java.lang.String",
                            isFilterSelected: false,
                            col_pos: 2,
                            col_value: c.col_value,
                            col_options: c.col_options,
                            withSurface: c.withSurface,
                            key: index,
                        }
                    } else if (c.field === 'SURFACE_EXISTED') {
                        return {
                            field: 'SURFACE_EXISTED',
                            headerName: 'SURFACE_EXISTED',
                            width: 0,
                            checked: false,
                            id: index,
                            dataType: c.dataType,
                            isFilterSelected: false,
                            col_pos: -1,
                            col_value: c.col_value,
                            col_options: c.col_options,
                            withSurface: c.withSurface,
                            key: index
                        }
                    } else if (c.field !== 'ACTIVITY_EXISTED') {
                        if (c.dataType === 'class java.math.BigDecimal') {
                            return {
                                field: c.field,
                                headerName: c.headerName,
                                width: c.width,
                                checked: false,
                                id: index,
                                dataType: c.dataType,
                                isFilterSelected: false,
                                col_pos: c.col_pos,
                                col_value: c.col_value,
                                col_options: c.col_options,
                                withSurface: c.withSurface,
                                key: index,
                                sortComparator: numericComparator
                            }
                        } else if (c.field === 'ADDR_LONGI' || c.field === 'ADDR_LATI') {
                            return {
                                field: c.field,
                                headerName: c.headerName,
                                width: c.width,
                                checked: false,
                                id: index,
                                dataType: c.dataType,
                                isFilterSelected: false,
                                col_pos: columns[columns.length - 1],
                                col_value: c.col_value,
                                col_options: c.col_options,
                                withSurface: c.withSurface,
                                key: index
                            }
                        } else {
                            return {
                                field: c.field,
                                headerName: c.headerName,
                                width: c.width,
                                checked: false,
                                id: index,
                                dataType: c.dataType,
                                isFilterSelected: false,
                                col_pos: c.col_pos,
                                col_value: c.col_value,
                                col_options: c.col_options,
                                withSurface: c.withSurface,
                                key: index,
                            }
                        }

                    }

                }), gridType: currentGrid
            }))
            dispatch(setHiddenColumns({
                columns: columns.filter((c: any) => c?.width === 0).map((c: any, index: number) => {
                    if (c.field === 'ACTIVITY_EXISTED') {
                        return {
                            field: 'ACTIVITY_EXISTED',
                            headerName: 'activity',
                            width: 0,
                            checked: false,
                            id: index,
                            dataType: "class java.lang.String",
                            isFilterSelected: false,
                            col_pos: 1,
                            col_value: c.col_value,
                            col_options: c.col_options,
                            withSurface: c.withSurface,
                            key: index,
                        }
                    } else if (c.field === 'SURFACE_EXISTED') {
                        return {
                            field: 'SURFACE_EXISTED',
                            headerName: 'SURFACE_EXISTED',
                            width: 0,
                            checked: false,
                            id: index,
                            dataType: c.dataType,
                            isFilterSelected: false,
                            col_pos: -1,
                            col_value: c.col_value,
                            col_options: c.col_options,
                            withSurface: c.withSurface,
                            key: index
                        }
                    } else if (c.dataType === 'class java.math.BigDecimal') {
                        return {
                            field: c.field,
                            headerName: c.headerName,
                            width: 0,
                            checked: false,
                            id: index,
                            dataType: c.dataType,
                            isFilterSelected: false,
                            col_pos: c.col_pos,
                            col_value: c.col_value,
                            col_options: c.col_options,
                            withSurface: c.withSurface,
                            key: index,
                            sortComparator: numericComparator
                        }
                    } else {
                        return {
                            field: c.field,
                            headerName: c.headerName,
                            width: 0,
                            checked: false,
                            id: index,
                            dataType: c.dataType,
                            isFilterSelected: false,
                            col_pos: c.col_pos,
                            col_value: c.col_value,
                            col_options: c.col_options,
                            withSurface: c.withSurface,
                            key: index,
                        }
                    }
                }), gridType: currentGrid
            }))
            setChecked(columns.filter((c: any) => c?.width === 0).map((c: any) => c.field))
        }

        // eslint-disable-next-line
    }, [gridColumns, currentGrid, dispatch])

    const onSetFiltersShown = () => {
        setFiltersShown(!filtersShown)
    }


    const handleFiltersShownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // Prevent event bubbling to document click event listener
        onSetFiltersShown();
    };

    useEffect(() => {
        if (!checkedSurfaces.length && !checkedProperties.length) {
            dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
        }
    }, [dispatch, checkedSurfaces, checkedProperties])


    const onHideModal = () => {
        dispatch(onChangeWindowModesMode({modeName: 'Email', mode: 'Hided'}))
        if(activityButtonMode?.mode !== 'Opened'){
            dispatch(onSetCurrentWindow(null))
        }
        else {
            dispatch(onSetCurrentWindow('Activity'))
        }

        // setHiddenModal(!hiddenModal)
        // if (hiddenModal) {
        //     dispatch(onClearPropertyActivities())
        //     setOpenedActivity(false)
        // }
    }

    const unHideModal = () => {
        dispatch(onChangeWindowModesMode({modeName: 'Email', mode: 'Opened'}))
        if(activityButtonMode?.mode !== 'Closed'){
            dispatch(onChangeWindowModesMode({modeName: 'Activity', mode: 'Hided'}))
        }
        dispatch(onSetCurrentWindow('Email'))
    }

    const unHideActivityModal = () => {
        dispatch(onChangeWindowModesMode({modeName: 'Activity', mode: 'Opened'}))
        if(emailButtonMode?.mode !== 'Closed'){
            dispatch(onChangeWindowModesMode({modeName: 'Email', mode: 'Hided'}))
        }

        dispatch(onSetCurrentWindow('Activity'))
    }

    const onHideActivityModal = () => {
        dispatch(onChangeWindowModesMode({modeName: 'Activity', mode: 'Hided'}))
        if(emailButtonMode?.mode !== 'Opened'){
            dispatch(onSetCurrentWindow(null))
        }
        else {
            dispatch(onSetCurrentWindow('Email'))
        }
        // setHiddenModal(!hiddenModal)
        // if (hiddenModal) {
        //     dispatch(onClearPropertyActivities())
        //     setOpenedActivity(false)
        // }
    }

    const onCloseActivityModal = () => {
        dispatch(onClearPropertyActivities())
        dispatch(onChangeWindowModesMode({modeName: 'Activity', mode: 'Closed'}))
        setOpenedActivity(false)
        setIsDisabledSaveBtn(true)
        setTimeout(() => {
            setIsDisabledSaveBtn(false)
        }, 1000)
    }

    function handleRowsPerPageChange(event: any) {
        setRowsPerPage(event);
        setPage(1)
    }

    const onResetFilters = () => {
        if (currentGrid === 'All Properties/REQ') {
            dispatch(onSetPropertiesWithReq({properties: defaultAllPropertiesGridColumns, gridType: currentGrid}))
            const resetShownColumns = shownColumns.map((c: any) => c.field !== 'STATUS' ? {
                ...c,
                isFilterSelected: false,
                isModalBtnOpened: false
            } : c)
            dispatch(setShownColumns({columns: resetShownColumns, gridType: currentGrid}))
            dispatch(onResetFiltersButtonsSelected(currentGrid))
            sessionStorageGridFilters.removeBrokeragePropertiesGridFilters()
        } else {
            dispatch(onSetUpcomingProperties({properties: defaultAllPropertiesGridColumns, gridType: currentGrid}))
            const resetShownColumns = shownColumns.map((c: any) => c.field !== 'STATUS' ? {
                ...c,
                isFilterSelected: false,
                isModalBtnOpened: false
            } : c)
            dispatch(setShownColumns({columns: resetShownColumns, gridType: currentGrid}))
            dispatch(onResetFiltersButtonsSelected(currentGrid))
            sessionStorageGridFilters.removeContactPropertiesGridFilters()
        }
        // dispatch(onFilterPropertiesGrid([]))

    }

    const onResetSorting = () => {
        dispatch(onSetLocalSortModel({sort_model: [], gridType: currentGrid}))
        setLocalSortModel({})
    }

    const handlePageChange = (params: any, value: number) => {
        setPage(value);
        afterPaginationPageChangeScrollToTop();
    }

    const afterPaginationPageChangeScrollToTop = () => {
        const gridApi = apiRef.current;
        if (!gridApi) return;
        gridApi.scrollToIndexes({rowIndex: 0});
    };


    const [searchTerm, setSearchTerm] = useState('');

    let sortedRows = customSortFunction(propertiesDataRows, sortModel);


    const rows = sortedRows
        .reduce((acc, row) => {
            const values = Object.values(row);
            const useStartsWith = searchTerm.endsWith('%');
            const processedSearchTerm = searchTerm.slice(0, -1).toLowerCase();
            if (values.some(value => {
                const stringValue = String(value).toLowerCase();
                return useStartsWith ? stringValue.startsWith(processedSearchTerm) : stringValue.includes(searchTerm.toLowerCase());
            })) {
                acc.push(row);
            }
            return acc;
        }, [])
    // .filter((r: any) => )

    let propertiesTestDataRowsPaginated = rows.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);
    const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {

        setSearchTerm(event.target.value)

    };

    // layout effect to always adapt grid height to the user screen device height
    useLayoutEffect(() => {
        const handleResize = debounce(function () {
            setHeight(window.innerHeight);
            const heightForPage = window.innerHeight - 500;
            setGridContentHeight(heightForPage);
        }, 100);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const debounce = (func: Function, wait: number) => {
        let timeout: ReturnType<typeof setTimeout> | null;
        return function (this: any, ...args: any[]) {
            const context = this;
            clearTimeout(timeout!);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    };

    const onRowDoubleClick = (params: any) => {
        const columnName = params.field;
        const rowData = params.row;
        gridDataHelper.setBrokeragePropertiesSearchValue(searchTerm);
        gridDataHelper.setBrokeragePropertiesStatus('1');
        if (columnName !== 'PE_REMARKS') {
            if (currentGrid === 'All Properties/Upcoming') {
                dispatch(GetPropertyActivities({
                    req_id: null,
                    row_pro: rowData.PROP_REF,
                    user_id: Number(userData.user_ref),
                }))
                    .then((res) => {
                        // @ts-ignore
                        if (!res.payload.length) {
                            message.error('There is no activities for this property');
                        }
                    });
                setHiddenModal(true);
                setCurrentPropId(rowData.PROP_REF);
                setOpenedActivity(true);
            } else if (currentGrid === 'All Properties/REQ') {
                dispatch(GetBrokerageIsReqExisted({prop_ref: rowData.PROP_ID, user_ref: Number(userData.user_ref)}))
                    .then((res) => {
                        if (res.payload === 1) {
                            dispatch(setBrokerageReqProp(rowData));
                            navigate('/properties/brokerage/reqs');
                        } else {
                            message.error('There is no requirements for this property');
                        }
                    });
            }
        }

    }

    // We have custom column position in our state, so default function, provided MUI data grid doesn't fit our app. So we get the column order from state and then sort them in our state in redux
    const handleStateChange = (params: any) => {
        if (currentGrid !== 'Address Properties') {
            // Access the grid state
            dispatch(onChangeColumnsGridPosition({columns: params.columns.all, gridType: currentGrid}))
            dispatch(onChangeColumnsGridWidth({columns: params.columns.lookup, gridType: currentGrid}))
        }

    };


    const onChangeCurrentGridType = (gridType: 'All Properties/Upcoming' | 'All Properties/REQ' | 'Address Properties' | 'Map') => {
        dispatch(clearPropertiesForEmail([]))
        dispatch(onClearCheckedProperties())
        dispatch(onChangeCurrentGridTab(gridType))
        dispatch(onSetIsBrokerageDataUpdating(false))
        dispatch(onSetLocalSortModel({sort_model: [], gridType: currentGrid}))
        // dispatch(onSetProperties({properties: [], gridType: currentGrid}))
        dispatch(onSetSortingString({sorting_string: null, gridType}))
        gridDataHelper.removePropertiesGridFilters()
        // dispatch(onSetUpcomingPropsCurrentStatus(null))
        // dispatch(onSetBrokeragePropsCurrentStatus(null))
        dispatch(onFilterPropertiesGrid({gridFilters: [], gridType: gridType}))
        dispatch(setShownColumns({columns: [], gridType: 'All Properties/REQ'}))
        dispatch(setShownColumns({columns: [], gridType: 'All Properties/Upcoming'}))
        setLocalSortModel({})
        dispatch(onSetContactPropertiesSearchedAddress(''))
        dispatch(onSetContactPropertiesWasNotFound(null))
        dispatch(onSetContactPropertiesIsSearchInputShown(false))
        dispatch(setAllPropertiesGridColumns([]))
        dispatch(onClearPropertyActivities())
        dispatch(onSetIsActivityModalOpened(false))
        dispatch(onSetPropertiesWithReq({properties: [], gridType: 'All Properties/REQ'}))
        dispatch(onSetUpcomingProperties({properties: [], gridType: 'All Properties/Upcoming'}))
        dispatch(onResetPropertiesGridColumns([]))
        dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
        dispatch(onSetContactPropertiesWasNotFound(null))
        gridDataHelper.removeConditionGridFiltersString()
        gridDataHelper.removePropertiesGridConfig()
        gridDataHelper.removeRowId()
        gridDataHelper.removeRowTitle()
        gridDataHelper.removePropertiesGridFilters()
        dispatch(clearPropertiesForEmail([]))
        dispatch(clearSurfacesForEmail([]))
        dispatch(onSetAllSurfaceClosed())
        dispatch(onClearCheckedProperties())
        dispatch(onClearCheckedSurfaces())
        dispatch(onSetSortingString({sorting_string: null, gridType: 'All Properties/REQ'}));
        dispatch(onSetSortingString({sorting_string: null, gridType: 'All Properties/Upcoming'}))
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'All Properties/REQ'}))
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'All Properties/Upcoming'}))
        dispatch(onSetPropertiesGridLoaderMessage(''))
        dispatch(onChangeOfferType('Offer subscription'))
        gridDataHelper.removeIsCheckReqMatchFirstTime()
        dispatch(onSetIsCheckReqMatchFirstTime(false))
        setLocalSortModel({})
        dispatch(onSetContactPropertiesIsSearchInputShown(false))
        dispatch(setAllPropertiesGridColumns([]))
    }


    useEffect(() => {
        dispatch(GetMapPropertiesStatusesThunk())
    }, [dispatch])


    const onCancelRequest = () => {
        // dispatch(onSetAllPropertiesUpcomingLoading(false))
        const controller = getAbortController();
        controller.abort();
    }


    const onAddressChange = (value: string) => {
        dispatch(onSetContactPropertiesSearchedAddress(value))
    }

    const onSearchAddress = () => {
        dispatch(onSetContactPropertiesLastSearchedAddress(searchedAddress))
        dispatch(GetAllPropertiesWithoutREQGridThunk({
            requestData: {
                p_agent_ref: Number(userData.user_ref),
                p_function: null,
                p_sort_order: null,
                p_address: searchedAddress.toLowerCase().trim(),
                p_status: null
            },
        }))
            .then(() => {
                setPage(1)
            })
        if (upcomingPropsStatus === null) {
            sessionStorageGridFilters.setContactPropertiesGridFilters([{
                "property": "STATUS",
                "value": "All",
                "type": "class java.lang.String",
                "condition": "",
                "dateCondition": "onDate",
                "date": ""
            }])
            dispatch(onSetFiltersButtonsSelected({
                headerName: "STATUS",
                isFilterSelected: true,
                gridType: currentGrid
            }))
            dispatch(onChangeColumnForFilterValue({
                title: "STATUS",
                value: "All",
                gridType: currentGrid
            }))
            dispatch(onSetLastUpcomingPropsCurrentStatus(null))
        } else {
            sessionStorageGridFilters.setContactPropertiesGridFilters([{
                "property": "STATUS",
                "value": "All",
                "type": "class java.lang.String",
                "condition": "",
                "dateCondition": "onDate",
                "date": ""
            }])
            dispatch(onSetFiltersButtonsSelected({
                headerName: "STATUS",
                isFilterSelected: true,
                gridType: currentGrid
            }))
            dispatch(onChangeColumnForFilterValue({
                title: "STATUS",
                "value": 'All',
                gridType: currentGrid
            }))
            dispatch(onSetLastUpcomingPropsCurrentStatus(upcomingPropsStatus))
        }


    }



    const onOpenOfferModal = () => {
        dispatch(onSetCurrentWindow('Email'))
        dispatch(onChangeWindowModesMode({modeName: 'Email', mode: 'Opened'}))
        if(activityButtonMode?.mode === 'Opened'){
            dispatch(onChangeWindowModesMode({modeName: 'Activity', mode: 'Hided'}))
        }

    }

    const onOpenActivityModal = () => {
        dispatch(onSetCurrentWindow('Activity'))
        // if (currentGrid === 'Properties') {
        //     dispatch(onSetLocalSortModel({sort_model: [{field: "DATE_OFFERED", sort: "desc"}], gridType: currentGrid}))
        // }
        dispatch(onSetCurrentModalTab('General'))
        dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
        // dispatch(onSetPropertiesMessageBody(''))
        dispatch(onSetIsActivityModalOpened(true))
        dispatch(onChangeWindowModesMode({modeName: 'Activity', mode: 'Opened'}))
        if(emailButtonMode?.mode === 'Opened'){
            dispatch(onChangeWindowModesMode({modeName: 'Email', mode: 'Hided'}))
        }

        // dispatch(onClearActivitiesPropertiesAndSurfaces())
        // dispatch(clearPropertiesForEmail([]))
    }

    const onCloseModal = () => {
        dispatch(onSetIsActivityModalOpened(false))
        dispatch(onSetIsDescriptionFieldError(false))
        dispatch(onChangeWindowModesMode({modeName: 'Activity', mode: 'Closed'}))
        dispatch(onClearPropContactsCheckedProperties())
        // dispatch(clearPropertiesForEmail([]))
        // dispatch(clearSurfacesForEmail([]))
        // dispatch(onSetAllSurfaceClosed())
        // dispatch(onClearCheckedProperties())
        // dispatch(onClearCheckedSurfaces())
        // dispatch(clearSurfacesForEmail([]))
        dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
        dispatch(onSetPropertiesMessageBody(''))
        // dispatch(onClearActivitiesPropertiesAndSurfaces())
        //
        // dispatch(onSetLocalSortModel({sort_model: [], gridType: currentGrid}))
        // dispatch(onFilterPropertiesGrid({gridFilters: [], gridType: currentGrid}))
        // dispatch(onSetProperties({
        //     properties: defaultAllPropertiesGridColumns,
        //     gridType: currentGrid
        // }))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Type'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Kind'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Description'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Remark'}))
        dispatch(onChangeModalActivityFormField({value: null, inputName: 'Contacts'}))
        dispatch(onChangeModalActivityFormField({value: null, inputName: 'Organizations'}))
    }
    const onCreateNewActivity = () => {
        setIsLoading(true)
        const maxListLength = activitiesProperties.length;
        const link_type_list = []
        const link_entity_list = []
        // let link_ref_list = Array.from({length: maxListLength + 2}, () => null)
        for (let i = 0; i < maxListLength; i++) {
            if (i < activitiesProperties.length) {
                link_type_list.push(0);
            }
            if (i < checkedSurfacesIds.length) {
                link_type_list.push(14);
            }
        }
        for (let i = 0; i < maxListLength; i++) {
            if (i < activitiesProperties.length) {
                link_entity_list.push(activitiesProperties[i].PROP_REF);
            }
            if (i < checkedSurfacesIds.length) {
                link_entity_list.push(checkedSurfacesIds[i]);
            }
        }
        // link_entity_list.unshift(gridRowId)
        // link_type_list.unshift(1)
        activitiesProperties.forEach((p) => {
            link_type_list.unshift(3)
            link_entity_list.unshift(p.PE_REF)
            link_type_list.unshift(2)
            link_entity_list.unshift(p.CO_REF)
        })
        if (contactId && contactId !== null) {
            link_type_list.unshift(3)
            link_entity_list.unshift(contactId)
        } else if (organizationId && organizationId !== null) {
            link_type_list.unshift(2)
            link_entity_list.unshift(organizationId)
        }


        if (modalFormFields.description !== '') {
            dispatch(onSetIsSavingButtonDisabled(true))
            const req: CreateAndUpdateActivityRequest = {
                date_begin: moment(modalFormFields.date_from),
                date_end: moment(modalFormFields.date_till),
                description: modalFormFields.description !== '' ? modalFormFields.description : '',
                link_entity_list: link_entity_list,
                link_ref_list: Array.from({length: link_entity_list.length}, () => null),
                link_type_list: link_type_list,
                priority: modalFormFields.urgency === 'low' ? 0 : modalFormFields.urgency === 'avg' ? 1 : 2,
                remark: modalFormFields.remark !== '' ? modalFormFields.remark : '',
                type: typeNumberEntity,
                type_kind: kindNumberEntity
            }
            const isPropRequired = activitiesTypeKind && activitiesTypeKind.find((a: any) => Number(a.CODE!) === (Number(kindNumberEntity)))
            if (isPropRequired?.PROP_REQUIRED === 0) {
                dispatch(CreateNewActivityThunk({activityData: req, isActivityGrid: false}))
                    .then(() => {
                        setIsLoading(false)
                        dispatch(onSetIsActivityModalOpened(false))
                        dispatch(onClearActivitiesPropertiesAndSurfaces())
                    })
                    .then(() => {
                        /////reset modal fields
                        dispatch(onSetIsActivityModalOpened(false))
                        // dispatch(clearPropertiesForEmail([]))
                        // dispatch(clearSurfacesForEmail([]))
                        // dispatch(onSetAllSurfaceClosed())
                        // dispatch(onClearCheckedProperties())
                        // dispatch(onClearCheckedSurfaces())
                        // dispatch(clearSurfacesForEmail([]))
                        dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
                        // dispatch(onSetPropertiesMessageBody(''))
                        dispatch(onClearActivitiesPropertiesAndSurfaces())
                        //
                        dispatch(onSetLocalSortModel({sort_model: [], gridType: currentGrid}))
                        dispatch(onFilterPropertiesGrid({gridFilters: [], gridType: currentGrid}))
                        dispatch(onSetProperties({
                            properties: defaultAllPropertiesGridColumns,
                            gridType: currentGrid
                        }))
                        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Type'}))
                        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Kind'}))
                        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Description'}))
                        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Remark'}))
                        dispatch(onChangeModalActivityFormField({value: null, inputName: 'Organization'}))
                        dispatch(onChangeModalActivityFormField({value: null, inputName: 'Contact'}))
                        dispatch(onClearActivitiesContacts())
                        // dispatch(clearPropertiesForEmail([]))
                        dispatch(onClearActivitiesFormFields())
                        dispatch(onSetCurrentWindow(null))
                        dispatch(onChangeWindowModesMode({modeName: 'Activity', mode: 'Closed'}))
                    })
            } else if (isPropRequired?.PROP_REQUIRED === 1 && !activitiesProperties.length) {
                message.warn('You need to find and select at least one property in order to create activity with this type', 10)
                setIsLoading(false)
            } else {
                dispatch(CreateNewActivityThunk({activityData: req, isActivityGrid: false}))
                    .then(() => {
                        setIsLoading(false)
                        dispatch(onSetIsActivityModalOpened(false))
                        dispatch(onClearActivitiesPropertiesAndSurfaces())
                    })
                    .then(() => {
                        /////reset modal fields
                        dispatch(onSetIsActivityModalOpened(false))
                        // dispatch(clearPropertiesForEmail([]))
                        // dispatch(clearSurfacesForEmail([]))
                        // dispatch(onSetAllSurfaceClosed())
                        // dispatch(onClearCheckedProperties())
                        // dispatch(onClearCheckedSurfaces())
                        // dispatch(clearSurfacesForEmail([]))
                        dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
                        // dispatch(onSetPropertiesMessageBody(''))
                        // dispatch(onClearActivitiesPropertiesAndSurfaces())
                        //
                        dispatch(onSetLocalSortModel({sort_model: [], gridType: currentGrid}))
                        dispatch(onFilterPropertiesGrid({gridFilters: [], gridType: currentGrid}))
                        dispatch(onSetProperties({
                            properties: defaultAllPropertiesGridColumns,
                            gridType: currentGrid
                        }))
                        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Type'}))
                        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Kind'}))
                        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Description'}))
                        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Remark'}))
                        dispatch(onChangeModalActivityFormField({value: null, inputName: 'Organization'}))
                        dispatch(onChangeModalActivityFormField({value: null, inputName: 'Contact'}))
                        dispatch(onClearActivitiesContacts())
                        // dispatch(clearPropertiesForEmail([]))
                        dispatch(onClearActivitiesFormFields())
                        dispatch(onSetCurrentWindow(null))
                        dispatch(onChangeWindowModesMode({modeName: 'Activity', mode: 'Closed'}))
                    })
            }
        } else {
            dispatch(onSetIsDescriptionFieldError(true))
            dispatch(onSetCurrentModalTab('General'))
            setIsLoading(false)
        }
    }

    const onDeleteCheckedProperties = (p: any) => {
        dispatch(onDeleteCheckedProperty({prop_id: p.PROP_REF!, withoutReq: true}))
        dispatch(onDeleteAllPropertiesCheckedPropertyFromOffer({
            propId: p.PROP_REF!,
            gridType: 'All Properties/Upcoming'
        }))
        dispatch(onAddAllPropertiesCheckedProperties({
            property: p.PROP_REF!,
            isChecked: false,
            contactId: p.PE_REF!,
            gridType: 'All Properties/Upcoming'
        }))
        // dispatch(onDeleteAllPropertiesCheckedPropertyFromOffer({propId: p.PROP_REF!, contactId: p.CO_REF!, gridType: 'All Properties/Upcoming'}))
    }

    const ROW_CHUNK_SIZE = 50;
    const [currentChunk, setCurrentChunk] = useState(0);

    const propertiesDataRowsPaginated = useMemo(() => {
        return rows.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    }, [rows, page, rowsPerPage]);

    // Compute the rows based on the current chunk
    const paginatedData = useMemo(() => {
        const end = currentChunk * ROW_CHUNK_SIZE;
        return propertiesDataRowsPaginated.slice(0, end);
    }, [propertiesDataRowsPaginated, currentChunk]);

    useEffect(() => {
        // Check if the data itself has changed, not just the length
        const isDataDifferent = JSON.stringify(paginatedData) !== JSON.stringify(loadedRows);

        if (isDataDifferent) {
            dispatch(onSetPropContactsLoadedRows(paginatedData));
        }
        // eslint-disable-next-line
    }, [paginatedData]);

    // eslint-disable-next-line
    const handleRowsScrollEnd = useCallback(
        throttle(() => {
            const gridContainer = document.querySelector('.MuiDataGrid-virtualScroller');
            if (gridContainer) {
                const {scrollTop, scrollHeight, clientHeight} = gridContainer;

                // Ensure this logic runs only when really needed
                if (scrollTop + clientHeight >= scrollHeight - 50 && currentChunk * ROW_CHUNK_SIZE < propertiesDataRowsPaginated.length) {
                    setCurrentChunk(prev => prev + 1);
                }
            }
        }, 500), // Increased throttle time to avoid excessive triggers
        [currentChunk, propertiesDataRowsPaginated.length]
    );

    useEffect(() => {
        const gridContainer = document.querySelector('.MuiDataGrid-virtualScroller');
        if (gridContainer) {
            gridContainer.addEventListener('scroll', handleRowsScrollEnd);
        }
        return () => {
            if (gridContainer) {
                gridContainer.removeEventListener('scroll', handleRowsScrollEnd);
            }
        };
    }, [handleRowsScrollEnd]);

    // console.log(isAllPropertiesReqLoading)
    // console.log(allPropertiesColumnsWithReq)



    return (
        <div
            style={{
                padding: !hiddenModal ? '0px 0px 20px 20px' : '0 0 20px 20px',
            }}
        >
            <div className={s.contentInner} style={{marginTop: '20px'}}>
                <div
                    className={s.tableInner}

                >
                    <div style={{
                        paddingLeft: '6px'
                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            {/*<h1 className={s.tableTitle}>Properties</h1>*/}

                                <div className={s.tabButtons} style={{
                                    visibility: currentRoute !== '/contactProperties' ? 'visible' : 'hidden'
                                }}>
                                    {/*<button*/}
                                    {/*    className={currentGrid === 'All Properties/Upcoming' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}*/}
                                    {/*    onClick={() => onChangeCurrentGridType('All Properties/Upcoming')}*/}
                                    {/*>*/}
                                    {/*    Prop Contacts*/}
                                    {/*</button>*/}
                                    <button
                                        className={currentGrid === 'All Properties/REQ' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}
                                        onClick={() => onChangeCurrentGridType('All Properties/REQ')}
                                    >
                                        Brokerage
                                    </button>
                                    <button
                                        className={currentGrid === 'Address Properties' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}
                                        onClick={() => onChangeCurrentGridType('Address Properties')}
                                    >
                                        Addresses
                                    </button>
                                    <button
                                        className={currentGrid === 'Map' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}
                                        onClick={() => onChangeCurrentGridType('Map')}
                                    >
                                        Map
                                    </button>
                                </div>

                            <div style={{
                                display: 'flex',
                            }}>
                                {
                                    currentGrid === 'All Properties/Upcoming'
                                    &&
                                    currentScreenWidth! < 1500
                                    &&
                                    <button
                                        className={s.addActivityBtn}
                                        onClick={onOpenActivityModal}
                                        style={{
                                            marginLeft: '8px',
                                            minWidth: '121px',
                                            marginRight: '0px'
                                        }}
                                        disabled={!loadedRows.length}
                                    >
                                        <img
                                            src={plusImg}
                                            alt={'plus'}
                                            style={{
                                                marginRight: '8px',
                                            }}
                                        />
                                        Activity
                                    </button>

                                }
                                {
                                    currentGrid === 'All Properties/Upcoming'
                                    &&
                                    currentScreenWidth! < 1500
                                    &&
                                    <button
                                        className={s.addActivityBtn}
                                        onClick={onOpenOfferModal}
                                        style={{
                                            marginLeft: '8px',
                                            minWidth: '121px',
                                            marginRight: '0px'
                                        }}
                                        disabled={!loadedRows.length}
                                    >
                                        <img
                                            src={plusImg}
                                            alt={'plus'}
                                            style={{
                                                marginRight: '8px',
                                            }}
                                        />
                                        Email
                                    </button>

                                }
                            </div>
                            <div style={{
                                display: currentGrid === 'All Properties/Upcoming' && currentScreenWidth! < 1500 ? 'none' : 'flex'
                            }}
                            >
                                {
                                    currentGrid !== 'Address Properties' && currentGrid !== 'Map' && currentGrid !== 'All Properties/Upcoming'
                                    &&
                                    <PropertiesHeaderButtons
                                        onSetFiltersShown={handleFiltersShownClick}
                                        onResetConfigs={onResetConfigs}
                                        onSaveGridConfigs={onSaveGridConfigs}
                                        isDisabledSaveBtn={isDisabledSaveBtn}
                                        gridType={currentGrid}
                                        resetFilters={() => {
                                        }}
                                    />
                                }
                                {
                                    currentGrid === 'All Properties/Upcoming' && isSearchInputShown
                                    &&
                                    currentScreenWidth! > 1500
                                    &&
                                    <PropertiesHeaderButtons
                                        onSetFiltersShown={handleFiltersShownClick}
                                        onResetConfigs={onResetConfigs}
                                        onSaveGridConfigs={onSaveGridConfigs}
                                        isDisabledSaveBtn={isDisabledSaveBtn}
                                        gridType={currentGrid}
                                        resetFilters={() => {
                                        }}
                                    />
                                }
                                {
                                    currentGrid === 'All Properties/Upcoming'
                                    &&
                                    currentScreenWidth! > 1500
                                    &&
                                    <button
                                        className={s.addActivityBtn}
                                        onClick={onOpenActivityModal}
                                        style={{
                                            marginLeft: '8px',
                                            minWidth: '121px',
                                            marginRight: '0px'
                                        }}
                                        disabled={!loadedRows.length}
                                    >
                                        <img
                                            src={plusImg}
                                            alt={'plus'}
                                            style={{
                                                marginRight: '8px',
                                            }}
                                        />
                                        Activity
                                    </button>

                                }
                                {
                                    currentGrid === 'All Properties/Upcoming'
                                    &&
                                    currentScreenWidth! > 1500
                                    &&
                                    <button
                                        className={s.addActivityBtn}
                                        onClick={onOpenOfferModal}
                                        style={{
                                            marginLeft: '8px',
                                            minWidth: '121px',
                                        }}
                                        disabled={!loadedRows.length}
                                    >
                                        <img
                                            alt={'plus'}
                                            src={plusImg}
                                            style={{
                                                marginRight: '8px',
                                            }}
                                        />
                                        Email
                                    </button>

                                }
                            </div>
                        </div>
                        {
                            currentGrid === 'All Properties/Upcoming' && isSearchInputShown
                            &&
                            currentScreenWidth! < 1500
                            &&
                            <div style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between',
                                marginBottom: '12px'
                            }}>

                                    {
                                        currentGrid === 'All Properties/Upcoming' && allPropertiesColumnsUpcoming.length && !isAllPropertiesUpcomingLoading
                                            ?
                                            <PropertiesSearchInput
                                                searchTerm={searchTerm}
                                                setSearchTerm={(searchTerm: string) => setSearchTerm(searchTerm)}
                                                handleSearchInputChange={handleSearchInputChange}
                                            />
                                            : null
                                    }

                                <PropertiesHeaderButtons
                                    onSetFiltersShown={handleFiltersShownClick}
                                    onResetConfigs={onResetConfigs}
                                    onSaveGridConfigs={onSaveGridConfigs}
                                    isDisabledSaveBtn={isDisabledSaveBtn}
                                    gridType={currentGrid}
                                    resetFilters={() => {
                                    }}
                                />
                            </div>

                        }
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '12px'
                        }}>
                            {
                                currentGrid === 'All Properties/Upcoming'
                                &&
                                <div
                                    className={s.searchInner}
                                    style={{
                                        marginBottom: isAllPropertiesUpcomingLoading ? '15px' : '0'
                                    }}
                                >
                                    <div className={s.inner__inputBox}>
                                        <Input
                                            onChange={(e) => onAddressChange(e.target.value)}
                                            onPressEnter={onSearchAddress}
                                            className={s.shownSearchInput}
                                            placeholder={'Search by address'}
                                            value={searchedAddress}
                                            prefix={
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    style={{
                                                        marginRight: '8px'
                                                    }}
                                                >
                                                    <path
                                                        d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                                                        stroke="#667085" strokeWidth="1.66667" strokeLinecap="round"
                                                        strokeLinejoin="round"/>
                                                </svg>}
                                        />
                                    </div>

                                    <button style={{
                                        marginRight: '5px'
                                    }} onClick={onSearchAddress}>Search</button>
                                </div>
                            }

                        </div>

                        <div style={{
                            display: 'flex',
                        }}>
                            {
                                currentGrid !== 'Map'
                                &&
                                currentGrid !== 'All Properties/Upcoming'
                                    ?
                                    <PropertiesSearchInput
                                        searchTerm={searchTerm}
                                        setSearchTerm={(searchTerm: string) => setSearchTerm(searchTerm)}
                                        handleSearchInputChange={handleSearchInputChange}
                                    />
                                    : null
                            }

                            {
                                currentGrid === 'Address Properties'
                                    ?
                                    <div style={{
                                        marginLeft: '30px',
                                        marginBottom: '15px',
                                        marginTop: '5px'
                                    }}>
                                        {/* @ts-ignore */}
                                        <DateRangePicker onChange={(value) => onDateChange(value)} ranges={predefinedRanges}
                                                         placeholder="Start date - End date"
                                                         style={{width: 280}}
                                                         defaultCalendarValue={selectedDateRange}
                                                         value={selectedDateRange}
                                        />
                                    </div>
                                    :
                                    <>
                                    </>
                            }
                        </div>
                        {
                            currentGrid !== 'Address Properties' && currentGrid !== 'Map'
                            &&
                            <PropertiesFilterButtons
                                gridType={currentGrid}
                                shownColumns={shownColumns}
                                onResetFilters={onResetFilters}
                                onResetSorting={onResetSorting}
                            />
                        }
                        <div style={{
                            position: 'relative',
                            width: '100%',
                        }}>
                            {shownColumns.map((c: any) => {
                                return (
                                    <PropertyFilterModal
                                        gridType={currentGrid}
                                        textValue={c.field}
                                        currentGridProperties={propertiesDataRows}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    <Box sx={{position: 'relative', width: '100%'}}>
                        <>
                            {
                                currentGrid !== 'Address Properties' && currentGrid !== 'Map'
                                &&
                                <PropertiesViewOptions
                                    checked={checked}
                                    filtersShown={filtersShown}
                                    setChecked={(checkedArray: string[]) => setChecked(checkedArray)}
                                    setFilterShown={(isShown: boolean) => setFiltersShown(isShown)}
                                    gridType={currentGrid}
                                />
                            }

                            {
                                currentGrid === 'Map'
                                    ?
                                    <PropertiesMap/>
                                    :
                                    (
                                        (currentGrid === 'All Properties/REQ' && allPropertiesColumnsWithReq.length > 0 && !isAllPropertiesReqLoading)
                                        ||
                                        (currentGrid === 'All Properties/Upcoming' && allPropertiesColumnsUpcoming.length && !isAllPropertiesUpcomingLoading)
                                        ||
                                        (currentGrid === 'Address Properties' && !isAllPropertiesAddressPropertiesLoading && allPropertiesColumnsForAddressProperties.length > 0)
                                    )
                                        ?
                                        <DataGridPro
                                            rows={currentGrid === 'All Properties/Upcoming' ? propertiesWasNotFound ? [] : loadedRows : loadedRows}
                                            columns={currentGrid !== 'Address Properties' ? shownColumns.filter((col: any) => col?.width !== 0).map((column: any, index: number) => ({
                                                    ...column,
                                                    renderCell: (params: any) =>
                                                        <CustomCell field={column.field}
                                                                    value={params.value}
                                                                    params={params}
                                                                    properties={propertiesTestDataRowsPaginated}
                                                                    key={index}
                                                                    setHiddenModal={setHiddenModal}
                                                                    setOpenedActivity={setOpenedActivity}
                                                                    dataType={column.dataType}

                                                        />,
                                                    renderHeader: (params: any) => {
                                                        return (
                                                            <div style={{
                                                                display: 'flex',
                                                            }}>
                                                        <span
                                                            className={s.columnHeader}>
                                                            {params?.colDef?.headerName === 'ACTIVITY_EXISTED'
                                                                ? 'ACTIVITY'.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())
                                                                : params?.colDef?.headerName?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())}
                                                        </span>
                                                            </div>
                                                        );
                                                    },
                                                })) :
                                                // gridColumns
                                                addressPropertiesGridColumns.sort((a: any, b: any) => a.col_pos - b.col_pos).map((column: any, index: number) => ({
                                                    ...column,
                                                    renderCell: (params: any) =>
                                                        <CustomCell field={column.field}
                                                                    value={params.value}
                                                                    params={params}
                                                                    properties={propertiesTestDataRowsPaginated}
                                                                    key={index}
                                                                    dataType={column.dataType}

                                                        />,
                                                }))
                                            }
                                            apiRef={apiRef}
                                            autoHeight={true}
                                            getRowId={e => e.PROP_ID || e.id}
                                            sortingOrder={['desc', 'asc']}
                                            sortModel={Object.values(localSortModel)}
                                            onSortModelChange={handleSortModelChange}
                                            disableColumnSelector={true}
                                            // onRowDoubleClick={onRowDoubleClick}
                                            onStateChange={handleStateChange}
                                            rowCount={propertiesDataRowsPaginated.length}
                                            onRowsScrollEnd={handleRowsScrollEnd}
                                            onCellDoubleClick={onRowDoubleClick}
                                            rowHeight={25}
                                            sx={{
                                                borderRight: 'none',
                                                borderLeft: 'none',
                                                borderBottom: 'none',
                                                borderRadius: '0px',
                                                // minHeight: '600px',
                                                // height: height > 950 ? `${gridContentHeight}px !important` : height > 900 ? `${gridContentHeight - 150}px !important` : `${gridContentHeight + 150}px !important`,
                                                "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                                    opacity: 0
                                                },
                                                "& .MuiDataGrid-cellContent": {
                                                    fontFamily: 'Inter, sans-serif',
                                                    fontWeight: 500,
                                                    fontSize: '14px',
                                                    lineHeight: '20px',
                                                    color: '#111827'
                                                },
                                                "& .MuiDataGrid-columnHeaderTitle": {
                                                    fontFamily: 'Inter, sans-serif',
                                                    fontWeight: 600,
                                                    color: '#6B7280',
                                                    fontSize: '12px',
                                                    lineHeight: '20px',
                                                },
                                                "& .MuiDataGrid-row:hover": {
                                                    backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                                },
                                                "& .MuiDataGrid-row.Mui-selected": {
                                                    backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                                },
                                                "& .MuiDataGrid-row.Mui-selected:hover": {
                                                    backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                                },
                                                "& .MuiDataGrid-cell:focus": {
                                                    outline: 'none'
                                                },
                                                "& .MuiDataGrid-toolbarContainer": {
                                                    color: '#454545',
                                                    position: 'absolute',
                                                    top: '-50px',
                                                    right: '0px'
                                                },
                                                "& .MuiTablePagination-displayedRows": {
                                                    margin: '0',
                                                    fontFamily: 'Inter, sans-serif !important',
                                                    fontWeight: 400,
                                                    color: '#6B7280',
                                                    fontSize: '12px',
                                                    lineHeight: '20px',
                                                },
                                                "& .MuiPagination-root": {
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    padding: '15px 0px'
                                                },
                                                "& .MuiButtonBase-root.Mui-selected": {
                                                    backgroundColor: '#eff1f3',
                                                    borderRadius: '0px'
                                                },
                                                "& .MuiButtonBase-root.MuiPaginationItem-root": {
                                                    width: '40px',
                                                    height: '40px',
                                                    margin: 0,
                                                    borderRight: '1px solid #D0D5DD',
                                                    borderLeft: '1px solid #D0D5DD',
                                                    borderTop: 'none',
                                                    borderBottom: 'none',
                                                    fontFamily: 'Inter, sans-serif !important',
                                                    fontWeight: 500,
                                                    color: '#1D2939',
                                                    fontSize: '14px',
                                                    lineHeight: '20px',
                                                    borderRadius: '0px'
                                                },
                                                "& .MuiPagination-ul": {
                                                    border: '1px solid #D0D5DD',
                                                    borderRadius: '8px'
                                                },
                                                "& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext": {
                                                    borderRadius: '0px',
                                                    border: 'none'
                                                },
                                                "& .MuiDataGrid-columnHeaders": {
                                                    backgroundColor: '#FCFDFE'
                                                },
                                                "& .MuiDivider-root": {
                                                    display: 'none',
                                                    height: '0',
                                                    width: '0',
                                                    opacity: '0 !important',
                                                    borderColor: '#fff '
                                                },
                                                "& .MuiBox-root": {
                                                    height: 'auto'
                                                },
                                                "& .MuiDataGrid-cell": {
                                                    borderBottom: 'none'
                                                },
                                                "& .MuiDataGrid-virtualScroller": {
                                                    overflowY: 'scroll !important',
                                                    overflowX: 'scroll',
                                                    height: currentGrid === 'Address Properties'
                                                        ? `${gridContentHeight + 40}px !important`

                                                        : `${gridContentHeight - 10}px !important`,
                                                    minHeight: 'auto',
                                                },
                                                "& .MuiDataGrid-virtualScrollerContent": {},
                                            }}
                                            components={{
                                                Footer: () =>
                                                    // <PropertiesGridFooter
                                                    //     rows={rows}
                                                    //     rowsPerPageOptions={rowsPerPageOptions}
                                                    //     handlePageChange={handlePageChange}
                                                    //     handleRowsPerPageChange={handleRowsPerPageChange}
                                                    //     rowsPerPage={rowsPerPage}
                                                    //     page={page}
                                                    // />,
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: currentScreenWidth! > 768 ? 'space-between' : 'center',
                                                        alignItems: 'center',
                                                        width: '100%'
                                                    }}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: currentScreenWidth! > 768 ? 'space-between' : 'center',
                                                                alignItems: 'center',

                                                            }}>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: currentScreenWidth! > 768 ? 'space-between' : 'center',
                                                                    alignItems: 'center',
                                                                }}>
                                                                {
                                                                    currentScreenWidth! > 768
                                                                    &&
                                                                    <>
                                                                        <p
                                                                            className={s.footerText}
                                                                        >
                                                                            Rows per page:
                                                                        </p>
                                                                        <Select
                                                                            value={rowsPerPage}
                                                                            onChange={handleRowsPerPageChange}
                                                                            className={s.rowsPerPage}
                                                                        >
                                                                            {rowsPerPageOptions.map((option) => (
                                                                                <Select.Option key={option}
                                                                                               value={option}>
                                                                                    {option}
                                                                                </Select.Option>
                                                                            ))}
                                                                        </Select>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <Pagination
                                                            // count={Math.ceil(sortedRows.length / rowsPerPage)}
                                                            count={propertiesWasNotFound ? 0 : Math.ceil(sortedRows.length / rowsPerPage)}
                                                            // count={Math.ceil(gridFilters.length ? (rows.length / rowsPerPage) : (defaultAuditGridColumns.length / rowsPerPage))}
                                                            page={page}
                                                            onChange={handlePageChange}
                                                            variant="outlined"
                                                            shape="rounded"
                                                        />
                                                        {
                                                            currentScreenWidth! > 768
                                                            &&
                                                            // <p className={s.footerText}>
                                                            //     Total rows: {sortedRows.length}
                                                            // </p>
                                                            <p className={s.footerText}>
                                                                {
                                                                    propertiesWasNotFound
                                                                        ?
                                                                        'Total: 0'
                                                                        :
                                                                        <span>
                                                                            Total{gridFilters.length ? '/Filtered' : ''} properties: {gridFilters.length ? defaultAllPropertiesGridColumns.length : rows.length}{gridFilters.length ? `/${rows.length}` : ''}
                                                                        </span>
                                                                }
                                                            </p>
                                                        }
                                                    </div>,
                                                ColumnMenu: (props) => {
                                                    return (
                                                        <GridColumnMenu
                                                            hideMenu={() => {
                                                            }}
                                                            currentColumn={
                                                                {
                                                                    field: props.currentColumn.field,
                                                                    filterable: false,
                                                                    hideable: false,
                                                                }
                                                            }
                                                            open={true}
                                                        />
                                                    )
                                                },
                                                Row: CustomGridRow,
                                            }}
                                            componentsProps={{
                                                row: {
                                                    setHiddenModal,
                                                    setOpenedActivity
                                                },
                                            }}
                                        />
                                        :
                                        (
                                            (currentGrid === 'All Properties/REQ' && !allPropertiesColumnsWithReq.length && !isAllPropertiesReqLoading)
                                            ||
                                            (currentGrid === 'Address Properties' && !isAllPropertiesAddressPropertiesLoading && !allPropertiesColumnsForAddressProperties.length)
                                            ||
                                            (currentGrid === 'All Properties/Upcoming' && !allPropertiesColumnsUpcoming.length && !isAllPropertiesUpcomingLoading)
                                        )
                                            ?
                                            <div style={{
                                                height: gridContentHeight + 115,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>

                                                <Empty/>


                                            </div>
                                            :
                                            <div style={{
                                                height: gridContentHeight + 115,
                                                width: '400px',
                                                margin: '0 auto',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center'
                                            }}>
                                                <div style={{
                                                    height: '300px',
                                                    width: '100%',
                                                    textAlign: 'center'
                                                }}>
                                                    {/* @ts-ignore */}
                                                    <Lottie config={{animationData: animatedLoader, loop: true, height: '60px !important'
                                                    }}/>
                                                </div>

                                                <p style={{
                                                    textAlign: 'center'
                                                }}>{gridLoaderMessage}</p>
                                                {
                                                    currentGrid === 'All Properties/Upcoming' && isAllPropertiesUpcomingLoading
                                                    &&
                                                    <button className={s.cancelRequest}
                                                            onClick={onCancelRequest}>Cancel</button>
                                                }
                                            </div>
                            }
                        </>
                    </Box>
                </div>
                <PropertiesOfferModal
                    height={height}
                    hiddenModal={false}
                    gridContentHeight={gridContentHeight}
                    onHideModal={onHideModal}
                    unHideModal={unHideModal}
                    divRef={divRef}
                    gridType={currentGrid}
                    contactLang={contactLang}
                    rowReqId={currentReqId}
                    isModalOpened={emailButtonMode?.mode === 'Opened'}
                    isModalHided={emailButtonMode?.mode === 'Hided'}
                />
                {
                    propertyActivities.length
                        ?
                        <PropertiesActivityModal
                            openedActivity={openedActivity}
                            height={height}
                            gridContentHeight={gridContentHeight}
                            currentPropId={currentPropId}
                            onCloseActivityModal={onCloseActivityModal}
                        />
                        :
                        null
                }
                {
                    activityButtonMode?.mode === 'Opened'
                    ?
                    <div style={{marginLeft: '10px'}}>
                        <CommonActivityModal modalTitle={`Activity`}
                                             gridType={"Contact Properties"} height={gridContentHeight + 145}
                                             onCloseModal={onCloseModal}
                                             width={'650px'}
                                             onHideModal={onHideActivityModal}
                        >
                            <div>
                                <div className={s.tabButtons__activitiesModal}>
                                    <button
                                        onClick={() => dispatch(onSetCurrentModalTab('General'))}
                                        className={currentTab === 'General' ? `${s.tabButtons__activitiesModalButton} ${s.tabButtons__activitiesModalButton_active}` : s.tabButtons__activitiesModalButton}
                                    >
                                        General
                                    </button>

                                    <button
                                        onClick={() => dispatch(onSetCurrentModalTab('Existing Links'))}
                                        className={currentTab === 'Existing Links' ? `${s.tabButtons__activitiesModalButton} ${s.tabButtons__activitiesModalButton_active}` : s.tabButtons__activitiesModalButton}
                                    >
                                        Existing links
                                    </button>
                                    {/*{*/}
                                    {/*    currentRowValues.WE_TYPE_POSITION !== null*/}
                                    {/*    &&*/}
                                    {/*    <p className={s.badge}>{currentRowValues.WE_TYPE_POSITION}</p>*/}
                                    {/*}*/}

                                </div>
                                {
                                    currentTab === 'General'
                                        ?
                                        <ActivitiesGridForm withButtons={false} maxWidthInputs={true} editMode={false}
                                                            disabledInputs={false}/>
                                        :
                                        activitiesProperties.length
                                            ?
                                            <div>
                                                <>
                                                    {activitiesProperties.length
                                                        ?
                                                        <div className={propertiesStyles.messageInner__properties}>
                                                            <p>Properties:</p>
                                                            <div
                                                                className={propertiesStyles.messageInner__propertiesInner}>
                                                                {activitiesProperties.map((p: any) => {
                                                                    return (
                                                                        <div
                                                                            className={propertiesStyles.messageInner__property}
                                                                            key={p.PROP_REF}>


                                                                            <span>{p.PROP_REF} {p.PROP_ADDR_STREET_ADDRESS ? '-' : null} {p.PROP_ADDR_STREET_ADDRESS} {p.PROP_ADDR_ZIP ? ',' : null} {p.PROP_ADDR_ZIP} {p.PROP_ADDR_CITY ? ',' : null} {p.PROP_ADDR_CITY}</span>
                                                                            <button
                                                                                onClick={() => onDeleteCheckedProperties(p)}
                                                                                type={'button'}
                                                                                // disabled={currentRowValues?.ACT_TYPE === 'Documentation'}
                                                                                // style={{
                                                                                //     cursor: currentRowValues?.ACT_TYPE === 'Documentation' ? 'not-allowed' : 'pointer'
                                                                                // }}
                                                                            >
                                                                                <svg width="12" height="12"
                                                                                     viewBox="0 0 12 12"
                                                                                     fill="none"
                                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                                    <g clipPath="url(#clip0_1387_36685)">
                                                                                        <path
                                                                                            d="M6.46967 5.99974L9.233 3.2364C9.28761 3.17264 9.31614 3.09061 9.3129 3.00672C9.30966 2.92283 9.27488 2.84325 9.21552 2.78388C9.15616 2.72452 9.07658 2.68974 8.99268 2.6865C8.90879 2.68326 8.82677 2.71179 8.763 2.7664L5.99967 5.52974L3.23633 2.76307C3.17356 2.7003 3.08843 2.66504 2.99967 2.66504C2.9109 2.66504 2.82577 2.7003 2.763 2.76307C2.70023 2.82584 2.66497 2.91097 2.66497 2.99974C2.66497 3.0885 2.70023 3.17364 2.763 3.2364L5.52967 5.99974L2.763 8.76307C2.7281 8.79295 2.69976 8.82972 2.67976 8.87108C2.65975 8.91243 2.64851 8.95748 2.64673 9.00338C2.64496 9.04929 2.65269 9.09507 2.66945 9.13784C2.68621 9.18062 2.71163 9.21947 2.74412 9.25195C2.7766 9.28444 2.81545 9.30986 2.85822 9.32661C2.901 9.34337 2.94678 9.35111 2.99268 9.34933C3.03859 9.34756 3.08363 9.33632 3.12499 9.31631C3.16634 9.2963 3.20312 9.26796 3.233 9.23307L5.99967 6.46974L8.763 9.23307C8.82677 9.28768 8.90879 9.31621 8.99268 9.31297C9.07658 9.30973 9.15616 9.27496 9.21552 9.21559C9.27488 9.15623 9.30966 9.07665 9.3129 8.99275C9.31614 8.90886 9.28761 8.82684 9.233 8.76307L6.46967 5.99974Z"
                                                                                            fill={'rgba(0, 0, 0, 0.54)'}/>
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_1387_36685">
                                                                                            <rect width="12" height="12"
                                                                                                  fill="white"/>
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>

                                                                            </button>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        :
                                                        null}
                                                </>
                                                <div className={propertiesStyles.messageInner__properties}>
                                                    <p>Contacts:</p>
                                                    {
                                                        activitiesProperties.length
                                                            ?
                                                            activitiesProperties.map((p) => {
                                                                return (

                                                                    `${p.PE_FIRST_NAME} ${p.PE_LAST_NAME}` !== null && `${p.PE_FIRST_NAME} ${p.PE_LAST_NAME}` !== '' && `${p.PE_FIRST_NAME} ${p.PE_LAST_NAME}` !== ' '
                                                                        ?
                                                                        <div
                                                                            className={propertiesStyles.messageInner__property}>

                                                                            <p>{p.PROP_REF} - {p.PE_FIRST_NAME} {p.PE_LAST_NAME}</p>
                                                                        </div>
                                                                        :
                                                                        null


                                                                )
                                                            })
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className={propertiesStyles.messageInner__properties}>
                                                    <p>Organisations:</p>
                                                    {
                                                        activitiesProperties.length
                                                            ?
                                                            activitiesProperties.map((p) => {
                                                                return (
                                                                    p.CO_NAME !== null && p.CO_NAME !== '' && p.CO_NAME !== ' '
                                                                        ?
                                                                        <div
                                                                            className={propertiesStyles.messageInner__property}>

                                                                            <p>{p.PROP_REF} - {p.CO_NAME}</p>
                                                                        </div>
                                                                        :
                                                                        null
                                                                )
                                                            })
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div className={s.reqGridText}>
                                                Add the Properties to the activity.
                                            </div>
                                }
                            </div>
                            {
                                modalFormFields.type === ''
                                || modalFormFields.type === null
                                || modalFormFields.kind === ''
                                || modalFormFields.kind === null
                                    ?
                                    <Tooltip
                                        text={
                                            modalFormFields.type === ''
                                            || modalFormFields.type === null
                                            || modalFormFields.kind === ''
                                            || modalFormFields.kind === null
                                                ? `Please select the domain , activity type and activity kind in order to save changes.`
                                                : `Please change your activity kind to the one that is correct with the system kinds`}
                                        classname={'addressButtonsTooltip'}>
                                        <Button
                                            type='primary'
                                            htmlType='submit'
                                            style={{
                                                height: '44px',
                                                backgroundColor: 'rgba(7, 7, 250, 0.26)',
                                                color: '#fff',
                                                width: '100%',
                                                border: '1px solid transparent',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginTop: '10px'

                                            }}
                                            onClick={onCreateNewActivity}
                                            disabled={
                                                modalFormFields.type === ''
                                                || modalFormFields.type === null
                                                || modalFormFields.kind === ''
                                                || modalFormFields.kind === null
                                            }
                                            loading={loading}
                                        >
                                            Add
                                        </Button>
                                    </Tooltip>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        style={{
                                            height: '40px',
                                            backgroundColor: '#0707FA',
                                            border: '1px solid #0707FA',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: '10px'

                                        }}
                                        onClick={onCreateNewActivity}
                                        onDoubleClick={() => setIsLoading(true)}
                                        loading={loading}
                                    >
                                        Add
                                    </Button>

                            }

                        </CommonActivityModal>
                    </div>
                        :
                        activityButtonMode?.mode === 'Hided'
                    ?
                            <div
                                className={s.messageInner__header}
                                style={{
                                    padding: '12px 12px 11px 16px',
                                    borderRadius: '8px 8px 0 0',
                                    height: '50px',
                                    position: 'fixed',
                                    bottom: '0',
                                    width: '250px',
                                    right: '325px',
                                    zIndex: 9999
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: '14px'
                                    }}
                                >New activity</p>
                                <div className={s.messageInner__buttons}>
                                    <button
                                        type={'button'}
                                        onClick={unHideActivityModal}
                                    >

                                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10 4.44444V0H5.55556L7.38333 1.82778L1.82778 7.38333L0 5.55556V10H4.44444L2.61667 8.17222L8.17222 2.61667L10 4.44444Z"
                                                fill="white" fillOpacity="0.6"/>
                                        </svg>
                                    </button>
                                    <button
                                        type={'button'}
                                        onClick={onCloseActivityModal}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_1387_34908)">
                                                <path
                                                    d="M4.66667 3L3 4.66667L6.33333 8L3 11.3333L4.66667 13L8 9.66667L11.3333 13L13 11.3333L9.66667 8L13 4.66667L11.3333 3L8 6.33333L4.66667 3Z"
                                                    fill="white" fillOpacity="0.6"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1387_34908">
                                                    <rect width="10" height="10" fill="white"
                                                          transform="matrix(-1 0 0 -1 13 13)"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            :
                            null
                }
            </div>
            {
                isAddressModalOpened
                &&
                <AllPropertiesAddressForm/>
            }
            {/*{*/}
            {/*    isCreateModalOpened*/}
            {/*    &&*/}
            {/*    <CreateContactModal reqId={gridRowId}/>*/}
            {/*}*/}
        </div>

    );
};


const CustomCell = React.memo(({
                                   field,
                                   value,
                                   params,
                                   properties,
                                   setHiddenModal,
                                   setOpenedActivity,
                                   dataType
                               }: any) => {
    const dispatch = useAppDispatch()
    const checkedProperties = useAppSelector(selectAllPropertiesGridCheckedProperties)
    const currentGrid = useAppSelector(selectAllPropertiesCurrentGrid)
    const contactLang = useAppSelector(selectPropertyContactLang)
    const currentWindowMode = useAppSelector(selectPropContactsCurrentWindowMode)
    const propContactsWindowModes = useAppSelector(selectPropContactsWindowModes)
    const checkedActivitiesProperties = useAppSelector(selectCurrentActivitiesCheckedProperties)
    // const onGetPropertiesREQ = (value: string) => {
    //     if (!currentProp.reqs.length) {
    //         dispatch(GetAllPropertiesGridCurrentPropertyReq(value))
    //     } else {
    //         dispatch(onSetIsReqOpened({isOpened: !currentProp.isOpened, propId: value}))
    //     }
    // }
    const onEditAddress = () => {
        dispatch(onChangeIsAddressModalOpened(true))
        dispatch(onSetCurrentAddressPropertyData(params.row))
    }

    // @ts-ignore
    const getTextWidth = (text, font) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        // @ts-ignore
        context.font = font; // Example: "14px Roboto"
        // @ts-ignore
        const metrics = context.measureText(text);
        return metrics.width;
    };
    const onSetRemarksValue = (value: any) => {
        const textValue = value || '';
        const textWidth = getTextWidth(textValue, '14px Roboto');
        dispatch(onSetFiltersButtonsModalOpened({
            headerName: field,
            isModalOpened: true,
            gridType: 'All Properties/Upcoming'
        }))
        dispatch(onChangeColumnForFilterValue({
            title: field,
            value: value,
            gridType: 'All Properties/Upcoming'
        }))
        dispatch(onSetFilteredInputDynamicWidth(textWidth))
        dispatch(onSetPropContactsRemarkEditMode(true))
        dispatch(onSetPropContactsCurrentRowPropId(params.row.PE_REF))
    }
    // const debouncedValueChange = _.debounce((newValue: string) => {
    //     // console.log(newValue)
    //     // console.log(params.row)
    //     clearTimeout(typingTimeout);
    //     typingTimeout = setTimeout(() => {
    //         dispatch(onSetIsCompaniesLoading(true))
    //         dispatch(GetAllCompaniesThunk(value))
    //         dispatch(onClearCompanies())
    //         dispatch(onClearActivitiesContacts())
    //         dispatch(onChangeModalActivityFormField({value: '', inputName: 'Contacts'}))
    //     }, 2000);
    //
    // }, debounceTime);

    if (field === 'ACTIVITY_EXISTED') {
        return <div style={{display: 'flex', alignItems: 'center'}}>{value === 0 ? <>
            <RedDot style={{marginRight: '6px'}}/>No</> : value === 1 ? <><GreenDot
            style={{marginRight: '6px'}}/>Yes</> : ''}</div>;
    }
    if (field === 'HAS_BALCONY' || field === 'HAS_PARKING' || field === 'HAS_LIFT' || field === 'FREE_LAND') {
        return <div style={{display: 'flex', alignItems: 'center'}}>{value === 'No' ? <>
            <RedDot style={{marginRight: '6px'}}/>No</> : value === 'Yes' ? <><GreenDot
            style={{marginRight: '6px'}}/>Yes</> : ''}</div>;
    }
    if (field === 'PROP_ID' || field === 'PROP_REF') {
        const handleCheckProperties = (value: any) => {
            dispatch(onClearPropertyActivities())
            setHiddenModal(false)
            setOpenedActivity(false)
            if (value.target.checked === true) {

                // if (checkedProperties.length = 1) {
                //     dispatch(onChangeModalActivityFormField({value: '', inputName: 'Organizations'}))
                //     dispatch(onChangeModalActivityFormField({value: '', inputName: 'Contacts'}))
                //     dispatch(onClearActivitiesContacts())
                // }
                if(currentWindowMode === 'Email'){
                    if (params.row.PE_EMAIL) {
                        dispatch(onAddOwnerContact({
                            label: `${params.row.PE_EMAIL} ${params.row.PE_NAME!!}`,
                            labelEmail: params.row.PE_EMAIL,
                            labelName: params.row.PE_NAME!!,
                            value: params.row.PE_EMAIL
                        }))
                    }

                    dispatch(onAddAllPropertiesCheckedProperties({
                        property: value.target.name,
                        isChecked: value.target.checked,
                        contactId: params.row.PE_REF,
                        contactName: `${params.row.PE_FIRST_NAME} ${params.row.PE_LAST_NAME}`,
                        orgId: params.row.CO_REF,
                        orgName: params.row.CO_NAME,
                        gridType: currentGrid
                    }))
                    dispatch(setAllPropertiesGridPropertiesForEmail({
                        propertyId: params.row.PROP_REF,
                        // propertyTitle: a.TITLE_EN,
                        propertyAddress: `${params.row.PROP_REF} - ${params.row.PROP_ADDR_STREET_ADDRESS}, ${params.row.PROP_ADDR_CITY}`,
                        description: params.row.PROP_DESCRIPTION,
                        gridType: 'All Properties/Upcoming',
                        contactId: params.row.PE_REF,
                    }))
                    dispatch(GetPropertiesEmailBody({
                        req_id: Number(value.target.value),
                        gridType: 'All Properties/Upcoming',
                        lang: contactLang
                    }))
                }
               else if(currentWindowMode === 'Activity'){
                    dispatch(onCheckActivitiesProperties({
                        property: params.row,
                        isChecked: value.target.checked,
                        gridType: 'All Properties/Upcoming',
                        contactId: params.row.PE_REF,
                        contactName: `${params.row.PE_FIRST_NAME} ${params.row.PE_LAST_NAME}`,
                        orgId: params.row.CO_REF,
                        orgName: params.row.CO_NAME,
                    }))
                    dispatch(onSetActivitiesContactRows({row: params.row, isChecked: true}))
                }
                // dispatch(onCheckActivitiesPropertiesIds({property: value.target.name, isChecked: value.target.checked}))

            } else {
                if(currentWindowMode === 'Email'){
                    if (params.row.PE_EMAIL) {
                        dispatch(onDeleteOwnerContact({
                            label: `${params.row.PE_EMAIL} ${params.row.PE_NAME!!}`,
                            labelEmail: params.row.PE_EMAIL,
                            labelName: params.row.PE_NAME!!,
                            value: params.row.PE_EMAIL
                        }))
                    }
                    dispatch(onAddAllPropertiesCheckedProperties({
                        property: value.target.name,
                        isChecked: value.target.checked,
                        contactId: params.row.PE_REF,
                        gridType: currentGrid
                    }))
                    dispatch(deleteAllPropertiesPropertyEmail({
                        propId: Number(value.target.name),
                        contactId: params.row.PE_REF,
                        gridType: 'All Properties/Upcoming'
                    }))
                }
                else if(currentWindowMode === 'Activity'){
                    dispatch(onSetActivitiesContactRows({row: params.row, isChecked: false}))
                    if (checkedActivitiesProperties.length === 1) {
                        dispatch(onChangeModalActivityFormField({value: null, inputName: 'Organizations'}))
                        dispatch(onChangeModalActivityFormField({value: null, inputName: 'Contacts'}))
                        dispatch(onClearActivitiesContacts())
                    }

                    // dispatch(onCheckActivitiesPropertiesIds({property: value.target.name, isChecked: value.target.checked}))
                    dispatch(onCheckActivitiesProperties({
                        property: params.row,
                        isChecked: value.target.checked,
                        gridType: 'All Properties/Upcoming',
                        contactId: params.row.PE_REF,
                        contactName: `${params.row.PE_FIRST_NAME} ${params.row.PE_LAST_NAME}`,
                        orgId: params.row.CO_REF,
                        orgName: params.row.CO_NAME,
                    }))
                }

            }
            // setTimeout(() => {
            //     setDisabledCheckbox(false)
            // }, 50)
        }
        const activityButtonIsOpened = propContactsWindowModes?.find((m) => m.modeName === 'Activity')?.mode === 'Opened'
        const emailButtonIsOpened = propContactsWindowModes?.find((m) => m.modeName === 'Email')?.mode === 'Opened'

        return (
            <div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                }}>
                    <div>
                        {
                            currentGrid === 'All Properties/Upcoming' && propContactsWindowModes.some((m) => m.mode === 'Opened')
                                ?
                                <Checkbox
                                    onChange={handleCheckProperties}
                                    name={value}
                                    checked={
                                        (currentWindowMode === "Activity" && activityButtonIsOpened)
                                            ?
                                            checkedActivitiesProperties.some((property: any) => Number(property.PROP_REF) === Number(value) && Number(property.PE_REF) === Number(params.row.PE_REF))
                                            :
                                            (currentWindowMode === "Email" && emailButtonIsOpened)
                                                ?
                                                checkedProperties.some((property: any) => property.propId === value && property.contactId === params.row.PE_REF)
                                                : false
                                    }
                                    // checked={checkedProperties.some(
                                    //     (property: any) => property.propId === value && property.contactId === params.row.PE_REF
                                    // )}
                                    // disabled={disabledCheckbox || (isCreateActivityModalOpened && checkedProperties.some(
                                    //     (property: any) => property.propId === value && property.contactId !== params.row.PE_REF
                                    // ))}
                                    style={{
                                        marginRight: '8px'
                                    }}
                                />
                                :
                                null
                        }
                        <span>{value}</span>
                    </div>
                    {/*{*/}
                    {/*    currentGrid === 'All Properties/REQ'*/}
                    {/*    &&*/}
                    {/*    <>*/}
                    {/*        <button*/}
                    {/*            onClick={() => onGetPropertiesREQ(value)}*/}
                    {/*            className={s.surfaceBtn}*/}
                    {/*        >*/}
                    {/*            REQ*/}
                    {/*            <img src={surfaceArrow} alt="arrow-down"/>*/}
                    {/*        </button>*/}
                    {/*    </>*/}
                    {/*}*/}

                </div>
            </div>

        )
    } else if (field === 'MAP_PIN') {
        return (
            <div className={s.editAddress} onClick={onEditAddress}>Edit</div>
        )
    } else if (field === 'EMPTY') {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <AddressWarningIcon style={{
                    width: '20px',
                    height: '20px'
                }}/>
            </div>
        )
    } else if (field === 'PE_REMARKS') {

        return (
            <div onClick={() => onSetRemarksValue(value)}>{value ? value : '-'}</div>
            // <TextField
            //     // value={contactRemark}
            //     onChange={(e) => onRemarkChange(e.target.value)}
            //     variant="outlined"
            //     fullWidth
            // />
        )
    } else {
        if (dataType === 'class java.math.BigDecimal' && currentGrid !== 'Address Properties' && field !== 'PE_REF' && field !== 'CO_REF' && field !== 'PROP_ADDR_NF_NO_FROM' && field !== 'PROP_ADDR_NF_NO_TO') {
            const europeanString = value !== null ? value?.toLocaleString('de-DE') : '';
            return <div>{europeanString}</div>;
        } else {
            return <div>{value}</div>;
        }

    }


});


const CustomGridRow = React.memo((props: any) => {
    return (
        <div className={s.customRow}>
            <GridRow {...props} />
        </div>
    );
});


export default AllProperties;