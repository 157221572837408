import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AsyncThunkConfig, RootState} from "./store";
import {AppStatusType, setAppStatus} from "./appStatusReducer";
import {companiesApi} from "../app/api";
import {onChangeModalActivityFormField} from "./activitesGrid";

interface InitialStateType {
    companies: any[]
    isCompaniesLoading: boolean
    company: any
    companyWasNotFound: boolean
}

const initialState: InitialStateType = {
    companies: [],
    isCompaniesLoading: false,
    company: [],
    companyWasNotFound: false
}


export const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        onSetIsCompaniesLoading: (state, action: PayloadAction<boolean>) => {
            state.isCompaniesLoading = action.payload
        },
        onClearCompanies: (state) => {
            state.companies = []
            state.company = []
        },
        onSetCompanyWasNotFound: (state, action: PayloadAction<boolean>) => {
            state.companyWasNotFound = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAllCompaniesThunk.fulfilled, (state, action) => {
                state.companies = action.payload
            })
            .addCase(GetCompanyByRefThunk.fulfilled, (state, action) => {
                state.company = [...state.company, action.payload[0]?.NAME]
            })
            .addCase(GetCompanyByReqRefThunk.fulfilled, (state, action) => {
                state.company = [...state.company, action.payload[0]?.NAME]
            })
    }
})

export const {onSetIsCompaniesLoading, onClearCompanies, onSetCompanyWasNotFound} = companiesSlice.actions

export const selectAllCompanies = (state: RootState): any[] => state.companies.companies
export const selectIsCompaniesLoading = (state: RootState): boolean => state.companies.isCompaniesLoading
export const selectCompany = (state: RootState): string[] => state.companies.company
export const selectCompanyWasNotFound = (state: RootState): boolean => state.companies.companyWasNotFound

export const GetAllCompaniesThunk = createAsyncThunk<any, string, AsyncThunkConfig>(
    'companies/getAllCompanies',
    async (name, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
        thunkAPI.dispatch(onSetCompanyWasNotFound(false))
        try {
            const {status, data} = await companiesApi.getAllCompanies(name)
            if (status === 200 && data) {
                if(data.resultSetList.length === 0){
                    thunkAPI.dispatch(onSetCompanyWasNotFound(true))
                }
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
            thunkAPI.dispatch(onSetIsCompaniesLoading(false))
        }
    }
)

export const GetCompanyByRefThunk = createAsyncThunk<any, number, AsyncThunkConfig>(
    'companies/getCompanyByRef',
    async (ref, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
        try {
            const {status, data} = await companiesApi.getCompanyByRef(ref)
            if (status === 200 && data) {
                console.log(data.resultSetList)
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                thunkAPI.dispatch(onChangeModalActivityFormField({value: data.resultSetList[0].NAME, inputName: 'Organizations'}))
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(setAppStatus(AppStatusType.idle))

        }
    }
)

export const GetCompanyByReqRefThunk = createAsyncThunk<any, number, AsyncThunkConfig>(
    'companies/getCompanyByReqRef',
    async (req_ref, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
        try {
            const {status, data} = await companiesApi.getCompaniesByReqRef(req_ref)
            if (status === 200 && data) {
                console.log(data)
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                thunkAPI.dispatch(onChangeModalActivityFormField({value: data.resultSetList[0].NAME, inputName: 'Organizations'}))
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(setAppStatus(AppStatusType.idle))

        }
    }
)

export default companiesSlice.reducer