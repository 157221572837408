import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    AuditGridItem, EmailTemplateTypes,
    EmailUpdate, faqsApi,
    gridApi,
    parametersAPI,
    requirementsApi,
    templatesApi
} from '../app/api'
import {
    ActivityCodesDataType, ActivityCodesGridColumn,
    DisclaimerType,
    GetActivityCodesReqData,
    MarketingReportType,
    RequirementType, UpdateActivityCodesReqData
} from '../types/parametersTypes'
import {AppStatusType, setAppStatus} from './appStatusReducer'
import {AsyncThunkConfig, RootState} from './store'
import {onSetPropertiesGridLoaderMessage, ShownAndHiddenColumnsType} from "./propertiesReducer";
import moment, {Moment} from "moment";
import {GridColumns, GridsColumnFilters, GridsColumnsForFilters} from "../types/commonTypes";


export interface ActivityCodeRow {
    ACT_CODE: string
    ACT_DESCRIPTION: string
    DOMAIN_CD: string
    ENUM_INDEX: number
    ORDER_NUM: number
    POSITION: string | null
    PROP_REQUIRED: number
    SEND_SURVEYS: number
    id: number
}

export interface FaqsType  {
    answer: string,
    category: string,
    dateCreated: string,
    id: number,
    question: string,
    section: string
}

interface InitialStateType {
    disclaimers: DisclaimerType[]
    templates: MarketingReportType[]
    templatesForImageUpload: MarketingReportType[]
    closedModal: boolean | null
    templatesForEmail: any[]
    userActivities: AuditGridItem[]
    userActivitiesColumns: GridColumns[]
    auditShownColumns: ShownAndHiddenColumnsType[],
    auditHiddenColumns: ShownAndHiddenColumnsType[],
    auditDefaultGridColumns: AuditGridItem[],
    auditGridFiltersForArray: GridsColumnFilters[],
    auditGridColumnsForFilters: GridsColumnsForFilters[],
    activityAttributesCodesRows: ActivityCodeRow[],
    activityAttributesCodesColumns: ActivityCodesGridColumn[]
    activityAttributesCodesDefaultRows: ActivityCodesGridColumn[]
    activityAttributesCodesFiltersForArray: GridsColumnFilters[],
    activityAttributesCodesColumnsForFilters: GridsColumnsForFilters[],
    activityAttributeNewCode: UpdateActivityCodesReqData
    newAttrCode: ActivityCodeRow
    oldAttrCode: ActivityCodeRow
    agentPositions: string[]
    attrCodesCurrentDomain: string | null
    attrCodesCurrentPosition: string | null
    upcomingPropsCurrentStatus: string | null
    lastUpcomingPropsCurrentStatus: string | null
    publicKey: string
    attrCodesCurrentAddrStreet: string | null
    brokeragePropsCurrentStatus: string | null
    propsAndSurfacesCurrentStatus: string | null
    templatesType: EmailTemplateTypes
    templatesRequirements: RequirementType[]
    faqs: FaqsType[]
    dynamicInputWidth: string | number
    faqsLoading: boolean
}

const initialState: InitialStateType = {
    disclaimers: [],
    templates: [],
    templatesForImageUpload: [],
    closedModal: null,
    templatesForEmail: [],
    userActivities: [],
    userActivitiesColumns: [],
    auditShownColumns: [],
    auditHiddenColumns: [],
    auditDefaultGridColumns: [],
    auditGridFiltersForArray: [],
    auditGridColumnsForFilters: [],
    activityAttributesCodesRows: [],
    activityAttributesCodesColumns: [],
    activityAttributesCodesDefaultRows: [],
    activityAttributeNewCode: {
        act_code: '',
        act_description: '',
        domain_cd: null,
        enum_index: 0,
        old_act_code: '',
        old_domain_cd: '',
        old_position: '',
        order_num: 1,
        position: null,
        prop_required: 0,
        send_surveys: 0,
    },
    newAttrCode: {
        ACT_CODE: '',
        ACT_DESCRIPTION: '',
        DOMAIN_CD: '',
        ENUM_INDEX: 0,
        ORDER_NUM: 0,
        POSITION: '',
        PROP_REQUIRED: 0,
        SEND_SURVEYS: 0,
        id: 0,
    },
    oldAttrCode: {
        ACT_CODE: '',
        ACT_DESCRIPTION: '',
        DOMAIN_CD: '',
        ENUM_INDEX: 0,
        ORDER_NUM: 0,
        POSITION: '',
        PROP_REQUIRED: 0,
        SEND_SURVEYS: 0,
        id: 0,
    },
    activityAttributesCodesFiltersForArray: [],
    activityAttributesCodesColumnsForFilters: [],
    agentPositions: [],
    attrCodesCurrentDomain: null,
    attrCodesCurrentPosition: null,
    upcomingPropsCurrentStatus: 'All',
    lastUpcomingPropsCurrentStatus: null,
    publicKey: 'H0rn9nF3Ke40Tur2zaxYoAzf/TSsDXtI/xmfQC3Zltc=',
    attrCodesCurrentAddrStreet: null,
    brokeragePropsCurrentStatus: null,
    templatesType: 'OFFER',
    templatesRequirements: [],
    propsAndSurfacesCurrentStatus: null,
    faqs: [],
    dynamicInputWidth: 'initial',
    faqsLoading: false
}


export const parametersSlice = createSlice({
    name: 'parameters',
    initialState,
    reducers: {
        setDisclaimers: (state, action: PayloadAction<DisclaimerType[]>) => {
            state.disclaimers = action.payload
        },
        setMarketingTemplates: (state, action: PayloadAction<MarketingReportType[]>) => {
            state.templates = action.payload
        },
        setTemplatesForImageUpload: (state, action: PayloadAction<MarketingReportType[]>) => {
            state.templatesForImageUpload = action.payload
        },
        setClosedModal: (state, action: PayloadAction<boolean>) => {
            state.closedModal = action.payload
        },
        setUserActivities: (state, action: PayloadAction<AuditGridItem[]>) => {
            state.userActivities = action.payload
        },
        setAuditShownColumns: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            state.auditShownColumns = action.payload.columns.sort((a: any, b: any) => a.col_pos - b.col_pos)
        },
        setAuditHiddenColumns: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            state.auditHiddenColumns = action.payload.columns
        },
        onSetAuditGridFiltersButtonsModalOpened: (state, action: PayloadAction<{ headerName: string, isModalOpened: boolean }>) => {
            state.auditGridColumnsForFilters = state.auditGridColumnsForFilters.map((m: GridsColumnsForFilters) => m.headerName === action.payload.headerName ? {
                ...m,
                isModalBtnOpened: action.payload.isModalOpened
            } : {...m, isModalBtnOpened: false})
        },
        onChangeAuditGridFilterConditionValue: (state, action: PayloadAction<{ title: string, value: string }>) => {
            state.auditGridColumnsForFilters = state.auditGridColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                conditionValue: action.payload.value
            } : m)
        },
        onSetFiltersForAuditGrid: (state, action: PayloadAction<{ gridFilters: GridsColumnFilters[] }>) => {
            state.auditGridFiltersForArray = action.payload.gridFilters
        },
        onSetAuditGridFiltersButtonsSelected: (state, action: PayloadAction<{ headerName?: string, isFilterSelected: boolean, columnTitle?: string }>) => {
            state.auditGridColumnsForFilters = state.auditGridColumnsForFilters.map((m: GridsColumnsForFilters) => m.headerName === action.payload.headerName || m.title === action.payload.columnTitle ? {
                ...m,
                isFilterSelected: action.payload.isFilterSelected
            } : m)
        },
        onChangeAuditGridFilterValue: (state, action: PayloadAction<{ title?: string, value: any, startValue?: string, endValue?: string, columnTitle?: string }>) => {
            state.auditGridColumnsForFilters = state.auditGridColumnsForFilters.map((m: GridsColumnsForFilters) => m.headerName === action.payload.title || m.title === action.payload.columnTitle ? {
                ...m,
                value: action.payload.value,
                startValue: action.payload.startValue,
                endValue: action.payload.endValue
            } : m)
        },
        onChangeAuditGridFilterStartValue: (state, action: PayloadAction<{ title: string, value: any }>) => {
            state.auditGridColumnsForFilters = state.auditGridColumnsForFilters.map((m: GridsColumnsForFilters) => m.headerName === action.payload.title ? {
                ...m,
                startValue: action.payload.value
            } : m)
        },
        onChangeAuditGridFilterEndValue: (state, action: PayloadAction<{ title: string, value: any }>) => {
            state.auditGridColumnsForFilters = state.auditGridColumnsForFilters.map((m: GridsColumnsForFilters) => m.headerName === action.payload.title ? {
                ...m,
                endValue: action.payload.value
            } : m)
        },
        setAuditGridColumnsForFilters: (state, action: PayloadAction<{ columns: GridsColumnsForFilters[] }>) => {
            state.auditGridColumnsForFilters = action.payload.columns
        },
        onAuditResetFiltersButtonsSelected: (state) => {

            state.auditGridColumnsForFilters = state.auditGridColumnsForFilters.map((m: GridsColumnsForFilters) => m && {
                ...m,
                isFilterSelected: false,
                isModalBtnOpened: false,
                value: '',
                startValue: '',
                endValue: '',
                conditionValue: ''
            })

        },
        setAuditColumns: (state, action: PayloadAction<{ columns: GridColumns[] }>) => {
            state.userActivitiesColumns = action.payload.columns.sort((a: any, b: any) => a.col_pos - b.col_pos)
        },
        changeActivityCodeRowValue: (state, action: PayloadAction<{ fieldName: string, itemId: number, value: string | number }>) => {
            if (action.payload.fieldName === 'ORDER_NUM') {
                const withOldOrderNum = state.activityAttributesCodesRows.find((c) => c.ORDER_NUM === Number(action.payload.value))
                const withNewOrderNum = state.activityAttributesCodesRows.find((c) => c.id === Number(action.payload.itemId))
                state.activityAttributesCodesRows = state.activityAttributesCodesRows.map((r: ActivityCodeRow) => Number(r.id) === withOldOrderNum?.id
                    ? {...r, [action.payload.fieldName]: withNewOrderNum?.ORDER_NUM} : r)
                state.activityAttributesCodesRows = state.activityAttributesCodesRows.map((r: ActivityCodeRow) => Number(r.id) === withNewOrderNum?.id
                    ? {
                        ...r,
                        [action.payload.fieldName]: Number(action.payload.value)
                    } : r).sort((a, b) => a.ORDER_NUM - b.ORDER_NUM)

            } else {
                state.activityAttributesCodesRows = state.activityAttributesCodesRows.map((r: ActivityCodeRow) => Number(r.id) === Number(action.payload.itemId)
                    ? {
                        ...r,
                        [action.payload.fieldName]: action.payload.fieldName === "ORDER_NUM" ? Number(action.payload.value) : String(action.payload.value)
                    } : r)
            }
        },
        onChangeAttrNewCodeField: (state, action: PayloadAction<{ fieldName: string; fieldValue: string | number | boolean }>) => {
            const {fieldName, fieldValue} = action.payload;
            if (fieldName in state.activityAttributeNewCode) {
                state.activityAttributeNewCode[fieldName] = fieldValue;
            }
        },
        resetAttrCodeFields: (state) => {
            state.activityAttributeNewCode = {
                act_code: '',
                    act_description: '',
                    domain_cd: '',
                    enum_index: 0,
                    old_act_code: '',
                    old_domain_cd: '',
                    old_position: '',
                    order_num: 1,
                    position: '',
                    prop_required: 0,
                    send_surveys: 0,
            }
        },
        onChangeAttrOrderNum: (state, action: PayloadAction<{ newOrderNum: number, rowId: number, oldOrderNum: number }>) => {
            let sortedByOrderNum = [...state.activityAttributesCodesRows].sort((a, b) => a.ORDER_NUM - b.ORDER_NUM);
            for (let i = 0; i < sortedByOrderNum.length - 1; i++) {
                if (sortedByOrderNum[i].ORDER_NUM === sortedByOrderNum[i + 1].ORDER_NUM) {
                    for (let j = i + 1; j < sortedByOrderNum.length; j++) {
                        sortedByOrderNum[j] = { ...sortedByOrderNum[j], ORDER_NUM: sortedByOrderNum[j].ORDER_NUM + 1};
                    }
                }
            }
            state.activityAttributesCodesRows = sortedByOrderNum.map((obj) => {
                if (obj.id === action.payload.rowId) {
                    return { ...obj, ORDER_NUM: action.payload.newOrderNum + 1 };
                }
                if (action.payload.oldOrderNum < action.payload.newOrderNum) {
                    if (obj.ORDER_NUM > action.payload.oldOrderNum + 1 && obj.ORDER_NUM <= action.payload.newOrderNum + 1) {
                        return { ...obj, ORDER_NUM: obj.ORDER_NUM - 1 };
                    }
                } else if (action.payload.oldOrderNum > action.payload.newOrderNum) {
                    if (obj.ORDER_NUM >= action.payload.newOrderNum + 1 && obj.ORDER_NUM < action.payload.oldOrderNum + 1) {
                        return { ...obj, ORDER_NUM: obj.ORDER_NUM + 1 };
                    }
                }
                return obj;
            }).sort((a, b) => a.ORDER_NUM - b.ORDER_NUM);
        },
        setAttrCodesGridColumnsForFilters: (state, action: PayloadAction<{ columns: GridsColumnsForFilters[] }>) => {
            state.activityAttributesCodesColumnsForFilters = action.payload.columns
        },
        onSetAttrCodesGridFiltersButtonsModalOpened: (state, action: PayloadAction<{ headerName: string, isModalOpened: boolean }>) => {
            state.activityAttributesCodesColumnsForFilters = state.activityAttributesCodesColumnsForFilters.map((m: any) => m.headerName === action.payload.headerName ? {
                ...m,
                isModalBtnOpened: action.payload.isModalOpened
            } : {...m, isModalBtnOpened: false})
        },
        onChangeAttrCodesGridFilterConditionValue: (state, action: PayloadAction<{ title: string, value: string }>) => {
            state.activityAttributesCodesColumnsForFilters = state.activityAttributesCodesColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                conditionValue: action.payload.value
            } : m)
        },
        onSetAttrCodesReqs: (state, action: PayloadAction<any[]>) => {
            state.activityAttributesCodesRows = action.payload
        },
        onSetFiltersForAttrCodesGrid: (state, action: PayloadAction<{ gridFilters: GridsColumnFilters[] }>) => {
            state.activityAttributesCodesFiltersForArray = action.payload.gridFilters
        },
        onSetAttrCodesGridFiltersButtonsSelected: (state, action: PayloadAction<{ headerName?: string, isFilterSelected: boolean, columnTitle?: string }>) => {
            state.activityAttributesCodesColumnsForFilters = state.activityAttributesCodesColumnsForFilters.map((m: any) => m.headerName === action.payload.headerName || m.title === action.payload.columnTitle || m.title === action.payload.headerName ? {
                ...m,
                isFilterSelected: action.payload.isFilterSelected
            } : m)
        },
        onChangeAttrCodesGridFilterValue: (state, action: PayloadAction<{ title?: string, value: any, startValue?: string, endValue?: string, columnTitle?: string }>) => {
            state.activityAttributesCodesColumnsForFilters = state.activityAttributesCodesColumnsForFilters.map((m: any) => m.headerName === action.payload.title || m.title === action.payload.columnTitle || m.title === action.payload.title ? {
                ...m,
                value: action.payload.value,
                startValue: action.payload.startValue,
                endValue: action.payload.endValue
            } : m)
        },
        onChangeAttrCodesGridFilterStartValue: (state, action: PayloadAction<{ title: string, value: any }>) => {
            state.activityAttributesCodesColumnsForFilters = state.activityAttributesCodesColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                startValue: action.payload.value
            } : m)
        },
        onChangeAttrCodesGridFilterEndValue: (state, action: PayloadAction<{ title: string, value: any }>) => {
            state.activityAttributesCodesColumnsForFilters = state.activityAttributesCodesColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                endValue: action.payload.value
            } : m)
        },
        onAttrCodesResetFiltersButtonsSelected: (state) => {

            state.activityAttributesCodesColumnsForFilters = state.activityAttributesCodesColumnsForFilters.map((m: GridsColumnsForFilters) => m && {
                ...m,
                isFilterSelected: false,
                isModalBtnOpened: false,
                value: null,
                startValue: '',
                endValue: '',
                conditionValue: ''
            })

        },
        onSetAttrCodesCurrentDomain: (state, action: PayloadAction<string | null>) => {
            state.attrCodesCurrentDomain = action.payload
        },
        onSetAttrCodesCurrentPosition: (state, action: PayloadAction<string | null>) => {
            state.attrCodesCurrentPosition = action.payload
        },
        onSetUpcomingPropsCurrentStatus: (state, action: PayloadAction<string | null>) => {
            state.upcomingPropsCurrentStatus = action.payload
        },
        onSetLastUpcomingPropsCurrentStatus: (state, action: PayloadAction<string | null>) => {
            state.lastUpcomingPropsCurrentStatus = action.payload
        },
        onSetLastSearchedStreet: (state, action: PayloadAction<string | null>) => {
            state.lastUpcomingPropsCurrentStatus = action.payload
        },
        onSetBrokeragePropsCurrentStatus: (state, action: PayloadAction<string | null>) => {
            state.brokeragePropsCurrentStatus = action.payload
        },
        onSetPropsAndSurfacesCurrentStatus: (state, action: PayloadAction<string | null>) => {
            state.propsAndSurfacesCurrentStatus = action.payload
        },
        onSetAttrCodesCurrentAddrStreet: (state, action: PayloadAction<string | null>) => {
            state.attrCodesCurrentAddrStreet = action.payload
        },
        onSetOfferTemplatesType: (state, action: PayloadAction<EmailTemplateTypes>) => {
            state.templatesType = action.payload
        },
        onSetFilteredInputDynamicWidth: (state, action: PayloadAction<string | number>) => {
            state.dynamicInputWidth = action.payload
        },
        onSetFaqs: (state, action: PayloadAction<FaqsType[]>) => {
            state.faqs = action.payload
        },
        onSetFaqsLoading: (state, action: PayloadAction<boolean>) => {
            state.faqsLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetDisclaimersThunk.fulfilled, (state, action) => {
                state.disclaimers = action.payload
            })
            .addCase(UpdateDisclaimersThunk.fulfilled, (state, action) => {
                state.disclaimers = action.payload
            })
            .addCase(GetMarketingTemplatesThunk.fulfilled, (state, action) => {
                state.templates = action.payload
            })
            .addCase(GetMarketingTemplateThunk.fulfilled, (state, action) => {
                state.templatesForImageUpload = [...state.templatesForImageUpload, action.payload].filter((t: any) => t.domain === action.payload.domain)
            })
            .addCase(GetTemplatesForEmail.fulfilled, (state, action) => {
                state.templatesForEmail = action.payload
            })
            .addCase(GetRequirementsThunk.fulfilled, (state, action) => {
                state.templatesRequirements = action.payload
            })
            .addCase(GetUserActivitiesThunk.fulfilled, (state, action) => {
                // eslint-disable-next-line
                const userActivities = action.payload.map((innerArr: any, index: number) => {
                    if (innerArr !== null) {
                        const obj: any = {};
                        innerArr.forEach((currentValue: any) => {
                            if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.')) : null;
                            } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss') : null;
                            } else {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            }

                        });
                        obj['id'] = index;
                        obj['isFiltered'] = false;
                        obj['isOpened'] = false;

                        return obj;
                    }
                });

                state.userActivities = userActivities
                state.userActivitiesColumns = action.payload[0]
                state.auditDefaultGridColumns = userActivities

            })
            .addCase(GetActivityAttributesThunk.fulfilled, (state, action) => {
                // eslint-disable-next-line
                const activitiesCodes = action.payload.grid_column_data.map((innerArr: any, index: number) => {
                    if (innerArr !== null) {
                        const obj: any = {};
                        innerArr.forEach((currentValue: any) => {
                            if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.')) : null;
                            } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss') : null;
                            } else {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            }

                        });
                        obj['id'] = index;
                        // obj['ORDER_NUM'] = index + 1
                        return obj;
                    }
                }).sort((a, b) => a.ORDER_NUM - b.ORDER_NUM);
                state.agentPositions = Array.from(new Set(activitiesCodes.map((c: any) => c.POSITION).filter((pos: any) => pos !== null)))
                state.activityAttributesCodesRows = activitiesCodes
                state.activityAttributesCodesColumns = [action.payload.grid_column_data[0]].flat(1)
                state.activityAttributesCodesDefaultRows = activitiesCodes

            })
            .addCase(GetFaqsThunk.fulfilled, (state, action) => {
                state.faqs = action.payload
            })
            .addCase(SearchFaqsThunk.fulfilled, (state, action) => {
                state.faqs = action.payload
            })
    }
})

export const {
    setTemplatesForImageUpload,
    setClosedModal,
    setUserActivities,
    onSetAuditGridFiltersButtonsModalOpened,
    onChangeAuditGridFilterConditionValue,
    onSetFiltersForAuditGrid,
    onSetAuditGridFiltersButtonsSelected,
    onChangeAuditGridFilterValue,
    onChangeAuditGridFilterStartValue,
    onChangeAuditGridFilterEndValue,
    setAuditGridColumnsForFilters,
    onAuditResetFiltersButtonsSelected,
    setAuditColumns,
    changeActivityCodeRowValue,
    onChangeAttrNewCodeField,
    onChangeAttrOrderNum,
    resetAttrCodeFields,
    setAttrCodesGridColumnsForFilters,
    onSetAttrCodesGridFiltersButtonsModalOpened,
    onSetAttrCodesReqs,
    onSetFiltersForAttrCodesGrid,
    onSetAttrCodesGridFiltersButtonsSelected,
    onChangeAttrCodesGridFilterValue,
    onChangeAttrCodesGridFilterStartValue,
    onChangeAttrCodesGridFilterEndValue,
    onChangeAttrCodesGridFilterConditionValue,
    onAttrCodesResetFiltersButtonsSelected,
    onSetAttrCodesCurrentDomain,
    onSetAttrCodesCurrentPosition,
    onSetUpcomingPropsCurrentStatus,
    onSetLastUpcomingPropsCurrentStatus,
    onSetAttrCodesCurrentAddrStreet,
    onSetBrokeragePropsCurrentStatus,
    onSetOfferTemplatesType,
    onSetPropsAndSurfacesCurrentStatus,
    onSetFilteredInputDynamicWidth,
    onSetFaqs,
    onSetFaqsLoading
} = parametersSlice.actions

export const selectDisclaimers = (state: RootState): DisclaimerType[] => state.parameters.disclaimers
export const selectMarketingTemplates = (state: RootState): MarketingReportType[] => state.parameters.templates
export const selectTemplatesForImageUpload = (state: RootState): MarketingReportType[] => state.parameters.templatesForImageUpload
export const selectClosedModal = (state: RootState): boolean | null => state.parameters.closedModal
export const selectTemplatesForEmail = (state: RootState): any[] => state.parameters.templatesForEmail
export const selectUserActivities = (state: RootState): AuditGridItem[] => state.parameters.userActivities
export const selectUserActivitiesColumns = (state: RootState): GridColumns[] => state.parameters.userActivitiesColumns
export const selectAuditDefaultColumns = (state: RootState): AuditGridItem[] => state.parameters.auditDefaultGridColumns
export const selectAuditGridFiltersForArray = (state: RootState): GridsColumnFilters[] => state.parameters.auditGridFiltersForArray
export const selectAuditGridColumnsForFilters = (state: RootState): GridsColumnsForFilters[] => state.parameters.auditGridColumnsForFilters
export const selectActivityAttributeCodeRows = (state: RootState): ActivityCodeRow[] => state.parameters.activityAttributesCodesRows
export const selectActivityAttributeCodeColumns = (state: RootState): ActivityCodesGridColumn[] => state.parameters.activityAttributesCodesColumns
export const selectActivityAttributeCodeDefaultRows = (state: RootState): ActivityCodesGridColumn[] => state.parameters.activityAttributesCodesDefaultRows
export const selectAttrNewCodeFields = (state: RootState): UpdateActivityCodesReqData => state.parameters.activityAttributeNewCode
export const selectAttrCodesFiltersForArray = (state: RootState): GridsColumnFilters[] => state.parameters.activityAttributesCodesFiltersForArray
export const selectAttrCodesColumnsForFilters = (state: RootState): GridsColumnsForFilters[] => state.parameters.activityAttributesCodesColumnsForFilters
export const selectAttrCodesAgentPositions = (state: RootState): string[] => state.parameters.agentPositions
export const selectAttrCodesCurrentDomain = (state: RootState): string | null => state.parameters.attrCodesCurrentDomain
export const selectAttrCodesCurrentPosition = (state: RootState): string | null => state.parameters.attrCodesCurrentPosition
export const selectUpcomingPropsCurrentStatus = (state: RootState): string | null => state.parameters.upcomingPropsCurrentStatus
export const selectLatestUpcomingPropsCurrentStatus = (state: RootState): string | null => state.parameters.lastUpcomingPropsCurrentStatus
export const selectBrokeragePropsCurrentStatus = (state: RootState): string | null => state.parameters.brokeragePropsCurrentStatus
export const selectPublicKey = (state: RootState): string  => state.parameters.publicKey
export const selectTemplatesType = (state: RootState): EmailTemplateTypes => state.parameters.templatesType
export const selectRequirementsTemplates = (state: RootState): RequirementType[] => state.parameters.templatesRequirements
export const selectPropsAndSurfacesCurrentPosition = (state: RootState): string | null => state.parameters.propsAndSurfacesCurrentStatus
export const selectFaqsArray = (state: RootState): FaqsType[] => state.parameters.faqs
export const selectDynamicInputWidth = (state: RootState): string | number => state.parameters.dynamicInputWidth
export const selectIsFaqsLoading = (state: RootState): boolean => state.parameters.faqsLoading

export const GetDisclaimersThunk = createAsyncThunk<DisclaimerType[], void, AsyncThunkConfig>(
    'parameters/getDisclaimers',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await parametersAPI.getDisclaimers()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.disclaimer_info_list, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const UpdateDisclaimersThunk = createAsyncThunk<DisclaimerType[], DisclaimerType[], AsyncThunkConfig>(
    'parameters/updateDisclaimers',
    async (updatedDisclaimers, thunkAPI) => {
        try {
            const {status, data} = await parametersAPI.updateDisclaimers(updatedDisclaimers)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.disclaimer_info_list, {
                    appStatus: AppStatusType.succeeded,
                    appMessage: 'All changes have been saved'
                })
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
        }
    }
)

export const GetMarketingTemplatesThunk = createAsyncThunk<MarketingReportType[], void, AsyncThunkConfig>(
    'parameters/getMarketingTemplates',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await templatesApi.getTemplates()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.templates, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const GetMarketingTemplateThunk = createAsyncThunk<any, string, AsyncThunkConfig>(
    'parameters/getMarketingTemplate',
    async (code, thunkAPI) => {
        try {
            const {status, data} = await templatesApi.getTemplate(code)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const UpdateMarketingTemplatesThunk = createAsyncThunk<MarketingReportType, { updatedMarketingTemplates: any, code: string }, AsyncThunkConfig>(
    'parameters/updatedMarketingTemplates',
    async (updatedMarketingTemplates, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await templatesApi.updateTemplates(updatedMarketingTemplates.updatedMarketingTemplates, updatedMarketingTemplates.code)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.marketingTemplates, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(GetMarketingTemplatesThunk())
            thunkAPI.dispatch(setClosedModal(false))
        }
    }
)


export const GetRequirementsThunk = createAsyncThunk<RequirementType[], string, AsyncThunkConfig>(
    'parameters/getRequirements',
    async (user_ref, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(1))
        try {
            const {status, data} = await requirementsApi.getRequirements(user_ref)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.loading})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetTemplatesForEmail = createAsyncThunk<any, EmailTemplateTypes, AsyncThunkConfig>(
    'parameters/getTemplatesForEmail',
    async (templateType, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
        try {
            const {status, data} = await requirementsApi.getEmailTemplates(templateType)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.domainTemplates, {appStatus: AppStatusType.idle})
                return data.domainTemplates
            } else {
                return thunkAPI.rejectWithValue(data.domainTemplates)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const UpdateEmailSubjectThunk = createAsyncThunk<{ message: string }, EmailUpdate, AsyncThunkConfig>(
    'parameters/updateEmailSubject',
    async (requestBody, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await requirementsApi.updateEmailSubject(requestBody)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: data.message})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        // finally{
        //     setInterval(() => {
        //         window.location.replace('/parameters/templates-for-email')
        //     }, 2500)
        // }
    }
)

export const UpdateEmailBodyThunk = createAsyncThunk<{ message: string }, EmailUpdate, AsyncThunkConfig>(
    'parameters/updateEmailBody',
    async (requestBody, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await requirementsApi.updateEmailBody(requestBody)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: data.message})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        // finally{
        //     setInterval(() => {
        //         window.location.replace('/parameters/templates-for-email')
        //     }, 2500)
        // }
    }
)

export const GetUserActivitiesThunk = createAsyncThunk<any[], {
    p_agent_ref: number
    p_beg_date: string | Moment,
    p_end_date: string | Moment,
    p_sort_order: string | null
}, AsyncThunkConfig>(
    'parameters/getUserActivities',
    async (requestData, thunkAPI) => {
        thunkAPI.dispatch(onSetPropertiesGridLoaderMessage('Loading'))
        try {
            const {status, data} = await gridApi.getAuditGridData(requestData)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.grid_column_data, {appStatus: AppStatusType.idle})
                return data.grid_column_data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            thunkAPI.dispatch(setUserActivities([]))
            thunkAPI.dispatch(setAuditColumns({columns: []}))
            return thunkAPI.rejectWithValue(error?.response?.data?.message)

        }
    }
)

export const GetActivityAttributesThunk = createAsyncThunk<ActivityCodesDataType, GetActivityCodesReqData, AsyncThunkConfig>(
    'parameters/getActivityAttributes',
    async (requestData, thunkAPI) => {
        thunkAPI.dispatch(onSetPropertiesGridLoaderMessage('Loading'));
        try {
            const {status, data} = await parametersAPI.getActivityCodeAttributes(requestData);
            if (status === 200 && data) {
                // Directly return the payload for a fulfilled action
                return data;
            } else {
                // Use rejectWithValue to return a rejected action with a custom payload
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error: any) {
            thunkAPI.dispatch(setUserActivities([]));
            thunkAPI.dispatch(setAuditColumns({columns: []}));
            // Use rejectWithValue for error cases to provide an error payload
            return thunkAPI.rejectWithValue(error?.response?.data?.message);
        }
    }
);

export const GetFaqsThunk = createAsyncThunk<FaqsType[], {category: 'Admin' | 'User'}, AsyncThunkConfig>(
    'parameters/getFaqs',
    async (requestData, thunkAPI) => {
        try {
            const {status, data} = await faqsApi.getFaqs(requestData.category)
            if (status === 200 && data) {
                // Directly return the payload for a fulfilled action
                return data.frequently_asked_questions;
            } else {
                // Use rejectWithValue to return a rejected action with a custom payload
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error: any) {
            // Use rejectWithValue for error cases to provide an error payload
            return thunkAPI.rejectWithValue(error?.response?.data?.message);
        }
    }
);


export const SearchFaqsThunk = createAsyncThunk<FaqsType[], {activity_ref: number, question: string, withLoader: boolean}, AsyncThunkConfig>(
    'parameters/searchFaqs',
    async (requestData, thunkAPI) => {
        if(requestData.withLoader){
            thunkAPI.dispatch(onSetFaqsLoading(true))
        }
        try {
            const {status, data} = await faqsApi.searchFaqs({activity_ref: requestData.activity_ref, search: requestData.question})
            if (status === 200 && data) {
                // Directly return the payload for a fulfilled action
                return data.frequently_asked_questions;
            } else {
                // Use rejectWithValue to return a rejected action with a custom payload
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error: any) {
            // Use rejectWithValue for error cases to provide an error payload
            return thunkAPI.rejectWithValue(error?.response?.data?.message);
        }
        finally {
            if(requestData.withLoader){
                thunkAPI.dispatch(onSetFaqsLoading(false))
            }
        }
    }
);


export default parametersSlice.reducer
 